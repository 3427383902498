import React from 'react'
import KeyAuthentication from "../key_authentication"
import ArchiveModal from "../archive_modal"
import {Modal} from "react-bootstrap"
import CircularProgress from '@material-ui/core/CircularProgress';
import Select from "react-select";

class ViewReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show_report_view_edit_modal: false,
      pdf_blob: null,
      pdf_blob_url: null,
      loading: true,
      current_report_message: this.props.report_message,
      current_configuration_data: this.props.configuration_data,
      current_report_display_types: this.props.report_display_types,
      pdf_version: this.props.version_list_options[0],
      pdf_report_type: {value: 'RMS', label: 'RMS Output'},
      incorrect_key: sessionStorage.getItem('incorrectKey') || false
    };

    this.handleShowEditModal = this.handleShowEditModal.bind(this);
    this.handleHideEditModal = this.handleHideEditModal.bind(this);
    this.handlePDFDownload = this.handlePDFDownload.bind(this);
    this.handleVersionChange = this.handleVersionChange.bind(this);
    this.handleTypeChange = this.handleTypeChange.bind(this);
    this.load_pdf = this.load_pdf.bind(this);
  }

  handleShowEditModal() {
    this.setState({show_report_view_edit_modal: true})
  }

  handleHideEditModal() {
    this.setState({show_report_view_edit_modal: false})
  }

  handlePDFDownload() {
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(this.state.pdf_blob, "HealthIM Report - " + this.props.report_incident_number + ".pdf");
    } else {
      var link = $('#download-link');
      link.attr('href', this.state.pdf_blob_url);
      link.attr('download', "HealthIM Report - " + this.props.report_incident_number + ".pdf");
      link.click();
    }
  }

  handleVersionChange(val) {
    this.setState({
      pdf_version: val,
      pdf_report_type: {value: 'RMS', label: 'RMS Output'},
      loading: true
    }, () => {
      canvasReset()
      $.get('/record_editor/get_versioned_report.json?report_id=' + this.props.report_id + '&version_number=' + val.value, function (data) {
        this.setState({
          current_report_message: data["report_message"],
          current_configuration_data: data["configuration_data"],
          current_report_display_types: data["report_display_types"],
        }, () => {
          this.load_pdf()
        });
      }.bind(this))
    });


  }

  handleTypeChange(val) {
    this.setState({
      pdf_report_type: val,
      loading: true
    }, () => {
      canvasReset()
      this.load_pdf()
    });

  }

  componentDidMount() {
    this.load_pdf()
  }

  load_pdf() {
    forge.options.usePureJavaScript = true;
    var reportObject = this.state.current_report_message;
    var configData = this.state.current_configuration_data;
    var contentJSON = this.props.question_content_json;
    var report_type = this.state.pdf_report_type.value
    var old_version = !this.props.is_plum_coulee;
    var organizationsList;
    var contentDictionary;
    var PDFs;
    var pdfBlob;
    var pdfBlobURL;
    var self = this;

    for (var key in this.state.pdf_report_type.extra_data) {
      configData[key] = this.state.pdf_report_type.extra_data[key]
    }

    setCanvas('pdf-canvas');

    $.get('/web_report_download/download_organizations.json', function (data) {
      if (configData.bmhsTextReplace) {
        var bmhs_override = configData.bmhsTextReplace;
        Object.keys(bmhs_override).forEach(function (key) {
            var bmhs_override_section = bmhs_override[key]
            for (var val in bmhs_override_section) {
              var index = bmhs_override_section[val]["index"];

              if (bmhs_override_section[val]["originalOptionText"] && bmhs_override_section[val]["newOptionText"] !== undefined) {
                var re = new RegExp(bmhs_override_section[val]["originalOptionText"], 'g');
                for (var i in contentJSON[key][index]["options"]) {
                  contentJSON[key][index]["options"][i] = contentJSON[key][index]["options"][i].replace(re, bmhs_override_section[val]["newOptionText"])
                }
              }

              if (bmhs_override_section[val]["originalButtonText"] && bmhs_override_section[val]["newButtonText"] !== undefined) {
                var re = new RegExp(bmhs_override_section[val]["originalButtonText"], 'g');
                for (var i in contentJSON[key][index]["buttons"]) {
                  contentJSON[key][index]["buttons"][i]["text"] = contentJSON[key][index]["buttons"][i]["text"].replace(re, bmhs_override_section[val]["newButtonText"])
                }
              }
            }
          }
        )
      }

      organizationsList = data;

      var sectionBContent = contentJSON["sectionBContent"];
      var sectionCContent = contentJSON["sectionCContent"];
      var sectionDActions = contentJSON["sectionDActions"];
      contentDictionary = {
        sectionBContent: sectionBContent,
        sectionCContent: sectionCContent,
        sectionDActions: sectionDActions
      };

      PDFs = HealthIMPDFGeneration(configData, organizationsList, contentDictionary, '/assets/', null, report_type, old_version);

      var keyData = JSON.parse(sessionStorage.getItem('organizationKey'));

      try {
        var privateKey = forge.pki.privateKeyFromPem(keyData.organizationPrivateKey);
        var decryptedKeyData = privateKey.decrypt(decodeFromBase64(reportObject.encryptionKey), 'RSA-OAEP');
        var aesComponents = decryptedKeyData.split('|');
        var encryptedBytes = decodeFromBase64(reportObject.encryptedData);
        var decipher = forge.cipher.createDecipher('AES-CBC', decodeFromBase64(aesComponents[0]));
        decipher.start({iv: decodeFromBase64(aesComponents[1])});
        var length = encryptedBytes.length;
        var chunkSize = 1024 * 64;
        var index = 0;
        var decrypted = '';
        do {
          decrypted += forge.util.decodeUtf8(decipher.output.getBytes());
          var buf = forge.util.createBuffer(encryptedBytes.substr(index, chunkSize));
          decipher.update(buf);
          index += chunkSize;
        } while (index < length);
        decipher.finish();
        decrypted += forge.util.decodeUtf8(decipher.output.getBytes());
        var decryptedJSON = JSON.parse(decrypted);

        for (var key in decryptedJSON) {
          reportObject[key] = decryptedJSON[key];
        }
      } catch (e) {
        console.log("Error");
        console.log(e);
      }

      var doc = new PDFDocument({margins: {right: 0}, bufferPages: true});

      var stream = doc.pipe(blobStream());

      var indicatorArray = PDFs.indicatorsArray(reportObject);

      if (reportObject['lastReport']) {
        contentDictionary.lastReportIndicators = PDFs.indicatorsArray(reportObject['lastReport']);
      }
      console.log(reportObject)

      PDFs.configureChart(reportObject, chartOnComplete);

      function chartOnComplete() {
        PDFs.generatePDF(doc, reportObject, indicatorArray, contentDictionary);
        doc.end();

        stream.on('finish', function () {
          var arrayBuffer;
          var fileReader = new FileReader();
          fileReader.onload = function (event) {
            arrayBuffer = event.target.result;
            var loadingTask = pdfjsLib.getDocument({data: arrayBuffer});
            loadingTask.promise.then(function (pdfDoc_) {
              pdfDoc = pdfDoc_;
              numberOfPages = pdfDoc.numPages;
              // Initial/first page rendering
              renderPage(currentPage);
              pdfZoom(-2);
            });
          };
          pdfBlob = this.toBlob();
          pdfBlobURL = this.toBlobURL();
          fileReader.readAsArrayBuffer(pdfBlob);

          self.setState({pdf_blob: pdfBlob, pdf_blob_url: pdfBlobURL, loading: false});
        })
      }
    });
  }

  render() {
    return (
      <div className="main-content">
        <div id="re-reports-container">
          <div id="report-view-header-container">
            <KeyAuthentication
              key_image_path={this.props.key_image_path}
              encryption_key={this.props.encryption_key}
              refresh={true}
            />
            <div id='report-view-header'>
              <div id="report-view-button-container">
                {
                  (sessionStorage.getItem('incorrectKey') == "false") &&
                    <div className="record-editor-edit" onClick={this.handleShowEditModal}>EDIT</div>
                }
                <a href="/record_editor">
                  <div className="record-editor-button">BACK</div>
                </a>
              </div>
              <div id='report-view-pdf-control-container'>
                <div id='report-view-increase-button' className="record-editor-button" onClick={() => {
                  pdfZoom(1)
                }}>+
                </div>
                <div id='report-view-decrease-button' className="record-editor-button" onClick={() => {
                  pdfZoom(-1)
                }}>-
                </div>
                <a id="download-link">
                  <div className="record-editor-edit" onClick={() => {
                    this.handlePDFDownload()
                  }}>DOWNLOAD
                  </div>
                </a>
              </div>
              <div id="report-view-control-container">
                <div id="report-view-back-button-container" className="record-editor-button"
                     onClick={() => {
                       previousPage()
                     }}>
                  <img id="report-view-back-button" src={this.props.page_control_button_path}/>
                </div>
                <div id="viewer-page-count" style={{display: this.state.loading ? "none" : "inline-block"}}>
                </div>
                {
                  this.state.loading &&
                  <div id="viewer-page-count-loader">
                    <CircularProgress
                      size={20}
                      thickness={5}
                      color="inherit"
                      classes={{
                        svg: 'view_report_spinner'
                      }}
                    />
                  </div>
                }
                <div id="report-view-forward-button-container" className="record-editor-button"
                     onClick={() => {
                       nextPage()
                     }}>
                  <img id="report-view-forward-button" src={this.props.page_control_button_path}/>
                </div>
              </div>
            </div>
            <div id="report-view-sub-header" style={{display: 'none'}}>
              <div className="report-view-sub-header-row row">
                <div className="col-sm-2 report-view-select-text-container">
                  <p className="report-view-sub-header-select-text">Record Version:</p>
                </div>
                <div className="col-sm-4">
                  <Select
                    classNamePrefix="re-viewer-select"
                    onChange={(value) => {
                      this.handleVersionChange(value)
                    }}
                    value={this.state.pdf_version}
                    options={this.props.version_list_options}
                  />
                </div>
                <div className="col-sm-2">
                  <p className="report-view-sub-header-select-text">Record Type:</p>
                </div>
                <div className="col-sm-4">
                  <Select
                    classNamePrefix="re-viewer-select"
                    onChange={(value) => {
                      this.handleTypeChange(value)
                    }}
                    value={this.state.pdf_report_type}
                    options={this.state.current_report_display_types}
                  />

                </div>
              </div>
              <div className="report-view-sub-header-row row">
                <div className="col-sm-3">
                <span className="report-view-sub-header-info">
                  <b>Updated: </b>
                  {getFullFormattedDateString(this.props.version_list[this.state.pdf_version.value], this.props.configuration_data)}
                </span>
                </div>
                <div className="col-sm-3">
                <span className="report-view-sub-header-info">
                  <b>Updated By: </b>
                  {this.state.current_report_message["serviceName"]}
                </span>
                </div>
                <div className="col-sm-3">
                <span className="report-view-sub-header-info">
                  <b>Updated Type: </b>
                  {"Edit"}
                </span>
                </div>
                <div className="col-sm-3">
                <span className="report-view-sub-header-info">
                  <b>Total Versions: </b>
                  {this.props.version_list.length}
                </span>
                </div>
              </div>
            </div>
          </div>
          <br/>
          <canvas id="pdf-canvas" style={{paddingTop: "150px"}} width="90%"/>
        </div>
        {
          !this.props.is_plum_coulee || this.state.current_report_message["endCallTime"] ? (
            <ReportViewEditModal
              show_report_view_edit_modal={this.state.show_report_view_edit_modal}
              handleHideEditModal={this.handleHideEditModal}
              is_admin={this.props.is_admin}
              report_id={this.props.report_id}
              report_exclude_from_charts={this.props.report_exclude_from_charts}
              report_test_report={this.props.report_test_report}
              current_user_permission={this.props.current_user_permission}
            >
            </ReportViewEditModal>
          ) : (
            <ReportViewNoEditModal
              show_report_view_edit_modal={this.state.show_report_view_edit_modal}
              handleHideEditModal={this.handleHideEditModal}
              current_report_message={this.state.current_report_message}
            />
          )
        }
      </div>
    );
  }
}

class ReportViewNoEditModal extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <Modal id="report_view_edit_modal"
               backdropClassName="report_view_edit_modal_backdrop"
               show={this.props.show_report_view_edit_modal} backdrop={true} keyboard={false} animation={false}
               onHide={this.props.handleHideEditModal} dialogClassName={"re-modal-content"}>
          <div id="report_view_edit_modal_header">
            <div id="report_view_edit_modal_header_title">
              <h2>CALL IN PROGRESS - EDITING DISABLED</h2>
            </div>
            <div className="report_view_edit_modal_header_body">
              <div>{`Record ${this.props.current_report_message.incidentNumber} (HealthIM ID: ${this.props.current_report_message.healthimID}) is incomplete.`}</div>
            </div>
            <div className="report_view_edit_modal_header_body">
              <div>Editing will remain disabled until the record has been completed in the HealthIM application.</div>
            </div>
            <div className="report_view_edit_modal_header_body">
              <span className="report_view_edit_modal_header_body_bold">Note:&nbsp;</span>
              <span className="report_view_edit_modal_header_body">Access the call in the "Record Search" view of the HealthIM application to complete.</span>
            </div>
          </div>

          <div id="report_view_edit_modal_button_container">
            <div id="report_view_edit_modal_dismiss_button_container">
              <button type="button" className="automated-button record-edit-menu-button" id="record-edit-menu-cancel"
                      onClick={this.props.handleHideEditModal}>Cancel
              </button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

//In the future can break down each button to individual components if necessary
class ReportViewEditModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      shouldNotHideEdit: (sessionStorage.getItem('incorrectKey') === 'false' || this.props.is_admin),
      waitTimeExcluded: (this.props.report_exclude_from_charts && this.props.report_exclude_from_charts.indexOf('waitTime') >= 0),
      show_archive_modal: false
    };
    this.inclusionText = this.inclusionText.bind(this);
    this.testReportText = this.testReportText.bind(this);
    this.handleTestReportClick = this.handleTestReportClick.bind(this);
    this.handleShowArchiveModal = this.handleShowArchiveModal.bind(this);
    this.handleHideArchiveModal = this.handleHideArchiveModal.bind(this);
  }

  inclusionText() {
    if (this.state.waitTimeExcluded) {
      return "Include in";
    } else {
      return "Exclude from";
    }
  }

  testReportText() {
    if (this.props.report_test_report) {
      return "Non-Test";
    } else {
      return "Test";
    }
  }

  handleTestReportClick() {
    var resp;

    if (this.props.report_test_report) {
      resp = confirm("Are you sure you want to mark this report as a non-test?\nDoing so will add the report into analytics.");
    } else {
      resp = confirm("Are you sure you want to mark this report as a test report?\nDoing so will remove the report from all analytics.");
    }

    if (resp === true) {
      $.post(
        '/reports/update_test/' + this.props.report_id,
        {
          reportTest: !this.props.report_test_report
        }
      ).done(function () {
        location.reload();
      });

    }
  }

  handleShowArchiveModal() {
    this.setState({show_archive_modal: true})
  }

  handleHideArchiveModal() {
    this.setState({show_archive_modal: false})
  }

  render() {
    return (
      <div>
        <Modal id="report_view_edit_modal"
               backdropClassName="report_view_edit_modal_backdrop"
               show={this.props.show_report_view_edit_modal} backdrop={true} keyboard={false} animation={false}
               onHide={this.props.handleHideEditModal} dialogClassName={"re-modal-content"}>
          <div id="report_view_edit_modal_header">
            <div id="report_view_edit_modal_header_title">
              <h2>Record Editor</h2>
            </div>
          </div>
          <a href={"/record_editor/report/" + this.props.report_id + "/edit"}>
            <button
              className={this.state.shouldNotHideEdit ? "automated-button record-edit-menu-button" : "hidden_button"}
              id="edit-button" type="button">Edit
              Record
            </button>
          </a>
          <button className="automated-button record-edit-menu-button"
                  onClick={() => {
                    inclusionChange(!this.state.waitTimeExcluded, this.props.report_id)
                  }}>
            {this.inclusionText()} Wait Time Data
          </button>
          <button id="report-test" onClick={this.handleTestReportClick}
                  className={this.props.current_user_permission ? "automated-button record-edit-menu-button" : "hidden_button"}>
            Mark as {this.testReportText()} Report
          </button>
          <button className="automated-button record-edit-menu-button" id="record-edit-menu-archive"
                  onClick={this.handleShowArchiveModal}>Archive Record
          </button>
          <div id="report_view_edit_modal_button_container">
            <div id="report_view_edit_modal_dismiss_button_container">
              <button type="button" className="automated-button record-edit-menu-button" id="record-edit-menu-cancel"
                      onClick={this.props.handleHideEditModal}>Cancel
              </button>
            </div>
          </div>
        </Modal>
        <ArchiveModal
          show_archive_modal={this.state.show_archive_modal}
          handleHideArchiveModal={this.handleHideArchiveModal}
          report_id={this.props.report_id}
        >
        </ArchiveModal>
      </div>
    );
  }
}

export default ViewReport
