import {ResponsiveBar} from "@nivo/bar";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import React from "react";
import {getTspanGroups} from "./data_viewer_custom_fields";

function BarGraph(props) {
  return (
    <div className="graph-sub-container"
         style={{backgroundColor: props.viewing ? "#1a1c1f" : "transparent"}}
    >
      <div id="graph-title-text-container" className="graph-title-text-container">
        <div className="graph-title-text" style={{color: props.graph_colour}}>
          <p>{props.graph_title}</p>
        </div>
        <div className="graph-sub-title-text">
          <p>{props.graph_sub_title}</p>
        </div>
      </div>
      <ChartBar
        formatValue={props.formatValue}
        graph_data={props.graph_data}
        hover={props.hover}
        show_hover={props.show_hover}
        graph_colour={props.graph_colour}
        viewing={props.viewing}
        index_by={props.index_by}
        index_by_label={props.index_by_label}
        data_keys={props.data_keys}
        graph_output_format={props.graph_output_format}
        handleLegendClick={props.handleLegendClick}
        y_axis_label={props.y_axis_label}
        max_val={props.max_val}
        opacity_text={props.opacity_text}
      />
      <br/><br/>
      <div id="graph-legend-container" className="graph-legend-container">
        <BarGraphExclusionList
          graph_full_data={props.graph_full_data}
          excluded_label={props.index_by_label}
          excluded_id={props.index_by}
          handleLegendClick={props.handleLegendClick}
          getTooltipText={props.getTooltipText}
          graph_colour={props.graph_colour}
        />

        <div id="graph-legend-text" className="graph-legend-title-text" style={{color: props.graph_colour}}>
          <p>Legend</p>
        </div>

        <Grid id="graph-legend" container spacing={2}>
          {

            Object.keys(props.data_keys).map((item, index) => {
              return (
                <Tooltip
                  key={index}
                  title={props.getTooltipText(item, "item")}
                  placement='bottom-end'
                >
                  <Grid key={index} item xs={3} sm={3}
                        className={props.data_keys[item]["show"] ? "graph-legend-item-container" : ""}
                        onClick={() => {
                          props.handleLegendClick(item, "", "data_keys")
                        }}
                        onMouseEnter={() => {
                          props.handleLegendOnHover(item)
                        }}
                        onMouseLeave={() => {
                          props.handleLegendOnHoverExit(item)
                        }}
                        style={{
                          display: props.data_keys[item]["show"] || props.viewing ? "block" : "none",
                          opacity: props.data_keys[item]["show"] ? 1 : 0.2
                        }}
                  >

                    <div className="graph-legend-item"
                         style={{color: props.graph_colour}}
                    >
                      <span
                        className="graph-legend-icon"
                        style={{
                          backgroundColor: props.data_keys[item]["color"],
                          height: 20,
                          width: 20,
                          float: "left",
                          borderRadius: "50%",
                          margin: 5,
                          border: "1px solid #000000"
                        }}
                      />
                      <div className="graph-label" style={{color: props.graph_colour}}> {item} </div>
                    </div>

                  </Grid>
                </Tooltip>

              )
            })
          }

        </Grid>
      </div>
    </div>
  )
}

class ChartBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      x_font: 16,
      x_dy: 5
    }
  }

  componentDidMount() {
    this.setState(
      {
        x_font: 16,
        x_dy: 5
      }
    )
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.index_by !== this.props.index_by || prevProps.graph_data.length !== this.props.graph_data.length) {
      this.setState(
        {
          x_font: 16,
          x_dy: 5
        }
      )
    }

    if (this.props.graph_data.length > 1) {
      var overlap = false;
      var offset1, length1, offset2, length2;
      $('.x_axis_text').each(function (index) {
        if (index === 0) {
          offset1 = $(this).offset().left;
          length1 = 0;
          $(this).children().each(function () {
            var val = $(this)[0].getComputedTextLength();
            if (val > length1) {
              length1 = val
            }
          })
        } else {
          offset2 = $(this).offset().left;
          length2 = 0;
          $(this).children().each(function () {
            var val = $(this)[0].getComputedTextLength();
            if (val > length2) {
              length2 = val
            }
          });

          if ((offset1 + length1) > (offset2)) {
            overlap = true;
            return false
          }
          offset1 = offset2;
          length1 = length2
        }
      });

      if (overlap) {
        this.setState({
          x_font: this.state.x_font - 1,
          x_dy: this.state.x_dy - 1
        })
      }
    }
  }

  render() {
    var y_labels = undefined;
    if (this.props.max_val < 8) {
      y_labels = Array.apply(null, {length: this.props.max_val + 1}).map(Number.call, Number)
    }

    var chart_width = 98;
    var max_char_per_line = Math.floor(chart_width / this.props.graph_data.length);
    return (
      <div className="graph-chart-container">
        <canvas id="temp-canvas"/>
        <img id="temp-img"/>
        <ResponsiveBar
          data={this.props.graph_data}
          indexBy={this.props.index_by}
          keys={Object.keys(this.props.data_keys).filter(val => {
            return this.props.data_keys[val].show
          })}
          groupMode="grouped"
          margin={{top: 40, right: 20, bottom: 90, left: 150}}
          borderColor={d => this.props.hover ? (this.props.data_keys[d.data.id].show_hover ? "#000000" : "none") : "#000000"}
          labelTextColor={d => this.props.hover ? (this.props.data_keys[d.data.id].show_hover ? this.props.graph_colour : this.props.graph_colour.replace("1)", this.props.opacity_text)) : this.props.graph_colour}
          borderWidth={1}
          colors={
            d => this.props.hover ?
              (this.props.data_keys[d.id].show_hover ?
                this.props.data_keys[d.id].color : this.props.data_keys[d.id].color.replace("1)", this.props.opacity_text))
              : this.props.data_keys[d.id].color
          }
          tooltipFormat={function (v) {
            return this.props.formatValue(v, false, false)
          }.bind(this)}
          labelFormat={function (v) {
            return <tspan y={-10}>{this.props.formatValue(v, false, false)}</tspan>
          }.bind(this)}
          animate={false}
          maxValue={this.props.graph_output_format === "percentage" ? 100 : "auto"}
          minValue={0}
          axisTop={null}
          axisRight={null}
          gridYValues={y_labels}

          axisBottom={{
            tickSize: 5,
            tickPadding: 15,
            legend: this.props.index_by_label,
            legendPosition: 'middle',
            legendOffset: 80,
            renderTick: ({
                           opacity,
                           textAnchor,
                           textBaseline,
                           textX,
                           textY,
                           theme,
                           value,
                           x,
                           y
                         }) => {
              return (
                <g
                  transform={`translate(${x} ${y})`}
                  style={{opacity}}
                >
                  <text
                    alignmentBaseline={textBaseline}
                    textAnchor={textAnchor}
                    className="x_axis_text"
                    style={{
                      fontSize: this.state.x_font,
                      fill: this.props.graph_colour,
                      cursor: "pointer",
                      fontFamily: "Helvetica"
                    }}
                    transform={`translate(${textX} ${textY})`}
                    onClick={() => {
                      this.props.handleLegendClick(value, this.props.index_by, "find")
                    }}
                  >
                    {getTspanGroups(value, max_char_per_line, this.state.x_dy, false)}
                  </text>
                </g>
              )
            }
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            legend: this.props.y_axis_label,
            legendPosition: 'middle',
            legendOffset: -110,
            tickValues: y_labels,
            format: function (v) {
              return this.props.formatValue(v, false, true)
            }.bind(this)
          }}
          theme={
            {
              labels: {
                text: {
                  fontSize: 16,
                  fontWeight: "bold",
                  fontFamily: "Helvetica"
                }
              },
              axis: {
                ticks: {
                  line: {
                    stroke: "#404040"
                  },
                  text: {
                    fill: this.props.graph_colour,
                    fontSize: 16,
                    fontFamily: "Helvetica"
                  }
                },
                legend: {
                  text: {
                    fill: this.props.graph_colour,
                    fontSize: 16,
                    fontFamily: "Helvetica"
                  }
                }
              },
              grid: {
                line: {
                  stroke: "#404040",
                  strokeWidth: 1
                }
              },
            }
          }
        />
      </div>
    )

  }
}

function BarGraphExclusionList(props) {
  if (props.graph_full_data.find(v => !v.show)) {
    return (
      <div>
        <div id="graph-exclusions-text" className="graph-legend-title-text" style={{color: props.graph_colour}}>
          <p>{props.excluded_label} Excluded:</p>
        </div>

        <Grid id="graph-exclusions" container spacing={2}>
          {
            props.graph_full_data.filter(v => !v.show).map((item, index) => {
              return (
                <Tooltip
                  key={index}
                  title={props.getTooltipText(item, "all")}
                  placement='bottom-end'
                >
                  <Grid key={index} item xs={3} sm={3}
                        className="graph-legend-item-container"
                        onClick={() => {
                          props.handleLegendClick(item["index"], "index", "find")
                        }}
                  >

                    <div className="graph-legend-item">
                      <div className="graph-label"
                           style={{color: props.graph_colour}}> {props.excluded_label}: {item[props.excluded_id]} </div>
                    </div>

                  </Grid>
                </Tooltip>
              )
            })
          }
        </Grid>
        <br/>
      </div>
    )
  } else {
    return (<div></div>)
  }
}

export default BarGraph