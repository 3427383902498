import React from 'react'
import {Modal} from "react-bootstrap";

class MentalHealthReferral extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show_referral_consent: false,
      selected_cmha: 0
    };

    this.handleReferralPressed = this.handleReferralPressed.bind(this);
    this.handleHideConsentModal = this.handleHideConsentModal.bind(this);
    this.addDestinationToReport = this.addDestinationToReport.bind(this);
    this.consentPressed = this.consentPressed.bind(this);
  }

  consentPressed(questionIndex, value) {
    this.props.report_object['cmhResponses'][questionIndex]['offered'] = 1;
    this.props.report_object['cmhResponses'][questionIndex]['consent'] = value;
    this.forceUpdate();

    this.addDestinationToReport(questionIndex, value);
    this.handleHideConsentModal();
  }

  addDestinationToReport(questionIndex, addDestination) {
    if (this.props.report_object["destinations"]) {
      if (addDestination) {
        if (this.props.report_object["destinations"].indexOf(this.props.report_object['cmhResponses'][questionIndex]["id"]) < 0) {
          this.props.report_object["destinations"].push(this.props.report_object['cmhResponses'][questionIndex]["id"]);
        }
      } else {
        var arrayIndex = this.props.report_object["destinations"].indexOf(this.props.report_object['cmhResponses'][questionIndex]["id"]);

        if (arrayIndex >= 0) {
          this.props.report_object["destinations"].splice(arrayIndex, 1);
        }
      }
    } else {
      if (addDestination) {
        this.props.report_object["destinations"] = [this.props.report_object['cmhResponses'][questionIndex]["id"]];
      }
    }
    this.forceUpdate()
  }

  handleReferralPressed(questionIndex, value) {
    if (value && this.props.report_object['cmhResponses'][questionIndex]['consentRequired']) {
      this.setState({selected_cmha: questionIndex});
      this.setState({show_referral_consent: true})
    } else if (value) {
      this.props.report_object['cmhResponses'][questionIndex]['offered'] = value;
      this.props.report_object['cmhResponses'][questionIndex]['consent'] = true;
      this.addDestinationToReport(questionIndex, value);
      this.forceUpdate()
    } else {
      this.props.report_object['cmhResponses'][questionIndex]['offered'] = value;
      this.props.report_object['cmhResponses'][questionIndex]['consent'] = false;
      this.addDestinationToReport(questionIndex, value);
      this.forceUpdate()
    }
  }

  handleHideConsentModal() {
    this.setState({show_referral_consent: false})
  }

  render() {
    if (this.props.report_object.hasOwnProperty('cmhResponses') && this.props.report_object["cmhResponses"].length > 0) {
      return (
        <div>
          <p id="mental-health-referral-title" className="container-sub-head">Mental Health Referral </p>
          <div id="mental-health-referral-container" className="bmhs-option-container">
            <div className="bmhs-option-cell">
              <p className="bmhs-option">Not Offered</p>
            </div>
            <div className="bmhs-option-cell">
              <p className="bmhs-option">Offer Referral</p>
            </div>
          </div>
          {
            this.props.report_object["cmhResponses"].map((item, index) => {
              return (
                <MentalHealthReferralOptions
                  key={index}
                  response={item}
                  index={index}
                  handleReferralPressed={this.handleReferralPressed}
                />
              )
            })
          }

          <MentalHealthReferralConsent
            show_referral_consent={this.state.show_referral_consent}
            handleHideConsentModal={this.handleHideConsentModal}
            index={this.state.selected_cmha}
            response={this.props.report_object["cmhResponses"][this.state.selected_cmha]}
            consentPressed={this.consentPressed}

          />

          <br className="re-line-break"/>
          <hr className="re-hr"/>
        </div>
      )
    } else {
      return (
        <div></div>
      )
    }
  }
}

function MentalHealthReferralConsent(props) {
  return (
    <div>
      <Modal id="report_view_edit_modal"
             backdropClassName="report_view_edit_modal_backdrop"
             show={props.show_referral_consent} backdrop={true} keyboard={false} animation={false}
             onHide={props.handleHideConsentModal} dialogClassName={"re-consent-modal-content"}>

        <div id="consent-header-container">
          <h1 id="consent-header-title" className="title-container-head">CONSENT REQUIRED</h1>
        </div>

        <div id="consent-body-container">
          <div id="consent-body-sub-header">To refer this individual to {props.response["name"]}
            <span className="re-bold-text"> you must seek informed consent from the client.</span>
          </div>
          <div id="consent-header-sub-title">PLEASE ASK THE CLIENT:</div>
          <ol>
            <li className="consent-description"> Do we have your permission to
              inform {props.response["name"]} that
              Police interacted with you today?
            </li>
            <li className="consent-description"> Do we have your permission to share a brief summary of our
              observations and what happened today with {props.response["name"]}?
            </li>
            <li className="consent-description"> Do we have your permission to share your phone number
              with {props.response["name"]} so they may follow-up with you?
            </li>
          </ol>
          <div>Please indicate whether consent was given or declined by the client.</div>
        </div>

        <div id="consent-button-container">
          <div id="consent-cancel" className="consent-button gunmetal" onClick={props.handleHideConsentModal}
               tabIndex="0">
            <p id="consent-cancel-text"> Cancel</p>
          </div>
          <div className="consent-button red" tabIndex="0"
               onClick={() => {
                 props.consentPressed(props.index, false)
               }}>
            <p className="consent-option-text">Consent Declined</p>
          </div>
          <div className="consent-button blue" tabIndex="0"
               onClick={() => {
                 props.consentPressed(props.index, true)
               }}>
            <p className="consent-option-text">Consent Received</p>
          </div>
        </div>
      </Modal>
    </div>
  );
}

class MentalHealthReferralOptions extends React.Component {
  constructor(props) {
    super(props);

    this.consentText = this.consentText.bind(this)
  }

  consentText() {
    if (this.props.response["offered"] == 1) {
      if (this.props.response["consentRequired"]) {
        if (this.props.response["consent"]) {
          return "Consent Received"
        } else {
          return "Consent Declined"
        }
      } else {
        return "Consent Not Required"
      }

    } else {
      return ""
    }
  }

  render() {
    return (
      <div key={this.props.index} className="section-b-question" id={"cmh-" + this.props.index}>
        <div className="mental-health-referral-option-container">
          <div className="section-b-sub-header">
            <p className="indicators">
              {this.props.response["name"]}
            </p>
          </div>
          <div className="referral-bmhs-cell-container" id={"referral-" + this.props.index}>
            <div className="bmhs-cell-report">
              <div
                className={`bmhs-button small not-offered ${this.props.response["offered"] == 0 ? "selected" : ""}`}
                tabIndex="0" onClick={() => {
                this.props.handleReferralPressed(this.props.index, 0)
              }}>
              </div>
            </div>
            <div className="bmhs-cell-report last">
              <div
                className={`bmhs-button small not-offered ${this.props.response["offered"] == 1 ? "selected" : ""}`}
                tabIndex="0" onClick={() => {
                this.props.handleReferralPressed(this.props.index, 1)
              }}>
              </div>
            </div>
            <p className="consent-text"> {this.consentText()} </p>
          </div>
        </div>
      </div>
    );
  }
}

export default MentalHealthReferral