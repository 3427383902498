import React from 'react'
import {RecordEditorDateTime, RecordEditorSelect} from './record_editor_custom_fields'

class PlumCouleeCallLog extends React.Component {
  constructor(props) {
    super(props);
    const disOptTemp = JSON.parse(JSON.stringify(this.props.disposition_options));
    const userDispositionOptions = [];

    let keysSorted = Object.keys(disOptTemp).sort((a, b) => {
      return disOptTemp[a].order - disOptTemp[b].order;
    });

    keysSorted.forEach((disOpt) => {
      if (!disOptTemp[disOpt].hidden) {
        userDispositionOptions.push({value: disOpt, label: this.props.disposition_options[disOpt].text});
      }
    });
    this.call_outcome_select_options = userDispositionOptions

    this.handleCallOutcomeChange = this.handleCallOutcomeChange.bind(this);
    this.resetDispositionDValues = this.resetDispositionDValues.bind(this);
    this.checkTransfer = this.checkTransfer.bind(this);
  }

  checkTransfer(index) {
    if ((index + 1) !== this.props.hospital_data_object.length) {
      var next_hospital = this.props.hospital_data_object[index + 1];
      var cur_hospital = this.props.hospital_data_object[index];
      if ((next_hospital.id.toString() === cur_hospital.id.toString())
        && (next_hospital.name.toString() === cur_hospital.name.toString())) {
        this.props.hospital_data_object[index + 1].id = "";
        this.props.hospital_data_object[index + 1].name = "";
        this.forceUpdate();
      }
    }
    this.forceUpdate();
  }

  resetDispositionDValues() {
    this.props.report_object['sectionDActions'][0] = 0;
    this.props.report_object['sectionDActions'][4] = 0;
    this.props.report_object['sectionDActions'][5] = 0;
    this.forceUpdate();
  }

  clearWaitTime(indices) {
    indices.forEach((i) => {
      this.props.hospital_data_object[i]["arriveAtED"] = "";
      this.props.hospital_data_object[i]["datetime"]["arriveAtED"]["date"] = "";
      this.props.hospital_data_object[i]["datetime"]["arriveAtED"]["time"] = "";
      this.props.hospital_data_object[i]["departFromED"] = "";
      this.props.hospital_data_object[i]["datetime"]["departFromED"]["date"] = "";
      this.props.hospital_data_object[i]["datetime"]["departFromED"]["time"] = "";
    })
    this.forceUpdate();
  }

  handleCallOutcomeChange(selected) {
    const {disposition_options} = this.props;
    var value = selected.value;
    var prev = this.props.report_object["selectedDispositionKey"];
    const dispOpt = disposition_options[value];
    const prevDispOpt = disposition_options[prev];
    const {index} = dispOpt;
    var allowClick = true;
    if (prevDispOpt.destinationSelectEnabled && !dispOpt.destinationSelectEnabled) {
      allowClick = confirm("Are you sure you want to remove all hospital wait times from this call?");
      if (allowClick) {
        this.props.clearHospitalsAfter(-1);
        this.resetDispositionDValues();
      } else {
        return;
      }
    } else if (dispOpt.destinationSelectEnabled && !dispOpt.callOutcomesEnabled && !dispOpt.transfersEnabled) {
      if (prevDispOpt.waitTimeEnabled && !dispOpt.waitTimeEnabled) {
        allowClick = confirm("Are you sure you want to remove all transfer hospital wait times from this call?");
      } else {
        allowClick = confirm("Are you sure you want to remove all transfer hospital and all wait times from this call?");
      }

      if (allowClick) {
        this.props.clearHospitalsAfter(0);
        this.resetDispositionDValues();
        if (!dispOpt.waitTimeEnabled) {
          this.clearWaitTime([0])
        }
      } else {
        return;
      }
    } else if (prevDispOpt.waitTimeEnabled && !dispOpt.waitTimeEnabled && !dispOpt.waitTimeEnabled && dispOpt.destinationSelectEnabled && dispOpt.transfersEnabled) {
      allowClick = confirm("Are you sure you want to remove all hospital wait times from this call?");

      if (allowClick) {
        this.resetDispositionDValues();
        this.clearWaitTime(Array.from({length: this.props.hospital_data_object.length}, (v, i) => i))
      } else {
        return;
      }
    } else if (!prevDispOpt.destinationSelectEnabled && dispOpt.destinationSelectEnabled && this.props.hospital_data_object.length === 0) {
      this.props.addHospital();
      this.resetDispositionDValues();
    } else {
      this.resetDispositionDValues();
    }

    this.props.report_object['sectionDActions'][index] = 1
    this.props.report_object['selectedDispositionKey'] = value

    if (
      prevDispOpt.dropdownEnabled &&
      prevDispOpt.dropdownKey &&
      prevDispOpt.dropdownKey !== dispOpt.dropdownKey
    ) {
      this.props.report_object[prevDispOpt.dropdownKey] = "";
    }

    if (
      dispOpt.dropdownEnabled &&
      dispOpt.dropdownKey &&
      dispOpt.dropdownOptions.length === 1
    ) {
      this.props.report_object[dispOpt.dropdownKey] = dispOpt.dropdownOptions[0];
    }

    if (!dispOpt.callOutcomesEnabled) {
      this.props.report_object["patientAdmitted"] = "";
    }

    this.forceUpdate();
  }

  componentDidMount() {
    if (this.props.report_object['sectionDActions'][4] != 1) {
      this.props.report_object["apprehensionType"] = "";
    }
    const {disposition_options} = this.props;
    const selected_disposition_key = this.props.report_object["selectedDispositionKey"]
    const dispOpt = disposition_options[selected_disposition_key];
    if (this.props.hospital_data_object.length === 0 && dispOpt.destinationSelectEnabled) {
      this.props.addHospital();
    }
    this.forceUpdate();
  }

  render() {
    const selected_disposition_key = this.props.report_object["selectedDispositionKey"]
    const selected_disposition = this.props.disposition_options[selected_disposition_key]
    const selected_disposition_label = selected_disposition.text
    let dropdown_options = []
    if (selected_disposition.dropdownEnabled) {
      dropdown_options = selected_disposition.dropdownOptions.map((val) => {
        return {value: val, label: val}
      })
    }

    let call_outcome_options = []
    if (selected_disposition.callOutcomesEnabled) {
      call_outcome_options = selected_disposition.callOutcomes.map((val) => {
        return {value: val, label: val}
      })
      if (selected_disposition.transfersEnabled) {
        call_outcome_options.push({value: "Transferred", label: "Transferred"})
      }
    }

    return (
      <div id="call-log-container" className="container-report">
        <div className="title-container">
          <h1 className="title-container-head">CALL LOG</h1>
        </div>

        <div id="form-times-container" className="content-area-container">
          <RecordEditorDateTime
            title="HealthIM initiated:"
            tooltip="Indicates when Officers selected “Start New Call” in HealthIM."
            field_id="date"
            datetime_value={this.props.report_time_fields}
          />

          <RecordEditorDateTime
            title="Arrival on scene:"
            tooltip="Indicates when Officers arrived at the incident location."
            field_id="arrivalTimestamp"
            datetime_value={this.props.report_time_fields}
          />

          <RecordEditorSelect
            title="Call Outcome:"
            tooltip={
              <DispositionOptionToolTip disposition_options={this.props.disposition_options}/>
            }
            select_id="disposition"
            selected={selected_disposition_key}
            selected_label={selected_disposition_label}
            select_options={this.call_outcome_select_options}
            select_prompt="Select call outcome:"
            handleSelectChange={this.handleCallOutcomeChange}
            show_dropdown={true}
          />

          {
            selected_disposition.dropdownEnabled && (
              <RecordEditorSelect
                title={`${selected_disposition.dropdownTitle}:`}
                tooltip={undefined}
                select_id={selected_disposition.dropdownKey}
                selected={this.props.report_object[selected_disposition.dropdownKey]}
                selected_label={this.props.report_object[selected_disposition.dropdownKey]}
                select_options={dropdown_options}
                select_prompt={`Select ${selected_disposition.dropdownTitle}:`}
                handleSelectChange={this.props.handleSelectChange}
                show_dropdown={selected_disposition.dropdownEnabled}
              />
            )
          }


          {
            this.props.hospital_data_object.map((item, index) => {
              return (
                <HospitalTemplate
                  key={index}
                  index={index}
                  possible_destinations={this.props.possible_destinations}
                  report_object={this.props.report_object}
                  hospital_object={item}
                  hospital_data_object={this.props.hospital_data_object}
                  addHospital={this.props.addHospital}
                  clearHospitalsAfter={this.props.clearHospitalsAfter}
                  call_outcome={selected_disposition_label}
                  hospital_outcome_enabled={selected_disposition.callOutcomesEnabled}
                  hospital_waittime_enabled={selected_disposition.waitTimeEnabled}
                  hospital_outcome_select_options={call_outcome_options}
                  hospital_outcome_tooltip={
                    <HospitalOutcomeTooltip transfer_available={this.props.transfer_available}/>
                  }
                  number_of_hospitals={this.props.hospital_data_object.length}
                  checkTransfer={this.checkTransfer}
                  hospital_datetime={this.props.hospital_datetime}
                  report_time_fields={this.props.report_time_fields}
                />
              )
            })
          }

          <RecordEditorDateTime
            title="End Call Time:"
            tooltip="Indicates when Officers ended the call."
            field_id="endCallTime"
            datetime_value={this.props.report_time_fields}
          />
        </div>
      </div>
    );
  }
}

function DispositionOptionToolTip(props) {
  let noActionText = 'The individual was not brought to a hospital.'
  if (props.disposition_options['noAction'] && props.disposition_options['noAction'].destinationSelectEnabled) {
    noActionText = 'The individual remained on scene, or was transferred to the care of another responding agency.'
  }
  let appEgText = ' (e.g., physician’s order to apprehend)';
  if (
    props.disposition_options['existingOrder'] &&
    props.disposition_options['existingOrder'].dropdownEnabled &&
    props.disposition_options['existingOrder'].dropdownOptions &&
    props.disposition_options['existingOrder'].dropdownOptions.length > 0
  ) {
    appEgText = ` (e.g., ${props.disposition_options['existingOrder'].dropdownOptions[0]})`
  }
  if (props.disposition_options['arrest'] && !props.disposition_options['arrest'].hidden) {
    return (
      <div className="dropdown-tooltip">
        Indicates what action was taken by the officer: <br/>
        • <b>Not Transported</b>: {noActionText}<br/>
        • <b>Voluntarily Transported</b>: The individual was brought to healthcare facility voluntarily.<br/>
        • <b>Involuntarily Apprehended</b>: The individual was apprehended under emergency police powers and
        brought to healthcare facility for involuntary psychiatric assessment.<br/>
        • <b>Apprehension under existing order</b>: The individual was apprehended under an existing
        order{appEgText} and transported to healthcare facility.<br/>
        • <b>Arrest/Detention</b>: The individual was arrested and taken to a detention facility.<br/>
      </div>
    )
  }
  return (
    <div className="dropdown-tooltip">
      Indicates what action was taken by the officer: <br/>
      • <b>Not Transported</b>: {noActionText}<br/>
      • <b>Voluntarily Transported</b>: The individual was brought to healthcare facility voluntarily.<br/>
      • <b>Involuntarily Apprehended</b>: The individual was apprehended under emergency police powers and
      brought to healthcare facility for involuntary psychiatric assessment.<br/>
      • <b>Apprehension under existing order</b>: The individual was apprehended under an existing order{appEgText} and
      transported to healthcare facility.<br/>
    </div>
  )
}

class HospitalTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hospital_outcome: (this.props.index + 1) === this.props.number_of_hospitals ? this.props.report_object["patientAdmitted"] : "Transferred"
    };
    this.props.hospital_object["datetime"] = {};

    for (var dt in this.props.hospital_datetime) {
      var field_name = this.props.hospital_datetime[dt];
      var r_t = {};
      r_t["date"] = this.props.hospital_object[field_name] && this.props.hospital_object[field_name].length > 0 ? formatDate(this.props.hospital_object[field_name]) : '';
      r_t["time"] = this.props.hospital_object[field_name] && this.props.hospital_object[field_name].length > 0 ? formatTime(this.props.hospital_object[field_name]) : '';
      this.props.hospital_object["datetime"][field_name] = r_t;
    }

    this.handleHospitalDestinationChange = this.handleHospitalDestinationChange.bind(this);
    this.hospitalOutcomeChange = this.hospitalOutcomeChange.bind(this);
  }

  handleHospitalDestinationChange(selected) {
    if (!this.props.report_object["destinations"]) {
      this.props.report_object["destinations"] = []
    }

    var last_index = this.props.report_object["destinations"].indexOf(this.props.hospital_object.id);

    if (last_index >= 0) {
      this.props.report_object["destinations"].splice(last_index, 1);
    }

    if (this.props.report_object["destinations"].indexOf(selected.value) < 0 && selected.value > 0) {
      this.props.report_object["destinations"].push(selected.value);
    }

    this.props.hospital_object.id = selected.value;
    this.props.hospital_object.name = selected.label;
    this.forceUpdate();

    this.props.checkTransfer(this.props.index)
  }

  hospitalOutcomeChange(selected) {
    var value = selected.value;
    var prev = this.state.hospital_outcome;

    if (prev !== "Transferred" && value === "Transferred") {
      this.props.addHospital();
      this.props.report_object["patientAdmitted"] = "";
    } else if (prev === "Transferred" && value !== "Transferred") {
      var allowClick = confirm("Are you sure you want to remove all hospital wait times after this hospital?");
      if (allowClick) {
        this.props.clearHospitalsAfter(this.props.index);
        this.props.report_object["patientAdmitted"] = value;
      } else {
        return;
      }
    } else {
      this.props.report_object["patientAdmitted"] = value;
    }
    this.setState({hospital_outcome: selected.value});
    this.forceUpdate();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.hospital_outcome_enabled && !this.props.hospital_outcome_enabled) {
      this.setState({hospital_outcome: ''})
    }
  }

  render() {
    let poss_dest = this.props.possible_destinations;
    if (this.props.call_outcome.toUpperCase() === 'NOT TRANSPORTED') {
      poss_dest = [{label: 'No Transport', value: -1}].concat(this.props.possible_destinations)
    }
    return (
      <div key={this.props.index} id="hospital-template">
        <RecordEditorSelect
          title="Destination:"
          tooltip="Indicates which health care facility the subject was transported to."
          select_id="hospitalSelect"
          selected={this.props.hospital_object.id.toString()}
          selected_label={this.props.hospital_object.name.toString()}
          select_options={poss_dest}
          select_prompt="Select Hospital:"
          select_prompt_value=""
          select_filter={this.props.index > 0 ? this.props.hospital_data_object[this.props.index - 1].id.toString() : undefined}
          handleSelectChange={this.handleHospitalDestinationChange}
          show_dropdown={true}
        />

        <SubmittedOrBeforeTravel
          index={this.props.index}
          hospital_object={this.props.hospital_object}
          call_outcome={this.props.call_outcome}
          report_object={this.props.report_object}
          report_time_fields={this.props.report_time_fields}
        />
        {
          this.props.hospital_waittime_enabled &&
          this.props.hospital_object.id &&
          this.props.hospital_object.id >= 0 &&
          (
            <RecordEditorDateTime
              title={`Arrival at ${this.props.hospital_object.name.length > 0 ? this.props.hospital_object.name : "hospital"}:`}
              tooltip="Indicates when Officers arrived at the health care facility."
              field_id="arriveAtED"
              datetime_value={this.props.hospital_object}
            />
          )
        }

        {
          this.props.hospital_waittime_enabled &&
          this.props.hospital_object.id &&
          this.props.hospital_object.id >= 0 && (
            <RecordEditorDateTime
              title={`Exited at ${this.props.hospital_object.name.length > 0 ? this.props.hospital_object.name : "hospital"}:`}
              tooltip="Indicates when Officers were released from the health care facility."
              field_id="departFromED"
              datetime_value={this.props.hospital_object}
            />
          )
        }

        {
          this.props.hospital_outcome_enabled && (
            <RecordEditorSelect
              title="Outcome at Hospital:"
              tooltip={this.props.hospital_outcome_tooltip}
              select_id="hospitalPatientAdmitted"
              selected={this.state.hospital_outcome}
              selected_label={this.state.hospital_outcome}
              select_options={this.props.hospital_outcome_select_options}
              select_prompt="Select hospital outcome:"
              select_prompt_value=""
              handleSelectChange={this.hospitalOutcomeChange}
              show_dropdown={this.props.hospital_outcome_enabled}
            />
          )
        }

      </div>
    )
  }
}

function SubmittedOrBeforeTravel(props) {
  if (props.index === 0) {
    return (
      <RecordEditorDateTime
        title={`${props.call_outcome}:`}
        tooltip={`Indicates when Officers made the decision to ${props.call_outcome}.`}
        field_id="reportEnd"
        datetime_value={props.report_time_fields}
      />
    )
  } else {
    return (
      <RecordEditorDateTime
        title={`Departed for ${props.hospital_object.name.length > 0 ? props.hospital_object.name : "hospital"}:`}
        tooltip="Indicates when Officers departed from one health care facility to transfer the subject to the next health care facility."
        field_id="beforeTravel"
        datetime_value={props.hospital_object}
      />
    )
  }
}

function HospitalOutcomeTooltip(props) {
  if (props.transfer_available) {
    return (
      <div className="dropdown-tooltip">
        Indicates which outcome occurred at the hospital:<br/>
        • <b>Not Admitted</b><br/>
        • <b>Admitted</b><br/>
        • <b>Unknown</b><br/>
        • <b>Transferred:</b> Officers were tasked with transporting the individual to a second health facility.<br/>
      </div>
    )
  } else {
    return (
      <div className="dropdown-tooltip">
        Indicates which outcome occurred at the hospital:<br/>
        • <b>Not Admitted</b><br/>
        • <b>Admitted</b><br/>
        • <b>Unknown</b><br/>
      </div>
    )
  }
}

export default PlumCouleeCallLog