import React from 'react'
import {Modal} from "react-bootstrap"

//Input: show_archive_modal, handleHideArchiveModal, report_id
function ArchiveModal(props) {
  return (
    <div>
      <Modal id="archive_report_modal"
             backdropClassName="report_view_edit_modal_backdrop"
             show={props.show_archive_modal} backdrop={true} keyboard={false} animation={false}
             onHide={props.handleHideArchiveModal} dialogClassName={"re-modal-content"}>
        <div>
          <h2 id="archive_modal_title">Archive Record</h2>
        </div>

        <div id="archive_modal_text_container">
          <div className="archive_modal_text_body_container">
            <p className="archive_modal_text_body">
              <b>Archived records will not appear in HealthIM search results and will not be linked to other
                records.</b>
            </p>
          </div>

          <div className="archive_modal_text_body_container">
            <p className="archive_modal_text_body">You may restore an archived record later by searching the
              incident number and selecting "Restore"</p>
          </div>

          <div className="archive_modal_text_body_container">
            <p className="archive_modal_text_body">Select "Confirm" to archive the record.</p>
          </div>

          <div className="archive_modal_text_body_container">
            <p className="archive_modal_text_body">Select "Cancel" to go back.</p>
          </div>
        </div>
        <div id="archive_modal_button_container">
          <button id="archive_modal_cancel_button" className="automated-button"
                  onClick={props.handleHideArchiveModal}>Cancel
          </button>
          <button id="archive_modal_confirm_button" className="automated-button "
                  onClick={() => {
                    archiveReport(props.report_id, true)
                  }}>Confirm
          </button>
        </div>
      </Modal>
    </div>
  );
}

export default ArchiveModal
