import React from 'react'
import DatePicker from "react-datepicker";
import Select from 'react-select';
import {isValid, parseISO} from 'date-fns'
import "react-datepicker/dist/react-datepicker.css";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

function InfoCircle(props) {
  if (props.tooltip !== undefined) {
    return (
      <div className="info-circle-container">
        <div className="tooltip-container">
          <InfoOutlinedIcon
            style={{
              color: "#828282",
              background: 'transparent',
              fontSize: 20
            }}
          />
          <span className="tooltiptext">{props.tooltip}</span>
        </div>
      </div>
    )
  } else {
    return (<div className="hidden"></div>)
  }
}

function RecordEditorSelect(props) {
  return (
    <div className={`update-dropdown-container ${props.show_dropdown ? 'record-editor-select' : 'hidden'}`}>
      <div className="time-label-container">
        <div className="time-label">{props.title}</div>
      </div>
      <InfoCircle
        tooltip={props.tooltip}
      />

      <Select
        classNamePrefix="re-select"
        onChange={(value) => {
          props.handleSelectChange(value, props.select_id)
        }}
        value={props.selected ? {value: props.selected, label: props.selected_label} : null}
        placeholder={props.select_prompt}
        options={props.select_options.filter(val => {
          return props.select_filter == undefined || props.select_filter.toString() !== val.value.toString()
        })}
      />
      <br/>
    </div>
  )
}

class RecordEditorDateTime extends React.Component {
  constructor(props) {
    super(props);

    this.handleTimeChange = this.handleTimeChange.bind(this);
    this.handleTimeBlur = this.handleTimeBlur.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
  }

  handleTimeChange(e) {
    if (this.props.datetime_value["missing"] && this.props.datetime_value["missing"][this.props.field_id]) {
      this.props.datetime_value["missing"][this.props.field_id]["date"] = false;
      this.props.datetime_value["missing"][this.props.field_id]["time"] = false;
    }

    var value = e.target.value.replace(' ', '');
    if (value.substr(-1).match(/[0-9]/) == null || value.length > 5) {
      e.target.value = value.substr(0, value.length - 1)
    }
    this.props.datetime_value["datetime"][this.props.field_id]["time"] = e.target.value;
    this.forceUpdate();
  }

  handleTimeBlur(e) {
    var value = e.target.value.replace(/\s/g, '');
    e.target.value = int2time(value);
    this.props.datetime_value["datetime"][this.props.field_id]["time"] = e.target.value;
    this.forceUpdate();
  }

  handleDateChange(date) {
    if (this.props.datetime_value["missing"] && this.props.datetime_value["missing"][this.props.field_id]) {
      this.props.datetime_value["missing"][this.props.field_id]["date"] = false;
      this.props.datetime_value["missing"][this.props.field_id]["time"] = false;
    }
    this.props.datetime_value["datetime"][this.props.field_id]["date"] = parseDateString(date)["date"];
    this.forceUpdate();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.props.datetime_value["datetime"][this.props.field_id]["title"] = this.props.title.replace(/:/g, '');
  }

  render() {
    return (
      <div className="update-times-container datetime-container">
        <div className="time-label-container">
          <span className="time-label">{this.props.title}</span>
        </div>
        <div className="update-fields-box">
          <DatePicker onChange={this.handleDateChange}
                      selected={isValid(parseISO(this.props.datetime_value["datetime"][this.props.field_id]["date"])) ? parseISO(this.props.datetime_value["datetime"][this.props.field_id]["date"]) : ''}
                      dateFormat="yyyy-MM-dd"
                      className={this.props.datetime_value["missing"] && this.props.datetime_value["missing"][this.props.field_id] && this.props.datetime_value["missing"][this.props.field_id]["date"] ? "missing" : ""}
                      placeholderText="YYYY-MM-DD"/>
        </div>

        <div className="update-fields-box">
          <input
            className={`time-field right ${this.props.datetime_value["missing"] && this.props.datetime_value["missing"][this.props.field_id] && this.props.datetime_value["missing"][this.props.field_id]["time"] ? "missing" : ""}`}
            onChange={this.handleTimeChange}
            onBlur={this.handleTimeBlur}
            value={this.props.datetime_value["datetime"][this.props.field_id]["time"] || ''}
            placeholder="HH:MM"/>
        </div>

        <InfoCircle
          tooltip={this.props.tooltip}
        />
      </div>
    )
  }
}

export {RecordEditorDateTime, RecordEditorSelect}