import React from 'react'
import {Modal} from "react-bootstrap";

class PlumCouleeMentalHealthReferral extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show_referral_consent: false,
      selected_cmha: 0,
      selected_consent: false,
      consent_required: false
    };

    this.handleReferralPressed = this.handleReferralPressed.bind(this);
    this.handleHideConsentModal = this.handleHideConsentModal.bind(this);
    this.addDestinationToReport = this.addDestinationToReport.bind(this);
    this.consentPressed = this.consentPressed.bind(this);
    this.consentConfirm = this.consentConfirm.bind(this);
  }

  consentPressed(value) {
    this.setState({selected_consent: value})
  }

  consentConfirm(questionIndex) {
    const value = this.state.selected_consent
    this.props.report_object['cmhResponses'][questionIndex]['offered'] = 1;
    this.props.report_object['cmhResponses'][questionIndex]['consent'] = value;
    this.forceUpdate();

    this.addDestinationToReport(questionIndex, value);
    this.handleHideConsentModal();
  }

  addDestinationToReport(questionIndex, addDestination) {
    if (this.props.report_object["destinations"]) {
      if (addDestination) {
        if (this.props.report_object["destinations"].indexOf(this.props.report_object['cmhResponses'][questionIndex]["id"]) < 0) {
          this.props.report_object["destinations"].push(this.props.report_object['cmhResponses'][questionIndex]["id"]);
        }
      } else {
        var arrayIndex = this.props.report_object["destinations"].indexOf(this.props.report_object['cmhResponses'][questionIndex]["id"]);

        if (arrayIndex >= 0) {
          this.props.report_object["destinations"].splice(arrayIndex, 1);
        }
      }
    } else {
      if (addDestination) {
        this.props.report_object["destinations"] = [this.props.report_object['cmhResponses'][questionIndex]["id"]];
      }
    }
    this.forceUpdate();
  }

  handleReferralPressed(questionIndex, value) {
    if (value && this.props.report_object['cmhResponses'][questionIndex]['consentRequired']) {
      this.setState({selected_cmha: questionIndex});
      this.setState({show_referral_consent: true});
      this.setState({selected_consent: this.props.report_object['cmhResponses'][questionIndex]['consent']})
      this.setState({consent_required: this.props.report_object['cmhResponses'][questionIndex]['consentRequired']})
    } else if (value) {
      this.setState({selected_cmha: questionIndex});
      this.setState({show_referral_consent: true});
      this.setState({selected_consent: this.props.report_object['cmhResponses'][questionIndex]['consent']})
      this.setState({consent_required: this.props.report_object['cmhResponses'][questionIndex]['consentRequired']})
    } else {
      this.props.report_object['cmhResponses'][questionIndex]['offered'] = value;
      this.props.report_object['cmhResponses'][questionIndex]['consent'] = false;
      this.addDestinationToReport(questionIndex, value);
      this.forceUpdate()
    }
  }

  handleHideConsentModal() {
    this.setState({show_referral_consent: false})
  }

  render() {
    if (this.props.report_object.hasOwnProperty('cmhResponses') && this.props.report_object["cmhResponses"].length > 0) {
      return (
        <div>
          <p id="mental-health-referral-title" className="container-sub-head">Mental Health Referral </p>
          {
            this.props.report_object["cmhResponses"].map((item, index) => {
              return (
                <MentalHealthReferralOptions
                  key={index}
                  response={item}
                  index={index}
                  handleReferralPressed={this.handleReferralPressed}
                  originalResponse={this.props.original_report_object["cmhResponses"][index]}
                />
              )
            })
          }

          <MentalHealthReferralConsent
            show_referral_consent={this.state.show_referral_consent}
            handleHideConsentModal={this.handleHideConsentModal}
            index={this.state.selected_cmha}
            response={this.props.report_object["cmhResponses"][this.state.selected_cmha]}
            consentPressed={this.consentPressed}
            consentConfirm={this.consentConfirm}
            selected_consent={this.state.selected_consent}
            consent_required={this.state.consent_required}
          />

          <br className="re-line-break"/>
          <hr className="re-hr"/>
        </div>
      )
    } else {
      return (
        <div></div>
      )
    }
  }
}

function MentalHealthReferralConsent(props) {
  return (
    <div>
      <Modal id="report_view_edit_modal"
             backdropClassName="report_view_edit_modal_backdrop"
             show={props.show_referral_consent} backdrop={true} keyboard={false} animation={false}
             onHide={props.handleHideConsentModal} dialogClassName={"re-consent-modal-content"}>
        <div id="consent-header-container">
          <h1 id="pc-consent-header-title"
              className="title-container-head">{props.consent_required ? 'CONSENT REQUIRED' : 'CONFIRM REFERRAL'}</h1>
          <div id="consent-cancel-title" onClick={props.handleHideConsentModal}>Cancel</div>
        </div>
        <br/>
        <br/>
        <br/>

        {props.consent_required ? (
          <div>
            <div id="consent-body-container">
              <div className="pc-re-bold-text"> Referral to {props.response["name"]} requires that the client provide
                informed consent.
              </div>
              <div className="pc-re-text"> Please indicate whether consent was given or declined by the client.</div>
            </div>
            <div id="consent-button-container">
              <div className={`pc-consent-button left ${props.selected_consent ? '' : 'selected'}`} tabIndex="0"
                   onClick={() => {
                     props.consentPressed(false)
                   }}>
                <p className="consent-option-text">Consent Declined</p>
              </div>
              <div className={`pc-consent-button right ${props.selected_consent ? 'selected' : ''}`} tabIndex="0"
                   onClick={() => {
                     props.consentPressed(true)
                   }}>
                <p className="consent-option-text">Consent Received</p>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <div id="consent-body-container">
              <div className="pc-re-bold-text"> A referral will be sent to {props.response["name"]}. Select “Confirm”,
                then select “Save” to transmit the referral. To cancel this transmission, select “Cancel”.
              </div>
            </div>
          </div>
        )}


        <div id="consent-button-confirm-container">
          <div id="re-consent-button-grey" onClick={() => {
            props.handleHideConsentModal();
          }}
          >
            Cancel
          </div>
          <div id="re-consent-button" onClick={() => {
            props.consentConfirm(props.index);
            props.handleHideConsentModal();
          }}
          >
            Confirm
          </div>
        </div>
      </Modal>
    </div>
  );
}

class MentalHealthReferralOptions extends React.Component {
  constructor(props) {
    super(props);

    this.consentText = this.consentText.bind(this)
    this.showCancel = this.showCancel.bind(this)
  }

  showCancel() {
    if (this.props.response["offered"] == 1) {
      if (this.props.response["consentRequired"]) {
        if (this.props.response["consent"]) {
          return true
        } else {
          return false
        }
      } else {
        return true
      }

    } else {
      return false
    }
  }

  consentText() {
    if (this.props.response["offered"] == 1) {
      if (this.props.response["consentRequired"]) {
        if (this.props.response["consent"]) {
          return "Consent Received".toUpperCase()
        } else {
          return "Consent Declined".toUpperCase()
        }
      } else {
        return "Consent Not Required".toUpperCase()
      }

    } else {
      return ""
    }
  }

  render() {
    if (this.props.originalResponse["offered"]) {
      if ((this.props.originalResponse["consentRequired"] && this.props.originalResponse["consent"]) || (!this.props.originalResponse["consentRequired"])) {
        return (
          <div key={this.props.index} className="section-b-question" id={"cmh-" + this.props.index}>
            <div className="mental-health-referral-option-container">
              <div className="section-b-sub-header">
                <p className="indicators consent-indicators">
                  {this.props.response["name"]}
                </p>
              </div>
              <div className="referral-bmhs-cell-container" id={"referral-" + this.props.index}>
                <p className="consent-text-no-button"> REFERRAL SENT, {this.consentText()}, {getFullFormattedDateString(this.props.originalResponse['created_at'])} </p>
              </div>
            </div>
          </div>
        )
      }
    }
    return (
      <div key={this.props.index} className="section-b-question" id={"cmh-" + this.props.index}>
        <div className="mental-health-referral-option-container">
          <div className="section-b-sub-header">
            <p className="indicators consent-indicators">
              {this.props.response["name"]}
            </p>
          </div>
          {this.showCancel() ? (
            <div className="referral-bmhs-cell-container" id={"referral-" + this.props.index}>
              <div id="re-consent-button-cancel" onClick={() => {
                this.props.handleReferralPressed(this.props.index, 0)
              }}
              >
                Cancel
              </div>
              <p className="consent-text"> {this.consentText()} </p>
            </div>
          ) : (
            <div className="referral-bmhs-cell-container" id={"referral-" + this.props.index}>
              <div id="re-consent-button-offer" onClick={() => {
                this.props.handleReferralPressed(this.props.index, 1)
              }}
              >
                Offer Referral
              </div>
              <p className="consent-text"> {this.consentText()} </p>
            </div>
          )}

        </div>
      </div>
    );
  }
}

export default PlumCouleeMentalHealthReferral