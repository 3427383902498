import {ResponsiveScatterPlot} from "@nivo/scatterplot";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import React from "react";

function PlotGraph(props) {
  var missing_text = "";
  if (props.missing_count) {
    missing_text = "Note: " + props.missing_count + " cases excluded due to missing data"
  }
  return (
    <div className="graph-sub-container"
         style={{backgroundColor: props.viewing ? "#1a1c1f" : "transparent"}}
    >
      <div id="graph-title-text-container" className="graph-title-text-container">
        <div className="graph-title-text" style={{color: props.graph_colour}}>
          <p>{props.graph_title}</p>
        </div>
        <div className="graph-sub-title-text">
          <p>{props.graph_sub_title}</p>
        </div>
        <div className="graph-missing-title-text">
          <p>{missing_text}</p>
        </div>
      </div>
      <ChartPlot
        formatValue={props.formatValue}
        graph_data={props.graph_data}
        hover={props.hover}
        show_hover={props.show_hover}
        graph_colour={props.graph_colour}
        viewing={props.viewing}
        index_by={props.index_by}
        index_by_label={props.index_by_label}
        graph_output_format={props.graph_output_format}
        handleLegendClick={props.handleLegendClick}
        y_axis_label={props.y_axis_label}
        max_val={props.max_val}
        opacity_text={props.opacity_text}
      />
      <br/><br/>
      <div id="graph-legend-container" className="graph-legend-container">
        <div id="graph-legend-text" className="graph-legend-title-text" style={{color: props.graph_colour}}>
          <p>Legend</p>
        </div>

        <Grid id="graph-legend" container spacing={2}>
          {
            props.graph_full_data.map((item, index) => {
              return (
                <Tooltip
                  key={index}
                  title={props.getTooltipText(item, "all_data")}
                  placement='bottom-end'
                >
                  <Grid key={index} item xs={3} sm={3}
                        className={item["show"] ? "graph-legend-item-container" : ""}
                        onClick={() => {
                          props.handleLegendClick(item["index"], "index", "find")
                        }}
                        onMouseEnter={() => {
                          props.handleLegendOnHover(item["index"])
                        }}
                        onMouseLeave={() => {
                          props.handleLegendOnHoverExit(item["index"])
                        }}
                        style={{
                          display: item["show"] || props.viewing ? "block" : "none",
                          opacity: item["show"] ? 1 : 0.2
                        }}
                  >

                    <div className="graph-legend-item" style={{color: props.graph_colour}}>
                      <span
                        className="graph-legend-icon"
                        style={{
                          backgroundColor: item["color"],
                          height: 20,
                          width: 20,
                          float: "left",
                          borderRadius: "50%",
                          margin: 5,
                          border: "1px solid #000000"
                        }}
                      />
                      <div className="graph-label" style={{color: props.graph_colour}}> {item["id"]} </div>
                    </div>

                  </Grid>
                </Tooltip>
              )
            })
          }

        </Grid>
      </div>
    </div>
  )
}

function ChartPlot(props) {
  var y_labels = undefined;
  if (props.max_val < 8) {
    y_labels = Array.apply(null, {length: props.max_val + 1}).map(Number.call, Number)
  }

  return (
    <div className="graph-chart-container">
      <canvas id="temp-canvas"/>
      <img id="temp-img"/>
      <ResponsiveScatterPlot
        data={props.graph_data}
        margin={{top: 40, right: 70, bottom: 90, left: 150}}
        yScale={{
          type: 'linear',
          min: 0,
          max: props.graph_output_format === "percentage" ? 100 : "auto",
          stacked: false,
          reverse: false
        }}
        colors={d => props.hover ? (d.show_hover ? d.color : d.color ? d.color.replace("1)", props.opacity_text) : d.color) : d.color}
        nodeSize={15}
        animate={false}
        tooltip={function (v) {
          var nd = v.node;
          return (<div className="graph-tooltip">
            <div className="graph-square" style={{backgroundColor: nd.data.color}}></div>
            <span className="graph-tooltip-text">
              {nd.data.serieId}: <strong>{props.formatValue(nd.data.y, true, true)}</strong>
            </span>
          </div>)
        }}
        axisBottom={{
          tickSize: 5,
          tickPadding: 15,
          legend: props.index_by_label,
          legendPosition: 'middle',
          legendOffset: 80
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 25,
          legend: props.y_axis_label,
          legendPosition: 'middle',
          legendOffset: -110,
          tickValues: y_labels,
          format: function (v) {
            return props.formatValue(v, false, true)
          }
        }}
        theme={
          {
            labels: {
              text: {
                fontSize: 16,
                fontFamily: "Helvetica"
              }
            },
            dots: {
              text: {
                fill: props.graph_colour,
                fontSize: 16,
                fontFamily: "Helvetica"
              }
            },
            axis: {
              ticks: {
                line: {
                  stroke: "#404040"
                },
                text: {
                  fill: props.graph_colour,
                  fontSize: 16,
                  fontFamily: "Helvetica"
                }
              },
              legend: {
                text: {
                  fill: props.graph_colour,
                  fontSize: 16,
                  fontFamily: "Helvetica"
                }
              }
            },
            grid: {
              line: {
                stroke: "#404040",
                strokeWidth: 1
              }
            },
          }
        }
      />
    </div>
  )
}

export default PlotGraph