import React, {Component} from "react";
import Select, {components} from "react-select";
import BarChartIcon from '@material-ui/icons/BarChart';
import PieChartIcon from '@material-ui/icons/PieChart';
import TimelineIcon from '@material-ui/icons/Timeline';
import ScatterPlotIcon from '@material-ui/icons/ScatterPlot';
import DeleteIcon from '@material-ui/icons/Delete';
import {Modal} from "react-bootstrap";
import Grid from "@material-ui/core/Grid";
import {DataViewerCheckBox} from './data_viewer_custom_fields'

class PresetOptions extends React.Component {
  constructor(props) {
    super(props);

    this.handleDeleteClick = this.handleDeleteClick.bind(this)
  }

  handleDeleteClick() {
    var c = confirm("Are you certain you want to delete this preset?");
    if (c === true) {

      $.ajax({
        url: '/data_viewer/delete_preset/' + this.props.data.id + '.json',
        type: 'DELETE',
        success: function (data) {
          alert("Delete Successful")
        }
      });

      this.props.data.deleted = true;
    }
  }

  render() {

    return (
      <components.Option {...this.props}>
        <div className="row">
          <DataViewerGraphTypeHorizontal
            label={this.props.data.label}
            graph_type={this.props.data.graph_type}
            disabled={this.props.data.disabled}
          />

          <div className="col-xs-1">
            <PresetDeleteIcon
              group={this.props.data.group}
              handleDeleteClick={this.handleDeleteClick}
              disabled={this.props.data.disabled}
            />

          </div>
        </div>
      </components.Option>
    );
  }
}

function DataViewerGraphTypeHorizontal(props) {
  var bar_icons = {
    "bar": BarChartIcon,
    "pie": PieChartIcon,
    "line": TimelineIcon,
    "plot": ScatterPlotIcon,
  };
  const GraphIcon = bar_icons[props.graph_type];
  if (props.disabled) {
    return (
      <div className="col-xs-9">
        <div className="data-viewer-preset-label-disabled">{props.label}</div>
      </div>
    )
  } else {
    return (
      <div className="col-xs-9">
        <GraphIcon
          style={{
            color: "#ffffff",
            background: 'transparent',
            fontSize: 28,
            float: "left"
          }}
        />
        <div className="data-viewer-preset-label">{props.label}</div>
      </div>
    )
  }


}

function PresetDeleteIcon(props) {
  if (props.group === "user" && !props.disabled) {
    return (
      <DeleteIcon
        onClick={() => {
          props.handleDeleteClick()
        }}
        style={{
          color: "#ffffff",
          background: 'transparent',
          fontSize: 28,
          float: "left",
          cursor: "pointer"
        }}
      />
    )
  } else {
    return (
      <div></div>
    )
  }
}

function SavePresetModal(props) {
  return (
    <div>
      <Modal id="archive_report_modal"
             backdropClassName="report_view_edit_modal_backdrop"
             show={props.show_preset_save_modal} backdrop={true} keyboard={false} animation={false}
             onHide={props.handleHidePresetSaveModal} dialogClassName={"re-modal-content"}>
        <div>
          <h2 id="archive_modal_title">Save as Preset</h2>
        </div>

        <div id="archive_modal_text_container">
          <div className="archive_modal_text_body_container">
            <p className="archive_modal_text_body">Enter a name for your custom preset.</p>
          </div>

          <input id="preset-save-input-box"
                 onChange={props.handlePresetNameChange}/>
        </div>
        <div id="archive_modal_button_container">
          <button id="archive_modal_cancel_button" className="automated-button"
                  onClick={props.handleHidePresetSaveModal}>Cancel
          </button>
          <button id="archive_modal_confirm_button" className="automated-button"
                  onClick={props.handlePresetNameConfirm}>Confirm
          </button>
        </div>
      </Modal>
    </div>
  );
}


class SharePresetModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user_ids: [],
      preset_ids: []
    };

    this.handlePresetClose = this.handlePresetClose.bind(this);
    this.handlePresetConfirm = this.handlePresetConfirm.bind(this);
    this.handlePresetOptionClick = this.handlePresetOptionClick.bind(this);
    this.presetOptionStatus = this.presetOptionStatus.bind(this);
    this.handlePresetSelectChange = this.handlePresetSelectChange.bind(this);
    this.userFilterOptions = this.userFilterOptions.bind(this)
  }

  handlePresetClose() {
    this.setState({
      user_ids: [],
      preset_ids: []

    });
    this.props.handleHidePresetShareModal()
  }

  handlePresetConfirm() {
    var error_message = "";
    if (this.state.user_ids.length === 0) {
      error_message += "No presets selected\n"
    }

    if (this.state.user_ids.length === 0) {
      error_message += "No users selected\n"
    }


    if (error_message !== "") {
      var default_error_message = "Cannot Share Preset for the following reasons:\n";
      alert(default_error_message + error_message);
      return;
    }
    var user_ids = this.state.user_ids.map(u => {
      return u.value
    });
    var preset_ids = this.state.preset_ids;
    $.ajax({
      url: 'data_viewer/share_presets',
      type: 'PUT',
      data: {
        user_ids: user_ids,
        preset_ids: preset_ids
      }
    }).done(function (data) {
      this.setState({
        user_ids: [],
        preset_ids: []
      });
      alert("Presets were shared")
    }.bind(this));
    this.props.handleHidePresetShareModal()
  }

  handlePresetOptionClick(id) {
    if (this.presetOptionStatus(id)) {
      var index = this.state.preset_ids.indexOf(id);
      this.setState(prevState => ({
        preset_ids: prevState.preset_ids.slice(0, index).concat(prevState.preset_ids.slice(index + 1))
      }));
    } else {
      this.setState(prevState => ({
        preset_ids: prevState.preset_ids.concat([id])
      }));
    }
  }

  presetOptionStatus(id) {
    return this.state.preset_ids.indexOf(id) > -1
  }

  handlePresetSelectChange(option) {
    this.setState(state => {
      return {
        user_ids: option
      };
    });
  }

  userFilterOptions(candidate, input) {
    if (input) {
      return candidate.data.label.toLowerCase().includes(input.toLowerCase()) ||
        candidate.data.first_name.toLowerCase().includes(input.toLowerCase()) ||
        candidate.data.last_name.toLowerCase().includes(input.toLowerCase())
    }
    return true
  }

  render() {
    return (
      <div>
        <Modal id="share_preset_modal"
               backdropClassName="report_view_edit_modal_backdrop"
               show={this.props.show_preset_share_modal} backdrop={true} keyboard={false} animation={false}
               onHide={this.handlePresetClose} dialogClassName={"dv-sp-modal-content"}>
          <div>
            <h2 id="archive_modal_title">Share Presets</h2>
          </div>

          <div id="share_preset_modal_text_container">
            <div className="archive_modal_text_body_container">
              <p className="archive_modal_text_body">Select the presets you would like to share below,
                then enter the email addresses you would like to share with.</p>
            </div>
          </div>

          <div className="share-preset-option-container">
            <Grid container spacing={1}>
              {
                this.props.user_preset_options.map((item, index) => {
                  return (
                    <Grid key={index} item xs={12}>
                      <div className="preset-option-check" key={index}>
                        <DataViewerCheckBox
                          label={item["preset_name"]}
                          handleCheckClick={() => this.handlePresetOptionClick(item["id"])}
                          checked={this.presetOptionStatus[item["id"]]}
                        />
                      </div>
                    </Grid>
                  )
                })
              }

            </Grid>
          </div>

          <div id="share-preset-user-select">
            <div className="share-preset-title-text ">
              <p>Share Preset(s) with:</p>
            </div>
            <div className="graph-select-container">
              <Select
                classNamePrefix="dv-select"
                onChange={this.handlePresetSelectChange}
                value={this.state.user_ids}
                placeholder="Select..."
                isMulti={true}
                options={this.props.other_users}
                filterOption={this.userFilterOptions}
              />
            </div>
          </div>
          <div id="share_preset_button_container">
            <button id="archive_modal_cancel_button" className="automated-button"
                    onClick={this.handlePresetClose}>Cancel
            </button>
            <button id="archive_modal_confirm_button" className="automated-button"
                    onClick={this.handlePresetConfirm}>Share
            </button>
          </div>
        </Modal>
      </div>
    );
  }
}

export {SavePresetModal, SharePresetModal, DataViewerGraphTypeHorizontal, PresetOptions}