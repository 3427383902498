import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import StopSharpIcon from '@material-ui/icons/StopSharp';
import React from "react";
import {components} from "react-select";
import Grid from "@material-ui/core/Grid";

function DataViewerCheckBox(props) {
  var fill_colour = "#1a6acb";
  if (props.fill_colour) {
    fill_colour = props.fill_colour
  }
  var back_colour = "#1a6acb";
  if (props.back_colour) {
    back_colour = props.back_colour
  }

  var label_class = "graph-check-label";
  if (props.label_class) {
    label_class = props.label_class
  }

  return (
    <FormControlLabel
      classes={
        {
          label: label_class,
          root: label_class
        }
      }
      control={
        <Checkbox
          icon={
            <StopSharpIcon
              style={{
                color: "transparent",
                background: "transparent",
                fontSize: 18,
                border: "1px solid #bfbfbf"
              }}
            />
          }
          checkedIcon={
            <StopSharpIcon
              style={{
                color: fill_colour,
                background: back_colour,
                fontSize: 18,
                border: "1px solid #bfbfbf"
              }}
            />
          }
          checked={props.checked}
          onClick={props.handleCheckClick}
        />
      }
      label={props.label}
    />
  )
}

function DataViewerGraphType(props) {
  const GraphIcon = props.graph_icon;
  return (
    <div className="col-md-3">
      <div className={props.graph_type === props.graph_var ? "graph-type-active" : "graph-type"}
           onClick={() => {
             props.handleGraphTypeChange(props.graph_var)
           }}
      >
        <GraphIcon
          style={{
            color: "#ffffff",
            background: 'transparent',
            fontSize: 35,
            paddingLeft: 5,
            paddingTop: 5
          }}
        />
      </div>
      <div className="graph-type-text">
        <p>{props.graph_text}</p>
      </div>
    </div>
  )
}

const getTspanGroups = (value, maxLineLength, x_dy, force_new) => {
  const words = value.toString().split(' ');
  let children = [];
  let dy = x_dy;
  let curLine = "";
  let assembleLines = [];
  for (var i in words) {
    let word = words[i].toString();
    if (force_new || (curLine + " " + word).trim().length >= maxLineLength) {
      if (curLine === "") {
        assembleLines.push(word.trim());
      } else {
        assembleLines.push(curLine.trim());
        curLine = word
      }
    } else {
      curLine = curLine + " " + word
    }
  }
  assembleLines.push(curLine.trim());

  for (var i in assembleLines) {
    var lineText = assembleLines[i];
    children.push(
      <tspan style={{fontFamily: "Helvetica"}} x={0} dy={dy} key={i}>
        {lineText}
      </tspan>
    );
    dy = 15
  }

  return children
};

class ColorThemeOptions extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {

    return (
      <components.Option {...this.props}>
        <div>
          {this.props.data.label}
        </div>
        <Grid container spacing={0}>
          {
            this.props.data.opt.map((item, index) => {
              return (
                <Grid key={index} item xs={1} sm={1}
                      style={{
                        backgroundColor: `rgb(${item[0]},${item[1]},${item[2]})`,
                        height: 20
                      }}
                />
              )
            })
          }
        </Grid>
      </components.Option>
    );
  }
}

export {DataViewerCheckBox, DataViewerGraphType, getTspanGroups, ColorThemeOptions}