import React from 'react'
import KeyAuthentication from "../key_authentication"
import EditableBMHS from "./editable_bmhs"
import Disposition from "./disposition"
import ArchiveModal from "../archive_modal";
import CallLog from "./call_log"
import PlumCouleeCallLog from "./plum_coulee_call_log"
import OfficerTracking from "./officer_tracking"
import CircularProgress from "@material-ui/core/CircularProgress";
import CloseMatchModal from "./close_match_modal"

class RecordEditor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      report_object: JSON.parse(JSON.stringify(this.props.edit_report)),
      key_data: JSON.parse(sessionStorage.getItem('organizationKey')),
      original_report_object: this.props.edit_report,
      protected_fields: {},
      original_protected_fields: {},
      show_archive_modal: false,
      show_close_match_modal: false,
      close_match_spinner: false,
      close_match_list: [],
      close_match_explainer_text: "Search Results",
      hospital_data_object: this.props.hospital_data_object,
      responder_data_object: this.props.responders,
      report_time_fields: {datetime: {}},
      save_spinner_visible: false,
      error_messages: {},
      reassign: false
    };

    this.empty_hospital = {
      name: "",
      id: "",
      order: 0,
      arriveAtED: null,
      departFromED: null,
      beforeTravel: null
    };

    this.empty_responder = {
      badgeNumber: "",
      responderStartTime: null,
      responderEndTime: null,
      responderOvertimeStart: null,
      responderDoubleTimeStart: null,
      username: null,
      responderName: null,
      detachment: null,
      division: null,
      officerType: null,
      responderFirstName: null,
      responderLastName: null,
      rank: null
    };

    this.dateValidationDependancy = {
      "date": [],
      "arrivalTimestamp": [],
      "reportEnd": [
        {
          "name": "arrivalTimestamp",
          "type": "none",
          "object": "report_time_fields"
        }
      ],
      "beforeTravel": [
        {
          "name": "departFromED",
          "type": "previous",
          "object": "hospital_data_object"
        }
      ],
      "arriveAtED": [
        {
          "name": "arrivalTimestamp",
          "type": "none",
          "object": "report_time_fields"
        },
        {
          "name": "beforeTravel",
          "type": "close",
          "object": "hospital_data_object"

        }
      ],
      "departFromED": [
        {
          "name": "arriveAtED",
          "type": "close",
          "object": "hospital_data_object"
        }
      ],
      "endCallTime": [
        {
          "name": "arrivalTimestamp",
          "type": "none",
          "object": "report_time_fields"
        },
        {
          "name": "departFromED",
          "type": "all",
          "object": "hospital_data_object"
        },
        {
          "name": "responderEndTime",
          "type": "all",
          "object": "responder_data_object"
        }
      ],
      "responderStartTime": [
        {
          "name": "arrivalTimestamp",
          "type": "none",
          "object": "report_time_fields"
        }
      ],
      "responderOvertimeStart": [],
      "responderDoubleTimeStart": [],
      "responderEndTime": [{
        "name": "responderStartTime",
        "type": "close",
        "object": "responder_data_object"
      }],
    };

    if (!this.props.is_plum_coulee) {
      this.dateValidationDependancy["responderOvertimeStart"] = [
        {
          "name": "responderStartTime",
          "type": "close",
          "object": "responder_data_object"
        }
      ];

      this.dateValidationDependancy["responderDoubleTimeStart"] = [
        {
          "name": "responderStartTime",
          "type": "close",
          "object": "responder_data_object"
        },
        {
          "name": "responderOvertimeStart",
          "type": "close",
          "object": "responder_data_object"
        }
      ];

      this.dateValidationDependancy["responderEndTime"] = [
        {
          "name": "responderOvertimeStart|responderDoubleTimeStart",
          "type": "or",
          "object": "responder_data_object"
        }
      ]
    }

    this.report_datetime = ["date", "arrivalTimestamp", "endCallTime", "reportEnd"];
    this.hospital_datetime = ["arriveAtED", "departFromED", "beforeTravel"];
    this.responder_datetime = ["responderStartTime", "responderEndTime"];
    if (!this.props.is_plum_coulee) {
      this.responder_datetime = this.responder_datetime.concat(["responderOvertimeStart", "responderDoubleTimeStart"])
    }
    this.birth_keys = ["birthYear", "birthMonth", "birthDay"]

    for (var dt in this.report_datetime) {
      var field_name = this.report_datetime[dt];
      var r_t = {};
      r_t["date"] = this.state.report_object[field_name] && this.state.report_object[field_name].length > 0 ? formatDate(this.state.report_object[field_name]) : '';
      r_t["time"] = this.state.report_object[field_name] && this.state.report_object[field_name].length > 0 ? formatTime(this.state.report_object[field_name]) : '';
      this.state.report_time_fields["datetime"][field_name] = r_t;
    }

    this.handleShowArchiveModal = this.handleShowArchiveModal.bind(this);
    this.handleReassign = this.handleReassign.bind(this);
    this.handleHideArchiveModal = this.handleHideArchiveModal.bind(this);
    this.handleShowCloseMatchModal = this.handleShowCloseMatchModal.bind(this);
    this.handleHideCloseMatchModal = this.handleHideCloseMatchModal.bind(this);
    this.handleClickCloseMatch = this.handleClickCloseMatch.bind(this);
    this.handleProtectedFieldChange = this.handleProtectedFieldChange.bind(this);
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.reencryptFields = this.reencryptFields.bind(this);
    this.clearHospitalsAfter = this.clearHospitalsAfter.bind(this);
    this.addHospital = this.addHospital.bind(this);
    this.removeResponder = this.removeResponder.bind(this);
    this.addResponder = this.addResponder.bind(this);
    this.validateFields = this.validateFields.bind(this);
    this.validateDateTime = this.validateDateTime.bind(this);
    this.checkTimeDependencies = this.checkTimeDependencies.bind(this);
    this.compareTime = this.compareTime.bind(this);
    this.changeErrorMessages = this.changeErrorMessages.bind(this);
    this.updateWaitTimeDetails = this.updateWaitTimeDetails.bind(this);
    this.checkDateOfBirth = this.checkDateOfBirth.bind(this);
  }

  clearHospitalsAfter(index) {
    this.setState(prevState => ({
      hospital_data_object: prevState.hospital_data_object.slice(0, index + 1)
    }));
  }

  addHospital() {
    this.setState(prevState => ({
      hospital_data_object: prevState.hospital_data_object.concat([JSON.parse(JSON.stringify(this.empty_hospital))])
    }));
  }

  addResponder() {
    this.setState(prevState => ({
      responder_data_object: prevState.responder_data_object.concat([JSON.parse(JSON.stringify(this.empty_responder))])
    }));
  }

  removeResponder(index) {
    this.setState(prevState => ({
      responder_data_object: prevState.responder_data_object.slice(0, index).concat(prevState.responder_data_object.slice(index + 1))
    }));
  }

  handleProtectedFieldChange(e) {
    this.state.protected_fields[e.target.id] = e.target.value.toUpperCase();
    this.state.protected_fields['address'] = $.trim(
      (this.state.protected_fields.streetNumber
        + ' ' + this.state.protected_fields.streetName
        + ' ' + this.state.protected_fields.apartment
        + ' ' + this.state.protected_fields.city
        + ' ' + this.state.protected_fields.province
        + ' ' + this.state.protected_fields.country).replace(/undefined/g, '')).toUpperCase();
    this.forceUpdate();
  }

  handleFieldChange(e) {
    if (this.state.report_object["missing"] && this.state.report_object["missing"][e.target.id]) {
      if (this.birth_keys.indexOf(e.target.id) > -1) {
        for (var index in this.birth_keys) {
          var field_name = this.birth_keys[index]
          this.state.report_object["missing"][field_name] = false;
        }
      } else {
        this.state.report_object["missing"][e.target.id] = false;
      }
    }
    this.state.report_object[e.target.id] = e.target.value.toUpperCase();
    this.forceUpdate();
  }

  handleSelectChange(selected, id) {
    this.state.report_object[id] = selected.value;
    this.forceUpdate();
  }

  handleShowArchiveModal() {
    this.setState({show_archive_modal: true});
  }

  handleReassign() {
    this.setState({reassign: true}, function(){
      this.reencryptFields();
    });
  }

  handleHideArchiveModal() {
    this.setState({show_archive_modal: false});
  }

  handleShowCloseMatchModal() {
    this.setState({show_close_match_modal: true});
  }

  handleHideCloseMatchModal() {
    this.setState({close_match_spinner: false});
    this.setState({show_close_match_modal: false});
    this.setState({save_spinner_visible: false});
    this.setState({close_match_list: []});
    this.setState({close_match_explainer_text: "Search Results"});
  }

  handleClickCloseMatch(caseID) {
    this.setState({close_match_spinner: true});
    var reportCopy = JSON.parse(JSON.stringify(this.state.report_object));
    updateCaseInformation(reportCopy, caseID).done(function (data) {
      if (data && data['case_id']) {
        // New case assigned, make sure it is referenced in the patch
        this.state.report_object["caseID"] = data['case_id'];
      }
      $.ajax({
        url: '/reports/' + this.state.report_object['id'].toString() + ".json",
        method: 'PATCH',
        data: {
          report: this.state.report_object
        }
      }).done(function () {
        this.updateWaitTimeDetails(function () {
          window.location = '/record_editor/report/' + this.state.report_object['id'];
        }.bind(this));
      }.bind(this))
        .fail(function () {
          this.setState({save_spinner_visible: false});
          this.setState({close_match_spinner: false});
        }.bind(this));
    }.bind(this))
      .fail(function () {
        this.setState({save_spinner_visible: false});
        this.setState({close_match_spinner: false});
      }.bind(this));
  }

  reencryptFields() {
    this.setState({save_spinner_visible: true});
    this.setState({error_messages: {}});
    const hospital_object = this.state.hospital_data_object.filter(obj => obj.id > 0);
    //Validate Report Datetime
    for (var index in Object.keys(this.state.report_time_fields["datetime"])) {
      var key = Object.keys(this.state.report_time_fields["datetime"])[index];
      this.validateFields(this.state.report_time_fields, key, true, null);
    }

    //Validate Hospital Date time
    for (var hospital in hospital_object) {
      for (var index in Object.keys(hospital_object[hospital]["datetime"])) {
        var key = Object.keys(hospital_object[hospital]["datetime"])[index];
        this.validateFields(hospital_object[hospital], key, true, hospital);
      }
    }

    //Auto input end time and responder validation
    for (var responder in this.state.responder_data_object) {
      for (var index in Object.keys(this.state.responder_data_object[responder]["datetime"])) {
        var key = Object.keys(this.state.responder_data_object[responder]["datetime"])[index];
        this.validateFields(this.state.responder_data_object[responder], key, true, responder);
      }
      if (this.state.responder_data_object[responder]["datetime"]["responderEndTime"]["date"].length == 0
        && this.state.responder_data_object[responder]["datetime"]["responderEndTime"]["time"].length == 0) {
        this.state.responder_data_object[responder]["datetime"]["responderEndTime"]["date"] = this.state.report_time_fields["datetime"]["endCallTime"]["date"];
        this.state.responder_data_object[responder]["datetime"]["responderEndTime"]["time"] = this.state.report_time_fields["datetime"]["endCallTime"]["time"];
      }
      this.validateFields(this.state.responder_data_object[responder], "badgeNumber", false, null);
    }

    //Validate Incident Number
    // this.validateFields(this.state.report_object, "incidentNumber", false, null);
    this.checkDateOfBirth()

    if (Object.keys(this.state.error_messages).length > 0) {
      var alertMessage = "Please fix the following entry errors:\n";
      $.each(this.state.error_messages, function (key, value) {
        for (var i = 0; i < value.length; i++) {
          alertMessage += "\u2022" + key + " " + value[i] + "\n";
        }
      });
      alert(alertMessage);
      this.setState({save_spinner_visible: false});
      return;
    }

    //START RE-ENCRYPTION
    var key = forge.random.getBytesSync(32);
    var iv = forge.random.getBytesSync(16);

    // encrypt some bytes
    var cipher = forge.cipher.createCipher('AES-CBC', key);
    cipher.start({iv: iv});
    cipher.update(forge.util.createBuffer(JSON.stringify(this.state.protected_fields)));
    cipher.finish();
    var encrypted = cipher.output;
    // outputs encrypted hex
    var encryptedData = btoa(encrypted.getBytes());
    var organizationsToEncrypt = [{
      "id": this.props.analytics_organization_id,
      "publicKey": this.state.key_data.organizationPublicKey
    }];

    // Update crisis notified section D action if new referral made
    if (this.state.report_object["cmhResponses"]) {
      this.state.report_object["sectionDActions"][3] = 0;
      for (var consentIndex in this.state.report_object["cmhResponses"]) {
        var consentInfo = this.state.report_object["cmhResponses"][consentIndex];

        if (consentInfo["offered"] == 1) {
          this.state.report_object["sectionDActions"][3] = 1;
          break;
        }
      }
    }

    if (this.state.report_object["destinations"]) {
      for (var idIndex in this.state.report_object["destinations"]) {
        var destinationID = this.state.report_object["destinations"][idIndex];
        var cmhFound = false;

        for (var cmhIndex in this.props.cmh_destination_details) {
          var cmhData = this.props.cmh_destination_details[cmhIndex];

          if (cmhData["id"] == destinationID && cmhData["public_key"] && cmhData["public_key"].length > 0) {
            organizationsToEncrypt.push({"id": destinationID, "publicKey": cmhData["public_key"]});
            cmhFound = true;
            break;
          }
        }
        if (!cmhFound) {
          organizationsToEncrypt.push({"id": destinationID});
          if (this.props.org_list) {
            var org_details = this.props.org_list.find(elem => elem.destination_organization && elem.destination_organization.id.toString() === destinationID.toString())
            if (org_details && org_details.destination_organization && org_details.destination_organization.linked_organization_list) {
              var linked_orgs = org_details.destination_organization.linked_organization_list.split(',');
              for (var linked in linked_orgs) {
                if (this.state.report_object["destinations"].find(elem => elem.toString() === linked.toString()) === undefined &&
                  organizationsToEncrypt.find(elem => elem.id.toString() === linked.toString()) === undefined
                ) {
                  organizationsToEncrypt.push({"id": parseInt(linked)});
                }
              }
            }
          }
        }
      }
    }

    this.state.report_object['encryptedOrganizationDictionary'] = {};
    var keyComponents = btoa(key) + '|' + btoa(iv);

    for (var encryptDataIndex in organizationsToEncrypt) {
      var encryptionDetails = organizationsToEncrypt[encryptDataIndex];
      var encryptedDataDictionary = {};

      if (encryptionDetails["publicKey"]) {
        var publicKey = forge.pki.publicKeyFromPem(encryptionDetails["publicKey"]);
        var encryptedKeyComponents = publicKey.encrypt(keyComponents, 'RSA-OAEP');

        encryptedDataDictionary['ciphertext'] = encryptedData;
        encryptedDataDictionary['key'] = btoa(encryptedKeyComponents);
      } else {
        encryptedDataDictionary['ciphertext'] = "";
        encryptedDataDictionary['key'] = "";
        encryptedDataDictionary['addedByRecordEditor'] = true;
      }

      this.state.report_object['encryptedOrganizationDictionary'][encryptionDetails["id"]] = encryptedDataDictionary;
    }

    this.state.report_object['encryptedData'] = this.state.report_object['encryptedOrganizationDictionary'][this.props.analytics_organization_id];
    this.state.report_object['nameIdentifiers'] = nameIdentifiersList(this.state.protected_fields, this.state.key_data.organizationSecretString);

    //Report timestamps
    for (index in Object.keys(this.state.report_time_fields["datetime"])) {
      var key = Object.keys(this.state.report_time_fields["datetime"])[index];
      var value = this.state.report_time_fields["datetime"][key];
      var date = new Date(value["date"].replace(/-/g, '/') + ' ' + value["time"]);
      if (key === 'arrivalTimestamp') {
        this.state.report_object['arrivalDate'] = value["date"];
        this.state.report_object['arrivalTime'] = value["time"];
        this.state.report_object['arrivalTimestamp'] = date;
        this.state.report_object['arrivalYear'] = date.getFullYear().toString();
        this.state.report_object['arrivalMonth'] = ("0" + (date.getMonth() + 1)).slice(-2);
        this.state.report_object['arrivalDay'] = ("0" + date.getDate()).slice(-2);
      } else {
        this.state.report_object[key] = date;
      }
    }

    //Hospital timestamps
    for (var hospital in hospital_object) {
      for (var index in Object.keys(hospital_object[hospital]["datetime"])) {
        var key = Object.keys(hospital_object[hospital]["datetime"])[index];
        var value = hospital_object[hospital]["datetime"][key];
        var date = new Date(value["date"].replace(/-/g, '/') + ' ' + value["time"]);
        if (!(hospital == 0 && key === "beforeTravel")) {
          hospital_object[hospital][key] = date;
        }
      }
      if (hospital == hospital_object.length - 1) {
        this.state.report_object["arriveAtED"] = hospital_object[hospital]["arriveAtED"];
        this.state.report_object["departFromED"] = hospital_object[hospital]["departFromED"];
      }
    }

    //Responder timestamps
    var badgeNumbers = [];
    for (var index in this.state.responder_data_object) {
      badgeNumbers.push(this.state.responder_data_object[index]["badgeNumber"]);
    }
    for (var originalResponderIndex in this.state.original_report_object["respondingOfficers"]) {
      var originalResponder = this.state.original_report_object["respondingOfficers"][originalResponderIndex];

      if (badgeNumbers.indexOf(originalResponder["badgeNumber"]) < 0) {
        $.ajax({
          url: '/responder_time',
          method: 'DELETE',
          data: {badgeNumber: originalResponder["badgeNumber"], report_id: this.state.original_report_object["id"]}
        });
      }
    }

    for (var responder in this.state.responder_data_object) {
      for (var index in Object.keys(this.state.responder_data_object[responder]["datetime"])) {
        var key = Object.keys(this.state.responder_data_object[responder]["datetime"])[index];
        var value = this.state.responder_data_object[responder]["datetime"][key];
        var date = new Date(value["date"].replace(/-/g, '/') + ' ' + value["time"]);
        this.state.responder_data_object[responder][key] = date;
      }
      this.state.responder_data_object[responder]['id'] = this.state.original_report_object['id'];
      $.ajax({
        url: '/new_responder_time',
        method: 'PATCH',
        data: this.state.responder_data_object[responder]
      });
    }

    if (this.state.reassign || this.state.original_report_object["birthYear"] != this.state.report_object["birthYear"] ||
      this.state.original_report_object["birthMonth"] != this.state.report_object["birthMonth"] ||
      this.state.original_report_object["birthDay"] != this.state.report_object["birthDay"] ||
      this.state.original_protected_fields["firstName"] != this.state.protected_fields["firstName"] ||
      this.state.original_protected_fields["lastName"] != this.state.protected_fields["lastName"]) {
      var reassigning = this.state.reassign;
      this.setState({reassign: false});
      
      pastReportLookup({
        firstName: this.state.protected_fields['firstName'],
        lastName: this.state.protected_fields['lastName'],
        birthYear: this.state.report_object['birthYear'],
        birthMonth: this.state.report_object['birthMonth'],
        birthDay: this.state.report_object['birthDay']
      }, this.state.key_data.organizationSecretString)
        .done(function (data) {
          if (data['closeMatch'] && data['lastReportList'] && data['lastReportList'].length > 0) {
            var list = []
            for(var i = 0; i < data['lastReportList'].length; i++){
              var report = data['lastReportList'][i];
              if(report["caseID"].toString() !== this.state.report_object['caseID'].toString()){
                list.push(report)
              }
            }
            this.setState({close_match_list: list});
          } else if (data["caseID"] && data["caseID"].toString() !== this.state.report_object['caseID'].toString()) {
            this.setState({close_match_list: [data]});
          }

          if (reassigning) {
            this.setState({close_match_explainer_text: "Case reassignment: Please select the new profile this report should be associated with or designate it as a new person."});
          }
          else if (this.state.close_match_list.length > 0) {
            this.setState({close_match_explainer_text: "You have modified key identifiers. Please select the correct profile this report should be associated with or designate it as a new person."});
          } else {
            this.setState({close_match_explainer_text: "You have modified key identifiers. No similar profiles could be found. Please confirm that this is a new person."});
          }
          this.setState({show_close_match_modal: true});
        }.bind(this))
        .fail(function () {
          this.setState({save_spinner_visible: false});
        }.bind(this));
    } else {
      $.ajax({
        url: '/reports/' + this.state.report_object['id'].toString(),
        method: 'PATCH',
        data: {
          report: this.state.report_object
        }
      }).done(function () {
        this.updateWaitTimeDetails(function () {
          window.location = '/record_editor/report/' + this.state.report_object['id'];
        }.bind(this));
      }.bind(this))
        .fail(function () {
          this.setState({save_spinner_visible: false});
        }.bind(this));
    }
  }

  checkDateOfBirth() {
    var fieldObj = this.state.report_object
    if (fieldObj["missing"] == undefined) {
      fieldObj["missing"] = {};
      this.forceUpdate();
    }
    if (fieldObj.birthYear && fieldObj.birthMonth && fieldObj.birthDay) {
      var dateString = fieldObj.birthYear + '-' + fieldObj.birthMonth + '-' + fieldObj.birthDay;
      var processedDate = new Date(dateString);
      if (processedDate.getUTCFullYear() != Number(fieldObj.birthYear) ||
        (processedDate.getUTCMonth() + 1) != Number(fieldObj.birthMonth) ||
        processedDate.getUTCDate() != Number(fieldObj.birthDay)) {

        for (var index in this.birth_keys) {
          var field_name = this.birth_keys[index]
          fieldObj["missing"][field_name] = true;
        }
        this.changeErrorMessages("Date of Birth", "birth")
      } else {
        for (var index in this.birth_keys) {
          var field_name = this.birth_keys[index]
          fieldObj["missing"][field_name] = false;
        }
      }

    } else {
      for (var index in this.birth_keys) {
        var field_name = this.birth_keys[index]
        fieldObj["missing"][field_name] = false;
      }
    }


  }

  validateFields(fieldObj, fieldID, datetime, index) {
    var field = fieldObj[fieldID];
    if (fieldObj["missing"] == undefined) {
      fieldObj["missing"] = {};
      this.forceUpdate();
    }

    if (this.props.field_formats[fieldID] === undefined) {
      if (datetime) {
        this.validateDateTime(fieldObj, fieldID, index);
      } else {
        if (field === null || field.length === 0) {
          fieldObj["missing"][fieldID] = true;
          var text = fieldID;
          var result = text.replace(/([A-Z])/g, " $1");
          var finalResult = result.charAt(0).toUpperCase() + result.slice(1);
          this.changeErrorMessages(finalResult, "empty");
        } else {
          fieldObj["missing"][fieldID] = false;
        }
      }

    } else {
      var expression = new RegExp(parseHashtagString(this.props.field_formats[fieldID]));
      if (datetime) {
        this.validateDateTime(fieldObj, fieldID, index)
      } else {
        if (field.match(expression) === null) {
          fieldObj["missing"][fieldID] = true;
          var text = fieldID;
          var result = text.replace(/([A-Z])/g, " $1");
          var finalResult = result.charAt(0).toUpperCase() + result.slice(1);
          this.changeErrorMessages(finalResult, "invalid")
        } else {
          fieldObj["missing"][fieldID] = false;
        }
      }

    }
    this.forceUpdate();
  }

  validateDateTime(fieldObj, fieldID, index) {
    var dateField = fieldObj["datetime"][fieldID]["date"];
    var timeField = fieldObj["datetime"][fieldID]["time"];
    if (fieldObj["missing"][fieldID] == undefined) {
      fieldObj["missing"][fieldID] = {};
      this.forceUpdate();
    }
    if (dateField.length == 0 && timeField.length == 0) {
      fieldObj["missing"][fieldID]["date"] = false;
      fieldObj["missing"][fieldID]["time"] = false;

    } else if (dateField.length > 0 && timeField.length > 0) {
      var arrival = new Date(dateField.replace(/-/g, '/') + " " + timeField);
      var now = new Date();

      if (arrival > now) {
        fieldObj["missing"][fieldID]["date"] = true;
        fieldObj["missing"][fieldID]["time"] = true;
        this.changeErrorMessages(fieldObj["datetime"][fieldID]["title"], "future");
      } else {
        this.checkTimeDependencies(fieldObj, fieldID, index)
      }
    } else if (dateField.length == 0) {
      fieldObj["missing"][fieldID]["date"] = true;
      this.changeErrorMessages(fieldObj["datetime"][fieldID]["title"], "empty");
    } else if (timeField.length == 0) {
      fieldObj["missing"][fieldID]["time"] = true;
      this.changeErrorMessages(fieldObj["datetime"][fieldID]["title"], "empty");
    }
  }

  checkTimeDependencies(fieldObj, fieldID, index) {
    var dependsOn = this.dateValidationDependancy[fieldID];
    var dateField = fieldObj["datetime"][fieldID]["date"];
    var timeField = fieldObj["datetime"][fieldID]["time"];
    var missing = false;
    var before = [];

    for (var i = 0; i < dependsOn.length; i++) {
      var depends = dependsOn[i];
      if (depends["type"] === "none") {
        if (this.state[depends["object"]]["datetime"] && this.state[depends["object"]]["datetime"][depends["name"]]) {
          var compareObj = this.state[depends["object"]]["datetime"][depends["name"]];
          var pastDate = compareObj["date"];
          var pastTime = compareObj["time"];

          missing = this.compareTime(dateField, timeField, pastDate, pastTime);
          if (missing) before.push(compareObj["title"]);
        }
      } else if (depends["type"] === "close") {
        if (fieldObj["datetime"] && fieldObj["datetime"][depends["name"]]) {
          var compareObj = fieldObj["datetime"][depends["name"]];
          var pastDate = compareObj["date"];
          var pastTime = compareObj["time"];

          missing = this.compareTime(dateField, timeField, pastDate, pastTime);
          if (missing) before.push(compareObj["title"]);
        }
      } else if (depends["type"] === "or") {
        var orNames = depends["name"].split("|");
        for (var x = 0; x < orNames.length; x++) {
          if (fieldObj["datetime"] && fieldObj["datetime"][orNames[x]]) {
            var compareObj = fieldObj["datetime"][orNames[x]];
            var pastDate = compareObj["date"];
            var pastTime = compareObj["time"];

            missing = this.compareTime(dateField, timeField, pastDate, pastTime);
            if (missing) before.push(compareObj["title"]);
          }
        }
      } else if (depends["type"] === "previous") {
        if (index > 0 && this.state[depends["object"]][index - 1]["datetime"] && this.state[depends["object"]][index - 1]["datetime"][depends["name"]]) {
          var compareObj = this.state[depends["object"]][index - 1]["datetime"][depends["name"]];
          var pastDate = compareObj["date"];
          var pastTime = compareObj["time"];

          missing = this.compareTime(dateField, timeField, pastDate, pastTime);
          if (missing) before.push(compareObj["title"]);
        }
      } else if (depends["type"] === "all") {
        var allObj = this.state[depends["object"]];
        for (var x = 0; x < allObj.length; x++) {
          if (allObj[x]["datetime"] && allObj[x]["datetime"][depends["name"]]) {
            var compareObj = allObj[x]["datetime"][depends["name"]];
            var pastDate = compareObj["date"];
            var pastTime = compareObj["time"];

            missing = this.compareTime(dateField, timeField, pastDate, pastTime);
            if (missing) before.push(compareObj["title"]);
          }
        }
      }
    }

    if (before.length > 0) {
      before = before.filter(function (v, p) {
        return before.indexOf(v) == p;
      });
      fieldObj["missing"][fieldID]["date"] = true;
      fieldObj["missing"][fieldID]["time"] = true;

      if (fieldObj["datetime"][fieldID]["title"] != undefined) {
        for (i in before) {
          this.changeErrorMessages(fieldObj["datetime"][fieldID]["title"], before[i])
        }
      }
    } else {
      fieldObj["missing"][fieldID]["date"] = false;
      fieldObj["missing"][fieldID]["time"] = false;
    }
  }

  compareTime(curDateField, curTimeField, pastDateField, pastTimeField) {
    if (curDateField.length > 0 && curTimeField.length > 0 && pastDateField.length > 0 && pastTimeField.length > 0) {
      var past = new Date(pastDateField.replace(/-/g, '/') + " " + pastTimeField);
      var current = new Date(curDateField.replace(/-/g, '/') + " " + curTimeField);

      if (past > current) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  changeErrorMessages(key, type) {
    if (this.state.error_messages[key] == undefined) {
      this.state.error_messages[key] = [];
      this.forceUpdate();
    }

    if (type === "empty") {
      this.state.error_messages[key].push("cannot be blank");
    } else if (type === "invalid") {
      this.state.error_messages[key].push("does not match pattern");
    } else if (type === "future") {
      this.state.error_messages[key].push("cannot be in the future");
    } else if (type === "birth") {
      this.state.error_messages[key].push("must be a valid calendar date");
    } else {
      this.state.error_messages[key].push("cannot be before " + type);
    }
    this.forceUpdate();
  }

  updateWaitTimeDetails(successCallback) {
    const hospital_object = this.state.hospital_data_object.filter(obj => obj.id > 0);
    $.ajax({
      url: '/record_editor/report/' + this.state.report_object['id'].toString() + '/wait_times',
      method: 'PATCH',
      data: {
        hospital_data: hospital_object
      }
    }).done(function () {
      successCallback();
    }.bind(this))
      .fail(function () {
      });
  }

  componentDidMount() {
    var orgIncorrectKey = sessionStorage.getItem('incorrectKey');
    var is_admin = this.props.is_admin;

    if (!(orgIncorrectKey === 'false' || is_admin)) {
      window.location = window.location.pathname.replace("/edit", "");
    }

    try {
      var privateKey = forge.pki.privateKeyFromPem(this.state.key_data.organizationPrivateKey);
      var decrypted = decryptReportData(this.state.report_object, privateKey);
      this.setState({protected_fields: JSON.parse(decrypted)});
      this.setState({original_protected_fields: JSON.parse(decrypted)});
      console.log(JSON.parse(decrypted))
    } catch (e) {
      console.log("Error");
      console.log(e);
    }
    this.forceUpdate();
  }

  render() {
    return (
      <div className="main-content">
        <div id="edit-reports-container">
          <KeyAuthentication
            key_image_path={this.props.key_image_path}
            encryption_key={this.props.encryption_key}
            refresh={true}
          />

          <EditableBMHS
            forward_report={this.props.forward_report}
            report_object={this.state.report_object}
            original_report_object={this.state.original_report_object}
            protected_fields={this.state.protected_fields}
            original_protected_fields={this.state.original_protected_fields}
            incident_number_ex={this.props.incident_number_ex}
            handleProtectedFieldChange={this.handleProtectedFieldChange}
            handleFieldChange={this.handleFieldChange}
          />

          {
            this.props.forward_report &&
            (
              <Disposition
                report_object={this.state.report_object}
                original_report_object={this.state.original_report_object}
                configuration={this.props.configuration}
                is_plum_coulee={this.props.is_plum_coulee}
                protected_fields={this.state.protected_fields}
                handleProtectedFieldChange={this.handleProtectedFieldChange}
                handleSelectChange={this.handleSelectChange}
              />
            )
          }
          {
            this.props.disposition_options && this.state.report_object["selectedDispositionKey"] ?
              (
                <PlumCouleeCallLog
                  report_object={this.state.report_object}
                  handleSelectChange={this.handleSelectChange}
                  disposition_options={this.props.disposition_options}
                  possible_destinations={this.props.possible_destinations}
                  hospital_data_object={this.state.hospital_data_object}
                  analytics_organization_name={this.props.analytics_organization_name}
                  transfer_available={this.props.transfer_available}
                  addHospital={this.addHospital}
                  clearHospitalsAfter={this.clearHospitalsAfter}
                  report_time_fields={this.state.report_time_fields}
                  hospital_datetime={this.hospital_datetime}
                />
              ) :
              (
                <CallLog
                  report_object={this.state.report_object}
                  handleSelectChange={this.handleSelectChange}
                  disposition={this.props.disposition}
                  apprehension_types={this.props.apprehension_types}
                  possible_destinations={this.props.possible_destinations}
                  hospital_data_object={this.state.hospital_data_object}
                  analytics_organization_name={this.props.analytics_organization_name}
                  transfer_available={this.props.transfer_available}
                  addHospital={this.addHospital}
                  clearHospitalsAfter={this.clearHospitalsAfter}
                  report_time_fields={this.state.report_time_fields}
                  hospital_datetime={this.hospital_datetime}
                  is_plum_coulee={this.props.is_plum_coulee}
                />
              )
          }

          <OfficerTracking
            report_object={this.state.report_object}
            responder_data_object={this.state.responder_data_object}
            responder_datetime={this.responder_datetime}
            addResponder={this.addResponder}
            removeResponder={this.removeResponder}
            is_plum_coulee={this.props.is_plum_coulee}
          />
        </div>

        <CloseMatchModal
          close_match_spinner={this.state.close_match_spinner}
          show_close_match_modal={this.state.show_close_match_modal}
          close_match_explainer_text={this.state.close_match_explainer_text}
          close_match_list={this.state.close_match_list}
          handleHideCloseMatchModal={this.handleHideCloseMatchModal}
          handleClickCloseMatch={this.handleClickCloseMatch}
          key_data={this.state.key_data}
        >
        </CloseMatchModal>

        <div id="page-number-container" className="re-footer">
          <a>
            <div id="re-cancel-button" onClick={() => {
              window.history.back()
            }}>CANCEL
            </div>
          </a>
          <div id="re-save-button" onClick={this.reencryptFields}>
            <div className={`spinner-container`}>
              <CircularProgress
                size={20}
                thickness={5}
                color="inherit"
                className={this.state.save_spinner_visible ? '' : 'hidden'}
                classes={{
                  root: 'editor-spinner-root',
                  svg: 'view_report_spinner'
                }}
              />
            </div>
            <div className='save-button-text'>SAVE</div>
          </div>
          <div id="re-archive-button" onClick={this.handleShowArchiveModal}>ARCHIVE RECORD</div>
          <div id="re-reassign-button" onClick={this.handleReassign}>REASSIGN</div>
        </div>

        <ArchiveModal
          show_archive_modal={this.state.show_archive_modal}
          handleHideArchiveModal={this.handleHideArchiveModal}
          report_id={this.props.report_id}
        >
        </ArchiveModal>
      </div>
    )
  }
}

export default RecordEditor