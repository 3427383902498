import React from 'react'

class EditableBMHS extends React.Component {
  constructor(props) {
    super(props);

    this.copyHomeAddress = this.copyHomeAddress.bind(this);
    this.handlePhoneKeyUp = this.handlePhoneKeyUp.bind(this);
    this.handleBirthDayBlur = this.handleBirthDayBlur.bind(this);
    this.handleBirthDayInput = this.handleBirthDayInput.bind(this);
  }

  handleBirthDayInput(e) {
    var field = e.target;
    var fieldID = e.target.id;
    var value = field.value;

    if (fieldID === 'birthYear') {
      if (value.match(/^\D+$|[0-9]+\D+/)) {
        e.target.value = '';
        this.props.handleFieldChange(e);
      }
    }

    if (fieldID === 'birthMonth') {
      if (value.match(/(^\D+$|[2-9][1-9]|1[3-9]|00|[0-9]\D+$)/)) {
        e.target.value = '';
        this.props.handleFieldChange(e);
      }
    }

    if (fieldID === 'birthDay') {
      if (value.match(/(^\D+$|[4-9][1-9]|3[2-9]|00|[0-9]\D+$)/)) {
        e.target.value = '';
        this.props.handleFieldChange(e);
      }
    }
  };

  handleBirthDayBlur(e) {
    var field = e.target;
    var fieldID = e.target.id;
    var value = field.value;
    if (fieldID === 'birthYear') {
      if (value.length === 1 || value.length === 2 || value.length === 3) {
        e.target.value = '';
        this.props.handleFieldChange(e);
      }

      if (parseInt(value) < 1800 || parseInt(value) > (new Date()).getFullYear()) {
        e.target.value = '';
        this.props.handleFieldChange(e);
      }
    }

    if (fieldID === 'birthMonth') {
      if (value.length === 1 && value !== '0') {
        var value = value;
        var newvalue = '0' + value;
        e.target.value = newvalue;
        this.props.handleFieldChange(e);
      }

      if (value === '0') {
        e.target.value = '';
        this.props.handleFieldChange(e);
      }

      if (parseInt(this.props.report_object['birthYear']) === (new Date()).getFullYear()) {
        if (parseInt(value) > (new Date()).getMonth() + 1) {
          e.target.value = '';
          this.props.handleFieldChange(e);
        }
      }
    }

    if (fieldID === 'birthDay') {
      if (value.length === 1 && value !== '0') {
        var value = value;
        var newvalue = '0' + value;
        e.target.value = newvalue;
        this.props.handleFieldChange(e);
      }

      if (value === '0') {
        e.target.value = '';
        this.props.handleFieldChange(e);
      }

      if (parseInt(this.props.report_object['birthYear']) === (new Date()).getFullYear() && parseInt(this.props.report_object['birthMonth']) === (new Date()).getMonth() + 1) {
        if (parseInt(value) > (new Date()).getDate()) {
          e.target.value = '';
          this.props.handleFieldChange(e);
        }
      }
    }
  }

  handlePhoneKeyUp(e) {
    if (e.keyCode === 8 || e.keyCode === 46) return;
    var field = e.target;
    var value = field.value;
    if (value.substr(-1).match(/[0-9]/) == null || value.length > 14) {
      e.target.value = value.substr(0, value.length - 1);
      this.props.handleProtectedFieldChange(e);
      return;
    }

    if (value.match(/^([0-9]{3})$/)) {
      value = '(' + value + ') ';
      e.target.value = value;
    } else if (value.match(/^\([0-9]{3}$/)) {
      value = value + ') ';
      e.target.value = value;
    } else if (value.match(/^\([0-9]{4}$/)) {
      value = value.substr(0, value.length - 1) + ') ' + value.substr(-1);
      e.target.value = value;
    } else if (value.match(/^\([0-9]{3}\)\s([0-9]{4})$/)) {
      value = value.substr(0, value.length - 1) + '-' + value.substr(-1);
      e.target.value = value;
    } else if (value.match(/^\(([0-9]{3})\)\s([0-9]{3})$/)) {
      value = value + '-';
      e.target.value = value;
    }
    this.props.handleProtectedFieldChange(e)
  }

  copyHomeAddress() {
    this.props.protected_fields["incidentLocation"] = this.props.protected_fields["address"];
    this.forceUpdate();
  }

  render() {
    return (
      <div id="section-a-container" className="container-report">
        <div className="title-container">
          <h1 className="title-container-head">EDITABLE BMHS DATA</h1>
        </div>
        {
          this.props.forward_report && (
            <div id="name-container" className="content-area-container">
              <div>
                <p id="name-container-title" className="container-sub-head">Name</p>
                <p id="suffix-container-title" className="container-sub-head">Suffix</p>
              </div>
              <div id="name-label-container">
                <div id="first-name-label" className="input-label">First</div>
                <div id="middle-name-label" className="input-label">Middle</div>
                <div id="last-name-label" className="input-label">Last</div>
              </div>
              <div>
                <div id="name-input-container">
                  <div className="field-container">
                    <BMHSInputField
                      field_id="firstName"
                      default_class="input-report left"
                      field_placeholder="first name"
                      field_maxlength={524288} /*This is the normal default value*/
                      fields={this.props.protected_fields}
                      original_fields={this.props.original_protected_fields}
                      handleFieldChange={this.props.handleProtectedFieldChange}
                    />
                  </div>
                  <div className="field-container">
                    <BMHSInputField
                      field_id="middleName"
                      default_class="input-report left"
                      field_placeholder="middle name"
                      field_maxlength={524288} /*This is the normal default value*/
                      fields={this.props.protected_fields}
                      original_fields={this.props.original_protected_fields}
                      handleFieldChange={this.props.handleProtectedFieldChange}
                    />
                  </div>
                  <div className="field-container">
                    <BMHSInputField
                      field_id="lastName"
                      default_class="input-report"
                      field_placeholder="last name"
                      field_maxlength={524288} /*This is the normal default value*/
                      fields={this.props.protected_fields}
                      original_fields={this.props.original_protected_fields}
                      handleFieldChange={this.props.handleProtectedFieldChange}
                    />
                  </div>
                </div>
                <div className="field-container">
                  <select id="suffix"
                          className={`select-report field-filled ${this.props.protected_fields["suffix"] != this.props.original_protected_fields["suffix"] ? "field-changed" : ""}`}
                          onChange={this.props.handleProtectedFieldChange}
                          value={this.props.protected_fields["suffix"] || ''}
                  >
                    <option value="">select</option>
                    <option value="JR.">JR.</option>
                    <option value="SR.">SR.</option>
                  </select>
                </div>
              </div>
              <br className="re-line-break"/>
            </div>
          )
        }
        {
          this.props.forward_report && (
            <div className="content-area-container">
              <div id="alias-container">
                <div className="field-container">
                  <p className="container-sub-head">Aliases</p>
                  <p className="input-label"> (Separate each by a comma ",") </p>
                  <BMHSInputField
                    field_id="aliases"
                    default_class="input-report"
                    field_placeholder="aliases"
                    field_maxlength={524288} /*This is the normal default value*/
                    fields={this.props.protected_fields}
                    original_fields={this.props.original_protected_fields}
                    handleFieldChange={this.props.handleProtectedFieldChange}
                  />
                </div>
              </div>
              <div id="phone-number-container">
                <div><p className="container-sub-head">Phone Number</p></div>
                <div className="field-container">
                  <BMHSInputField
                    field_id="phoneNumber"
                    default_class="input-report no-label"
                    field_placeholder="( _ _ _ ) _ _ _ - _ _ _ _"
                    field_maxlength={524288} /*This is the normal default value*/
                    fields={this.props.protected_fields}
                    original_fields={this.props.original_protected_fields}
                    handleFieldChange={this.props.handleProtectedFieldChange}
                    handleKeyUp={this.handlePhoneKeyUp}
                  />
                </div>
              </div>
              <br className="re-line-break"/>
            </div>
          )
        }

        <div id="birthdate-and-incident" className="content-area-container">
          {
            this.props.forward_report && (
              <div id="birthdate-container">
                <p className="container-sub-head">Date of Birth</p>
                <div className="field-container">
                  <p className="input-label">Year</p>
                  <BMHSInputField
                    field_id="birthYear"
                    default_class="birthDayText input-report left"
                    field_placeholder="Y Y Y Y"
                    field_maxlength={4}
                    fields={this.props.report_object}
                    original_fields={this.props.original_report_object}
                    handleFieldChange={this.props.handleFieldChange}
                    handleBlur={this.handleBirthDayBlur}
                    handleInput={this.handleBirthDayInput}
                    handleKeyUp={this.handleBirthDayInput}
                    handlePaste={this.handleBirthDayInput}
                  />
                </div>
                <div className="field-container">
                  <p className="input-label">Month</p>
                  <BMHSInputField
                    field_id="birthMonth"
                    default_class="birthDayText input-report left"
                    field_placeholder="M M"
                    field_maxlength={2}
                    fields={this.props.report_object}
                    original_fields={this.props.original_report_object}
                    handleFieldChange={this.props.handleFieldChange}
                    handleBlur={this.handleBirthDayBlur}
                    handleInput={this.handleBirthDayInput}
                    handleKeyUp={this.handleBirthDayInput}
                    handlePaste={this.handleBirthDayInput}
                  />
                </div>
                <div className="field-container">
                  <p className="input-label">Day</p>
                  <BMHSInputField
                    field_id="birthDay"
                    default_class="birthDayText input-report"
                    field_placeholder="D D"
                    field_maxlength={2}
                    fields={this.props.report_object}
                    original_fields={this.props.original_report_object}
                    handleFieldChange={this.props.handleFieldChange}
                    handleBlur={this.handleBirthDayBlur}
                  />
                </div>
              </div>
            )
          }
          <div id="incident-container">
            <div><p className="container-sub-head">Incident Number</p></div>
            <div id="incident-number-input-container" className="field-container">
              <p id="incident-number-ex"
                 className="input-label">{parseHashtagString(this.props.incident_number_ex)}</p>
              <BMHSInputField
                field_id="incidentNumber"
                default_class="incidentNumber input-report"
                field_placeholder=""
                field_maxlength={524288} /*This is the normal default value*/
                fields={this.props.report_object}
                original_fields={this.props.original_report_object}
                handleFieldChange={this.props.handleFieldChange}
              />
            </div>
          </div>
          {
            this.props.forward_report && (
              <div id="address-container" className="content-area-container">
                <div><p className="container-sub-head">Home Address </p></div>

                <div className="field-container">
                  <p className="input-label">Street Number</p>
                  <BMHSInputField
                    field_id="streetNumber"
                    default_class="input-report left"
                    field_placeholder="street #"
                    field_maxlength={524288} /*This is the normal default value*/
                    fields={this.props.protected_fields}
                    original_fields={this.props.original_protected_fields}
                    handleFieldChange={this.props.handleProtectedFieldChange}
                  />
                </div>
                <div className="field-container">
                  <p className="input-label">Street Name</p>
                  <BMHSInputField
                    field_id="streetName"
                    default_class="input-report left"
                    field_placeholder="street name"
                    field_maxlength={524288} /*This is the normal default value*/
                    fields={this.props.protected_fields}
                    original_fields={this.props.original_protected_fields}
                    handleFieldChange={this.props.handleProtectedFieldChange}
                  />
                </div>
                <div className="field-container">
                  <p className="input-label">Unit / Suite</p>
                  <BMHSInputField
                    field_id="apartment"
                    default_class="input-report"
                    field_placeholder="unit"
                    field_maxlength={524288} /*This is the normal default value*/
                    fields={this.props.protected_fields}
                    original_fields={this.props.original_protected_fields}
                    handleFieldChange={this.props.handleProtectedFieldChange}
                  />
                </div>
                <br/>
                <div className="field-container">
                  <p className="input-label-no-head">City</p>
                  <BMHSInputField
                    field_id="city"
                    default_class="input-report left"
                    field_placeholder="city"
                    field_maxlength={524288} /*This is the normal default value*/
                    fields={this.props.protected_fields}
                    original_fields={this.props.original_protected_fields}
                    handleFieldChange={this.props.handleProtectedFieldChange}
                  />
                </div>
                <div className="field-container">
                  <p className="input-label-no-head">Province</p>
                  <BMHSInputField
                    field_id="province"
                    default_class="input-report left"
                    field_placeholder="province"
                    field_maxlength={524288} /*This is the normal default value*/
                    fields={this.props.protected_fields}
                    original_fields={this.props.original_protected_fields}
                    handleFieldChange={this.props.handleProtectedFieldChange}
                  />
                </div>
                <div className="field-container">
                  <p className="input-label-no-head">Country</p>
                  <BMHSInputField
                    field_id="country"
                    default_class="input-report"
                    field_placeholder="country"
                    field_maxlength={524288} /*This is the normal default value*/
                    fields={this.props.protected_fields}
                    original_fields={this.props.original_protected_fields}
                    handleFieldChange={this.props.handleProtectedFieldChange}
                  />
                </div>
              </div>
            )
          }
          {
            this.props.forward_report && (
              <div id="incident-location-container" className="content-area-container">
                <p id="incident-location-title" className="container-sub-head">Incident Location</p>
                <div id="incident-location-input-container" className="field-container">
                  <BMHSTextAreaField
                    field_id="incidentLocation"
                    default_class="incident-location-textarea"
                    field_placeholder="incident location"
                    fields={this.props.protected_fields}
                    original_fields={this.props.original_protected_fields}
                    handleFieldChange={this.props.handleProtectedFieldChange}
                  />
                </div>
                <div className="copy-address-button" tabIndex="0"
                     onClick={this.copyHomeAddress}>
                  <div className="copy-home-text">COPY HOME</div>
                  <div className="address-text">ADDRESS</div>
                </div>
                <br className="re-line-break"/>
              </div>
            )
          }
        </div>


        <div className="content-area-container notes-div">
          <p id="de-escalation-advice-title" className="container-sub-head">De-escalation
            Advice</p>
        </div>

        <div id="triggers-container" className="content-area-container notes-div">
          <p id="trigger-title" className="container-sub-head"> Triggers </p>
          <BMHSTextAreaField
            field_id="triggers"
            default_class="text-area-report"
            field_placeholder="possible triggers"
            fields={this.props.protected_fields}
            original_fields={this.props.original_report_object}
            handleFieldChange={this.props.handleProtectedFieldChange}
          />
          <br className="re-line-break"/>
        </div>

        <div id="de-escalation-container" className="content-area-container notes-div">
          <p id="de-escalation-title" className="container-sub-head"> De-escalation </p>
          <BMHSTextAreaField
            field_id="deescalation"
            default_class="text-area-report"
            field_placeholder="possible de-escalation methods"
            fields={this.props.protected_fields}
            original_fields={this.props.original_report_object}
            handleFieldChange={this.props.handleProtectedFieldChange}
          />
          <br className="re-line-break"/>
        </div>

        <div id="other-factors-container" className="content-area-container notes-div">
          <p id="other-factors-title" className="container-sub-head"> Other
            Factors </p>
          <BMHSTextAreaField
            field_id="other_factors"
            default_class="text-area-report"
            field_placeholder="other relevant factors"
            fields={this.props.protected_fields}
            original_fields={this.props.original_report_object}
            handleFieldChange={this.props.handleProtectedFieldChange}
          />
          <br className="re-line-break"/>
        </div>
      </div>
    )
  }
}

function BMHSInputField(props) {
  return (
    <input id={props.field_id} type="text" placeholder={props.field_placeholder}
           className={`${props.default_class} 
           ${props.fields[props.field_id] != props.original_fields[props.field_id] ? "field-changed" : ""}
           ${props.fields["missing"] && props.fields["missing"][props.field_id] ? "missing" : ""}`}
           onChange={props.handleFieldChange}
           maxLength={props.field_maxlength}
           onKeyUp={props.handleKeyUp}
           onInput={props.handleInput}
           onPaste={props.handlePaste}
           onBlur={props.handleBlur}
           value={props.fields[props.field_id] || ''}/>
  )
}

function BMHSTextAreaField(props) {
  return (
    <textarea id={props.field_id} rows="1" placeholder={props.field_placeholder}
              className={`${props.default_class} ${props.fields[props.field_id] != props.original_fields[props.field_id] ? "field-changed" : ""}`}
              onChange={props.handleFieldChange}
              value={props.fields[props.field_id] || ''}/>
  )
}


export default EditableBMHS