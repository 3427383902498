import React from 'react'
import BarChartIcon from '@material-ui/icons/BarChart';
import PieChartIcon from '@material-ui/icons/PieChart';
import TimelineIcon from '@material-ui/icons/Timeline';
import ScatterPlotIcon from '@material-ui/icons/ScatterPlot';
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PieGraph from './pie_graph'
import BarGraph from './bar_graph'
import LineGraph from './line_graph'
import PlotGraph from './plot_graph'
import DataViewerTable from './data_table'
import {DataViewerGraphTypeHorizontal, PresetOptions, SavePresetModal, SharePresetModal} from './preset_options'
import FilterModal from './filter_modal'
import {ColorThemeOptions, DataViewerCheckBox, DataViewerGraphType} from './data_viewer_custom_fields'
import html2pdf from 'html2pdf.js'
import Canvg from 'canvg';

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      graph_colour: "rgba(255,255,255,1)",
      graph_type: "",
      x_axis_1: "",
      x_axis_2: "",
      measurement: "call_volume",
      output_format: "count",
      filters: JSON.parse(JSON.stringify(this.props.filter_starter)),
      modal_filters: {},
      active_index: 0,
      start_date: new Date((new Date()).setDate((new Date()).getDate() - 30)),
      end_date: new Date(),
      graph_data: [],
      graph_title: "",
      graph_sub_title: "",
      table_status: false,
      graph_output_format: "count",
      graph_measurement: "call_volume",
      graph_colour_theme: "default_call_vol",
      graph_table_data: {},
      graph_table_col_keys: {},
      table_data: {},
      table_col_keys: {},
      table_col_label: {},
      separated_row_keys: [],
      hover: false,
      data_keys: {},
      index_by: "",
      index_by_label: "",
      y_axis_label: "",
      graph_type_selected: "",
      missing_count: 0,
      selected_preset: undefined,
      show_preset_save_modal: false,
      show_preset_share_modal: false,
      show_filter_modal: false,
      new_preset_name: "",
      user_preset_legend: {},
      user_presets: [],
      user_preset_options: [],
      viewing: true,
      loading: false
    };

    this.zero_placeholder_thresh = 0.005;
    this.zero_placeholder = 0;
    this.max_val = 0;
    this.opacity_text = "0.3)";

    // Left Nav functions
    this.handleGraphTypeChange = this.handleGraphTypeChange.bind(this);
    this.handleOutputFormatChange = this.handleOutputFormatChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleGenerateGraph = this.handleGenerateGraph.bind(this);
    this.getXVar1Options = this.getXVar1Options.bind(this);
    this.getXVar2Options = this.getXVar2Options.bind(this);
    this.filterXVar = this.filterXVar.bind(this);
    this.clearXSelected = this.clearXSelected.bind(this);

    // Chart Functions
    this.formatValue = this.formatValue.bind(this);
    this.formatTime = this.formatTime.bind(this);
    this.handleLegendClick = this.handleLegendClick.bind(this);
    this.handleLegendOnHover = this.handleLegendOnHover.bind(this);
    this.handleLegendOnHoverExit = this.handleLegendOnHoverExit.bind(this);
    this.recalculateData = this.recalculateData.bind(this);
    this.getVisibleTotal = this.getVisibleTotal.bind(this);
    this.getTooltipText = this.getTooltipText.bind(this);
    this.handleTableToggle = this.handleTableToggle.bind(this);

    // Preset Functions
    this.handlePresetSelect = this.handlePresetSelect.bind(this);
    this.handleSavePresetClick = this.handleSavePresetClick.bind(this);
    this.handleHidePresetSaveModal = this.handleHidePresetSaveModal.bind(this);
    this.handlePresetNameChange = this.handlePresetNameChange.bind(this);
    this.handlePresetNameConfirm = this.handlePresetNameConfirm.bind(this);
    this.getUserPresets = this.getUserPresets.bind(this);
    this.handleSharePresetClick = this.handleSharePresetClick.bind(this);
    this.handleHidePresetShareModal = this.handleHidePresetShareModal.bind(this);

    // Filter functions
    this.filterText = this.filterText.bind(this);
    this.handleShowFilterModal = this.handleShowFilterModal.bind(this);
    this.getFilterCount = this.getFilterCount.bind(this);
    this.handleHideFilterModal = this.handleHideFilterModal.bind(this);
    this.handleOptionClick = this.handleOptionClick.bind(this);
    this.handleMainOptionClick = this.handleMainOptionClick.bind(this);
    this.optionStatus = this.optionStatus.bind(this);
    this.mainOptionStatus = this.mainOptionStatus.bind(this);
    this.handleAddFilters = this.handleAddFilters.bind(this);
    this.handleClearFilterClick = this.handleClearFilterClick.bind(this);
    this.handleTabChange = this.handleTabChange.bind(this);

    // Misc Functions
    this.handleClearClick = this.handleClearClick.bind(this);
    this.handleDownloadPrintClick = this.handleDownloadPrintClick.bind(this);
    this.isIE = this.isIE.bind(this);
    this.handlePrintCommand = this.handlePrintCommand.bind(this);
    this.printDownloadPDF = this.printDownloadPDF.bind(this)
  }

  // Misc
  componentDidMount() {
    this.getUserPresets();
    document.addEventListener('keydown', this.handlePrintCommand);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handlePrintCommand);
  }

  handlePrintCommand(e) {
    if (e.keyCode === 80 && e.ctrlKey || e.keyCode === 80 && e.metaKey) {
      e.preventDefault();
      this.handleDownloadPrintClick(true)
    }
  }

  isIE() {
    var ua = window.navigator.userAgent;
    var msie = ua.indexOf("MSIE ");
    return msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)
  }

  handleClearClick() {
    this.setState({
      graph_type: "",
      x_axis_1: "",
      x_axis_2: "",
      output_format: "count",
      filters: JSON.parse(JSON.stringify(this.props.filter_starter)),
      start_date: new Date((new Date()).setDate((new Date()).getDate() - 30)),
      end_date: new Date(),
      graph_data: [],
      graph_title: "",
      measurement: "call_volume",
      graph_sub_title: "",
      graph_output_format: "count",
      graph_measurement: "call_volume",
      graph_colour_theme: "default_call_vol",
      table_status: false,
      data_keys: {},
      index_by: "",
      index_by_label: "",
      y_axis_label: "",
      graph_type_selected: "",
      missing_count: 0,
      selected_preset: undefined,
      new_preset_name: "",
      graph_table_data: [],
      table_data: [],
      graph_table_col_keys: {},
      table_col_keys: {},
      viewing: true,
      loading: false
    })
  }

  printDownloadPDF(print) {
    var first_var = this.state.index_by_label.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word) {
      return word.toUpperCase();
    }).replace(/\s+/g, '');

    var file_name = "HealthIM_" + first_var + "_" + (moment(new Date()).format("MMDDYYYY")) + ".pdf";

    var margin_top = 25;
    var margin_bottom = 15;

    var opt = {
      pagebreak: {mode: '', before: '.avoidThisRow'},
      margin: [margin_top, 0, margin_bottom, 0],
      image: {type: 'jpeg', quality: 1},
      html2canvas: {scale: 2, allowTaint: true},
      jsPDF: {format: 'letter', unit: "mm", orientation: 'p'}
    };

    var imgData = this.props.download_images;

    var element = document.getElementById('data-viewer-display');
    var cv = document.getElementById('temp-canvas');
    var result = document.getElementById('temp-img');
    var chart_container = $(".graph-chart-container")[0];
    var title_text = document.getElementById('graph-title-text-container');
    var legend_container = document.getElementById('graph-legend-container');
    var svg = $(".graph-chart-container svg");

    html2pdf().from(element).set(opt).toPdf().get('pdf').then(function (pdf) {
      var totalPages = pdf.internal.getNumberOfPages();
      var date_text = (moment(new Date()).format("MM-DD-YYYY hh:mm")).toString();
      var page_bottom_change = 5;

      var icon_data = pdf.getImageProperties(imgData["service_icon"]);
      var icon_w = icon_data.width;
      var icon_h = icon_data.height;
      var icon_ratio = icon_w / icon_h;
      var icon_n_w = 18 * icon_ratio;
      var icon_n_h = 18;

      var hm_logo_data = pdf.getImageProperties(imgData["healthim_black"]);
      var hm_logo_w = hm_logo_data.width;
      var hm_logo_h = hm_logo_data.height;
      var hm_logo_ratio = hm_logo_w / hm_logo_h;
      var hm_logo_n_w = 18 * hm_logo_ratio;
      var hm_logo_n_h = 18;

      pdf.setFont('Helvetica');
      for (var i = 1; i <= totalPages; i++) {
        pdf.setPage(i);

        //Header
        pdf.setFontSize(14);
        pdf.setTextColor('#000000');
        pdf.setFontType("bold");
        pdf.text(this.props.org_name, icon_n_w + 12, 10);

        pdf.setFontSize(10);
        pdf.text("ANALYTICS DATA EXPORT", icon_n_w + 12, 15);

        pdf.setFontType("normal");
        pdf.text("Exported On: " + date_text, icon_n_w + 12, 20);

        pdf.addImage(imgData["healthim_black"], "PNG", pdf.internal.pageSize.getWidth() - (hm_logo_n_w + 10), 5, hm_logo_n_w, hm_logo_n_h);
        pdf.addImage(imgData["service_icon"], "PNG", 10, 5, icon_n_w, icon_n_h);

        //Footer
        pdf.setFontSize(12);
        pdf.setTextColor('#808080');
        pdf.text("Exported On: " + date_text,
          pdf.internal.pageSize.getWidth() / 2,
          pdf.internal.pageSize.getHeight() - page_bottom_change, {
            width: 550,
            align: 'center'
          }
        );

        pdf.text("Page " + i + " of " + totalPages,
          pdf.internal.pageSize.getWidth() - 10,
          pdf.internal.pageSize.getHeight() - page_bottom_change, {
            width: 550,
            align: 'right'
          }
        );

        pdf.addImage(imgData["healthim_Grey"], "PNG", 10,
          pdf.internal.pageSize.getHeight() - page_bottom_change - 4.5,
          hm_logo_n_w / 3, hm_logo_n_h / 3)
      }

      if (print && !this.isIE()) {
        if (document.getElementById("pdf-iframe")) {
          document.getElementById("pdf-iframe").remove();
        }

        var iframe = document.createElement('iframe');
        var frameId = "pdf-iframe";
        iframe.id = frameId;
        iframe.name = frameId;
        iframe.className = frameId;
        iframe.style.visibility = "hidden";
        iframe.style.position = "absolute";
        iframe.style.right = "0";
        iframe.style.bottom = "0";
        iframe.style.target = "_blank";
        iframe.type = "application/pdf";
        iframe.src = pdf.output('bloburl');
        document.body.appendChild(iframe);

        setTimeout(function () {
          window.frames[frameId].focus();
          window.frames[frameId].print();
        }, 1000)

      } else {
        setTimeout(function () {
          pdf.save(file_name);
        }, 0)
      }

      this.setState({
        viewing: true,
        graph_colour: "rgba(255,255,255,1)",
        separated_row_keys: []
      });
      cv.style.display = "none";
      svg.show();
      chart_container.style.height = "";
      result.src = "";
      result.style.width = '0px';
      result.style.height = '0px';
      legend_container.style.width = "80%";
      title_text.style.width = "80%";
      $('.avoidThisRow').removeClass('avoidThisRow');
      $('.avoidNextRow').removeClass('avoidNextRow');
    }.bind(this));
  }

  handleDownloadPrintClick(print) {
    if (this.state.graph_data.length === 0) {
      alert("There is no data!");
      return
    }

    this.setState({
      viewing: false,
      graph_colour: "rgba(0,0,0,1)"
    }, () => {
      var svg = $(".graph-chart-container svg");
      var data = svg.parent().html().replace(/xmlns=\"http:\/\/www\.w3\.org\/2000\/svg\"/, '');
      data = data.replace(/rotate\(0\)/g, '')
        .replace(/translate\(-110 135\)/g, '')
        .replace(/translate\(-110\, 135\)/g, '')
        .replace(/rotate\(-90\)/g, 'rotate(-90,15,120)');
      svg.hide();

      var chart_width = svg.attr("width");
      var chart_height = svg.attr("height");
      var c_ar = chart_height / chart_width;

      var pixelRatio = window.devicePixelRatio || 1;
      var cv = document.getElementById('temp-canvas');
      cv.style.display = "block";
      const ctx = cv.getContext('2d');
      ctx.mozImageSmoothingEnabled = false;
      ctx.webkitImageSmoothingEnabled = false;
      ctx.msImageSmoothingEnabled = false;
      ctx.imageSmoothingEnabled = false;
      cv.width = chart_width * 2;
      cv.height = chart_height * 2;
      cv.style.width = cv.width + 'px';
      cv.style.height = cv.height + 'px';
      cv.width *= pixelRatio;
      cv.height *= pixelRatio;
      ctx.scale(pixelRatio, pixelRatio);

      cv.width = chart_width;
      cv.height = chart_height;
      cv.style.width = cv.width + 'px';
      cv.style.height = cv.height + 'px';

      var v = Canvg.fromString(ctx, data, {
        ignoreMouse: true,
        ignoreAnimation: true,
        ignoreDimensions: true,
        forceRedraw: true,
        scaleWidth: cv.width,
        scaleHeight: cv.height
      });
      v.start();
      cv.style.display = "none";
      var ur = cv.toDataURL("image/png");
      var result = document.getElementById('temp-img');
      result.src = ur;

      var pdf_height = 792;
      var pdf_width = 612;
      var ptToPixel = (4.0 / 3.0);
      var mmToPt = (72.0 / 25.4);
      var container_width = pdf_width * ptToPixel * 0.9;

      result.style.width = container_width + "px";
      result.style.height = container_width * c_ar + "px";
      var chart_container = $(".graph-chart-container")[0];
      chart_container.style.height = container_width * c_ar + "px";
      var margin_top = 25;
      var margin_bottom = 15;

      var default_height = (pdf_height - ((margin_bottom + margin_top) * mmToPt)) * ptToPixel;

      var remaining_height = default_height;

      //Border
      remaining_height -= 1;

      //Padding
      remaining_height -= 10;

      //Chart header
      var title_text = document.getElementById('graph-title-text-container');
      title_text.style.width = (pdf_width * ptToPixel * 0.8) + "px";
      remaining_height -= title_text.offsetHeight;


      //Chart
      remaining_height -= (container_width * c_ar);

      //Spacing between chart and legend
      remaining_height -= 64;

      //Border
      remaining_height -= 1;

      var legend_container = document.getElementById('graph-legend-container');
      legend_container.style.width = (pdf_width * ptToPixel * 0.9 * 0.8) + "px";

      //Padding
      remaining_height -= 10;

      // Exclusion list
      var exclusions = document.getElementById('graph-exclusions');
      if (exclusions) {
        remaining_height -= document.getElementById('graph-exclusions-text').offsetHeight;
        var elem = exclusions.children;
        for (var i = 0; i < elem.length; i++) {
          if (i % 4 === 0) {
            var child_height = elem[i].offsetHeight;
            if (remaining_height - child_height <= 0) {
              remaining_height = default_height - child_height;
              for (var x = 0; x < 4; x++) {
                if (elem[i + x]) {
                  elem[i + x].children[0].classList.add("avoidThisRow")
                }
              }
            } else {
              remaining_height -= child_height
            }
          }
        }

        remaining_height -= 16
      }

      // Legend list
      var legend_text = document.getElementById('graph-legend-text');
      var legend_text_height = legend_text.offsetHeight;
      if (remaining_height - legend_text_height <= 0) {
        remaining_height = default_height;
        legend_text.classList.add("avoidThisRow")
      }
      remaining_height -= legend_text_height;

      var legend = document.getElementById('graph-legend');

      var elem = legend.getElementsByClassName("graph-legend-item-container");
      for (var i = 0; i < elem.length; i++) {
        if (i % 4 === 0) {
          var child_height = elem[i].offsetHeight;
          if (remaining_height - child_height <= 0) {
            remaining_height = default_height - child_height;
            for (var x = 0; x < 4; x++) {
              if (elem[i + x]) {
                elem[i + x].children[0].classList.add("avoidThisRow")
              }
            }
          } else {
            remaining_height -= child_height
          }
        }
      }

      //Border
      remaining_height -= 2;

      //Padding
      remaining_height -= 10;

      if (this.state.table_status) {
        //Space between graph and table
        remaining_height -= 16;
        var data_table = document.getElementById('data_viewer_table_container');
        data_table.style.width = container_width + "px";

        //First Header
        var table_header = document.getElementById('data-table-header');
        var table_header_height = table_header.offsetHeight;
        remaining_height -= table_header_height;
        //Border
        remaining_height -= 2;

        var table_body = document.getElementById('data-table-body');
        var table_data = table_body.children;
        var temp_row_keys = [];
        var table_row_keys = [];
        var row_keys = Object.keys(this.state.table_data);
        //Start at 1 since the first row is the vertical header
        for (var i = 1; i < table_data.length; i++) {
          var row_ind = i - 1;
          var row_height = table_data[i].offsetHeight;

          //Border
          row_height += 1;

          console.log(remaining_height)
          console.log(row_height)
          if (remaining_height - row_height <= 0) {
            var next_page = table_row_keys.length > 0;
            table_row_keys.push({next_page: next_page, data: temp_row_keys});
            temp_row_keys = [];
            temp_row_keys.push(row_keys[row_ind]);
            remaining_height = default_height - table_header_height - row_height;
          } else {
            temp_row_keys.push(row_keys[row_ind]);
            remaining_height -= row_height
            //Border
            remaining_height -= 2;
          }
        }
        var next_page = table_row_keys.length > 0;
        table_row_keys.push({next_page: next_page, data: temp_row_keys});

        table_row_keys = table_row_keys.filter(val => {
          return val.data.length > 0
        });
        this.setState({
          separated_row_keys: table_row_keys
        }, () => {
          this.printDownloadPDF(print)
        })
      } else {
        this.printDownloadPDF(print)
      }

    })
  }

  // Filter Functions

  getFilterCount() {
    var filterArrays = Object.values(this.state.filters);
    var count = 0;
    for (var i in filterArrays) {
      count += filterArrays[i].length;
    }
    return count;
  }

  handleShowFilterModal() {
    this.setState({
      active_index: 0,
      show_filter_modal: true,
      modal_filters: JSON.parse(JSON.stringify(this.state.filters))
    })
  }

  filterText() {
    var n = this.getFilterCount();
    if (n > 0) {
      var plural = n > 1 ? "s" : "";
      return n + " Filter" + plural + " Applied";
    } else {
      return "Filters";
    }
  }

  handleHideFilterModal() {
    this.setState({
      show_filter_modal: false
    })
  }

  handleOptionClick(sub_group, val) {
    var key = sub_group["sub_group_id"];
    if (this.optionStatus(key, val)) {
      this.setState(prevState => {
        var index = this.state.modal_filters[key].indexOf(val);
        prevState = JSON.parse(JSON.stringify(this.state.modal_filters));
        prevState[key] = prevState[key].slice(0, index).concat(prevState[key].slice(index + 1));

        if (sub_group["sub_group_is_option_id"] && sub_group["sub_group_is_option_value"]) {
          var sub_key = sub_group["sub_group_is_option_id"];
          var sub_val = sub_group["sub_group_is_option_value"];
          if (prevState[key].length === 0 && this.optionStatus(sub_key, sub_val)) {
            var sub_index = this.state.modal_filters[sub_key].indexOf(sub_val);
            prevState[sub_key] = prevState[sub_key].slice(0, sub_index).concat(prevState[sub_key].slice(sub_index + 1));
          }
        }

        return {modal_filters: prevState}
      })
    } else {
      this.setState(prevState => {
        prevState = JSON.parse(JSON.stringify(this.state.modal_filters));
        prevState[key] = prevState[key].concat([val]);

        if (sub_group["sub_group_is_option_id"] && sub_group["sub_group_is_option_value"]) {
          var sub_key = sub_group["sub_group_is_option_id"];
          var sub_val = sub_group["sub_group_is_option_value"];
          if (!this.optionStatus(sub_key, sub_val)) {
            prevState[sub_key] = prevState[sub_key].concat([sub_val]);
          }
        }

        return {modal_filters: prevState}
      })
    }
  }

  handleMainOptionClick(sub_group) {
    var key = sub_group["sub_group_id"];
    if (this.mainOptionStatus(sub_group)) {
      this.setState(prevState => {
        prevState = JSON.parse(JSON.stringify(this.state.modal_filters));
        prevState[key] = [];
        if (sub_group["sub_group_is_option_id"] && sub_group["sub_group_is_option_value"]) {
          var sub_key = sub_group["sub_group_is_option_id"];
          var sub_val = sub_group["sub_group_is_option_value"];
          if (this.optionStatus(sub_key, sub_val)) {
            var sub_index = this.state.modal_filters[sub_key].indexOf(sub_val);
            prevState[sub_key] = prevState[sub_key].slice(0, sub_index).concat(prevState[sub_key].slice(sub_index + 1));
          }
        }

        return {modal_filters: prevState}
      })
    } else {
      this.setState(prevState => {
        prevState = JSON.parse(JSON.stringify(this.state.modal_filters));
        prevState[key] = sub_group["sub_group_options"].map(item => {
          return item["option_value"]
        });

        if (sub_group["sub_group_is_option_id"] && sub_group["sub_group_is_option_value"]) {
          var sub_key = sub_group["sub_group_is_option_id"];
          var sub_val = sub_group["sub_group_is_option_value"];
          if (!this.optionStatus(sub_key, sub_val)) {
            prevState[sub_key] = prevState[sub_key].concat([sub_val]);
          }
        }

        return {modal_filters: prevState}
      })
    }
  }

  optionStatus(key, val) {
    return this.state.modal_filters[key].indexOf(val) > -1
  }

  mainOptionStatus(sub_group) {
    if (sub_group["sub_group_options"].length === 0
      && sub_group["sub_group_is_option_id"]
      && sub_group["sub_group_is_option_value"]) {
      return this.optionStatus(sub_group["sub_group_is_option_id"], sub_group["sub_group_is_option_value"])
    } else {
      for (var i in sub_group["sub_group_options"]) {
        if (this.optionStatus(sub_group["sub_group_id"], sub_group["sub_group_options"][i]["option_value"])) {
          return true
        }
      }
      return false
    }

  }

  handleAddFilters() {
    this.setState({
      selected_preset: undefined,
      show_filter_modal: false,
      filters: JSON.parse(JSON.stringify(this.state.modal_filters))
    })
  }

  handleClearFilterClick(main_group_options) {
    this.setState(prevState => {
      prevState = JSON.parse(JSON.stringify(this.state.modal_filters));
      for (var ind in main_group_options) {
        var sub_group = main_group_options[ind];
        var key = sub_group["sub_group_id"];
        var sub_key = sub_group["sub_group_is_option_id"];
        if (prevState[key]) {
          prevState[key] = []
        }

        if (sub_key && prevState[sub_key]) {
          prevState[sub_key] = []
        }
      }

      return {modal_filters: prevState}
    })
  }

  handleTabChange(index) {
    this.setState({active_index: index})
  }


  // Chart Functions
  handleLegendClick(index, key, type) {
    if (type === "find") {
      this.state.graph_data.find(v => v[key] == index).show = !this.state.graph_data.find(v => v[key] == index).show;
      this.state.graph_data.find(v => v[key] == index).show_hover = false;
    } else if (type === "data_keys") {
      this.state.data_keys[index].show = !this.state.data_keys[index].show;
      this.state.data_keys[index].show_hover = false
    } else if (type === "data") {
      for (var k in this.state.graph_data) {
        this.state.graph_data[k].show_hover = false;
        this.state.graph_data[k].data.find(v => v[key] == index).show = !this.state.graph_data[k].data.find(v => v[key] == index).show
      }
    }

    this.setState({hover: false});
    this.forceUpdate();
  }

  handleLegendOnHover(index) {
    if (this.state.graph_type_selected === "pie") {
      if (this.state.graph_data.find(v => v.index == index).show) {
        this.state.graph_data.find(v => v.index == index).show_hover = true;
        this.setState({hover: true});
        this.forceUpdate();
      }
    } else if (this.state.graph_type_selected === "bar") {
      if (this.state.data_keys[index].show) {
        this.state.data_keys[index].show_hover = true;
        this.setState({hover: true});
        this.forceUpdate();
      }
    } else if (this.state.graph_type_selected === "line" || this.state.graph_type_selected === "plot") {
      if (this.state.graph_data.find(v => v.index == index).show) {
        this.state.graph_data.find(v => v.index == index).show_hover = true;
        var dt = this.state.graph_data.find(v => v.index == index).data;
        for (var key in dt) {
          dt[key].show_hover = true;
        }
        this.state.graph_data.find(v => v.index == index).data = dt;
        this.setState({hover: true});
        this.forceUpdate();
      }
    }
  }

  handleLegendOnHoverExit(index) {
    if (this.state.graph_type_selected === "pie") {
      this.state.graph_data.find(v => v.index == index).show_hover = false;
      this.setState({hover: false});
      this.forceUpdate();
    } else if (this.state.graph_type_selected === "bar") {
      this.state.data_keys[index].show_hover = false;
      this.setState({hover: false});
      this.forceUpdate();
    } else if (this.state.graph_type_selected === "line" || this.state.graph_type_selected === "plot") {
      this.state.graph_data.find(v => v.index == index).show_hover = false;
      var dt = this.state.graph_data.find(v => v.index == index).data;
      for (var key in dt) {
        dt[key].show_hover = false;
      }
      this.state.graph_data.find(v => v.index == index).data = dt;
      this.setState({hover: false});
      this.forceUpdate();
    }
  }

  handleTableToggle() {
    if (this.state.table_status) {
      this.setState({
        table_status: false,
        table_data: {},
        table_col_keys: {}
      })
    } else {
      this.setState({
        table_status: true,
        table_data: this.state.graph_table_data,
        table_col_keys: this.state.graph_table_col_keys
      })
    }
  }

  formatValue(val, show_val, label) {
    if (!label && val === 0) return "N/A";
    if (val === this.zero_placeholder) val = 0;

    if (!show_val && (this.state.graph_type_selected === "bar" || this.state.graph_type_selected === "line")
      && this.state.graph_output_format === "percentage") {
      return val.toFixed(2) + "%"
    } else if (!show_val && this.state.graph_type_selected === "pie" && this.state.graph_output_format === "percentage") {
      return ((val / this.getVisibleTotal()) * 100).toFixed(2).toString() + "%"
    } else {
      if (this.state.graph_measurement === "wait_time_avg" || this.state.graph_measurement === "wait_time_sum") {
        return this.formatTime(val)
      } else {
        return val.toString()
      }
    }

  }

  formatTime(time) {
    return Math.round(time / 60).toString() + "h " + Math.round(time % 60).toString() + "m"
  }

  recalculateData() {
    var dt = JSON.parse(JSON.stringify(this.state.graph_data.filter(val => {
      return val.show
    })));

    if (this.state.graph_type_selected === "bar") {
      var max_val = 0;
      if (this.state.graph_output_format === "percentage") {
        var tot = this.getVisibleTotal();

        var dt_keys = Object.keys(this.state.data_keys);
        for (var key in dt) {
          for (var k in dt_keys) {
            if (dt[key][dt_keys[k]]) {
              if (tot > 0) {
                dt[key][dt_keys[k]] = ((dt[key][dt_keys[k] + "_original"] / tot) * 100)
              }
            }
          }
        }

        max_val = 100
      } else {
        var max_val = 0;
        var dt_keys = Object.keys(this.state.data_keys);
        for (var key in dt) {
          for (var k in dt_keys) {
            if (dt[key][dt_keys[k]] > max_val) {
              max_val = dt[key][dt_keys[k]]
            }
          }
        }
      }

      this.max_val = max_val;
      this.zero_placeholder = max_val * this.zero_placeholder_thresh;

      var dt_keys = Object.keys(this.state.data_keys);
      for (var key in dt) {
        for (var k in dt_keys) {
          if (dt[key][dt_keys[k]] === 0) {
            dt[key][dt_keys[k]] = this.zero_placeholder
          }
        }
      }
    }

    if (this.state.graph_type_selected === "line" || this.state.graph_type_selected === "plot") {
      var max_val = 0;
      for (var key in dt) {
        dt[key].data = dt[key].data.filter(val => {
          return val.show
        });
      }
      if (this.state.graph_output_format === "percentage") {
        var tot = this.getVisibleTotal();
        for (var key in dt) {
          if (dt[key].show) {
            for (var k in dt[key].data) {
              if (dt[key].data[k] && dt[key].data[k].show) {
                dt[key].data[k]["y"] = ((dt[key].data[k]["y_original"] / tot) * 100)
              }
            }
          }
        }
        max_val = 100
      } else {
        var max_val = 0;
        for (var key in dt) {
          if (dt[key].show) {
            for (var k in dt[key].data) {
              if (dt[key].data[k] && dt[key].data[k].show) {
                if (dt[key].data[k]["y"] > max_val) {
                  max_val = dt[key].data[k]["y"]
                }
              }
            }
          }
        }
      }
      this.max_val = max_val;
      this.zero_placeholder = max_val * this.zero_placeholder_thresh
    }

    return dt
  }

  getVisibleTotal() {
    var obj = this.state.graph_data;
    var total = 0;
    if (this.state.graph_type_selected === "pie") {
      for (var key in obj) {
        if (obj[key].show) {
          total += obj[key]["value"]
        }
      }
    } else if (this.state.graph_type_selected === "bar") {
      var dt_keys = Object.keys(this.state.data_keys);
      for (var key in obj) {
        if (obj[key].show) {
          for (var k in dt_keys) {
            if (obj[key][dt_keys[k]] && this.state.data_keys[dt_keys[k]].show) {
              total += obj[key][dt_keys[k] + "_original"]
            }
          }
        }
      }
    } else if (this.state.graph_type_selected === "line" || this.state.graph_type_selected === "plot") {
      for (var key in obj) {
        if (obj[key].show) {
          for (var k in obj[key].data) {
            if (obj[key].data[k] && obj[key].data[k].show) {
              total += obj[key].data[k]["y_original"]
            }
          }
        }
      }
    }

    return total
  }

  getTooltipText(item, type) {
    var text = [];
    if (type === "item") {
      var obj = this.state.graph_data;
      for (var key in obj) {
        text.push(this.state.index_by_label + ": " + obj[key][this.state.index_by] + ": " + this.formatValue(obj[key][item], true, false))
      }
    } else if (type === "all") {
      var dt_keys = Object.keys(this.state.data_keys);
      for (var key in dt_keys) {
        text.push(dt_keys[key] + ": " + this.formatValue(item[dt_keys[key]], true, false))
      }
    } else if (type === "all_data") {
      var obj = item.data;
      for (var key in obj) {
        text.push(this.state.index_by_label + ": " + obj[key]["x"].toString() + ": " + this.formatValue(obj[key]["y"], true, false))
      }
    } else if (type === "find_all") {
      var obj = this.state.graph_data;
      for (var key in obj) {
        var val = obj[key].data.find(val => val["x"] === item)["y"];
        text.push(obj[key]["id"] + ": " + this.formatValue(val, true, false))
      }
    }

    return text.map((val, i) => <div key={i}>{val}</div>)
  }


  // Preset Functions

  handlePresetSelect(val) {
    if (val.deleted) {
      this.getUserPresets();
      this.setState({selected_preset: undefined})
    } else {
      var temp = JSON.parse(JSON.stringify(val));
      temp.label = (
        <div className="row">
          <DataViewerGraphTypeHorizontal
            label={val.label}
            graph_type={val.graph_type}
          />
        </div>
      );

      var legend = {};
      if (val.group === "user") {
        legend = this.state.user_preset_legend[temp.value]
      } else {
        legend = this.props.system_preset_legend[temp.value]
      }

      this.setState({
        graph_type: legend.graph_type,
        x_axis_1: legend.x_axis_1,
        x_axis_2: legend.x_axis_2,
        measurement: legend.measurement,
        output_format: legend.output_format,
        graph_colour_theme: legend.graph_colour_theme,
        selected_preset: temp
      });

      this.setState(prevState => {
        prevState = JSON.parse(JSON.stringify(this.props.filter_starter));
        var keys = Object.keys(prevState);
        for (var ind in keys) {
          var key = keys[ind];
          if (legend.filters[key]) {
            prevState[key] = legend.filters[key]
          }
        }

        return {filters: prevState}
      })
    }
  }

  handleSavePresetClick() {
    var error_message = "";
    if (this.state.graph_type === "") {
      error_message += "Graph Type is mandatory\n"
    }

    if (this.state.x_axis_1 === "") {
      error_message += "Variable 1 is mandatory\n"
    }

    if (error_message !== "") {
      var default_error_message = "Cannot Save Preset for the following reasons:\n";
      alert(default_error_message + error_message);
      return;
    }

    this.setState({
      show_preset_save_modal: true,
      new_preset_name: ""
    })
  }

  handleHidePresetSaveModal() {
    this.setState({
      show_preset_save_modal: false
    })
  }

  handlePresetNameChange(e) {
    this.setState({
      new_preset_name: e.target.value,
    })
  }

  handlePresetNameConfirm() {
    var error_message = "";
    if (this.state.graph_type === "") {
      error_message += "Graph Type is mandatory\n"
    }

    if (this.state.x_axis_1 === "") {
      error_message += "Variable 1 is mandatory\n"
    }

    if (this.state.new_preset_name === "") {
      error_message += "Preset Name is mandatory\n"
    }

    if (error_message !== "") {
      var default_error_message = "Cannot Save Preset for the following reasons:\n";
      alert(default_error_message + error_message);
      return;
    }


    $.ajax({
      url: 'data_viewer/create_preset',
      type: 'PUT',
      data: {
        graph_type: this.state.graph_type,
        x_axis_1: this.state.x_axis_1,
        x_axis_2: this.state.x_axis_2,
        measurement: this.state.measurement,
        output_format: this.state.output_format,
        filters: this.state.filters,
        graph_colour_theme: this.state.graph_colour_theme,
        preset_name: this.state.new_preset_name
      }
    }).done(function (data) {
      this.getUserPresets()
    }.bind(this));

    this.setState({
      show_preset_save_modal: false,
      new_preset_name: "",
      selected_preset: undefined
    });
  }

  handleSharePresetClick() {
    this.getUserPresets();
    this.setState({
      show_preset_share_modal: true
    })
  }

  handleHidePresetShareModal() {
    this.setState({
      show_preset_share_modal: false
    })
  }


  getUserPresets() {
    $.ajax({
      url: '/data_viewer/get_presets',
      method: 'GET',
    }).done(function (data) {
      this.setState({
        user_preset_legend: data["user_preset_legend"],
        user_presets: data["user_presets"],
        user_preset_options: data["user_preset_options"]
      })
    }.bind(this))
  }


  // Left Nav Functions

  handleGenerateGraph() {
    var error_message = "";
    if (this.state.graph_type === "") {
      error_message += "Graph Type is mandatory\n"
    }

    if (this.state.x_axis_1 === "") {
      error_message += "Variable 1 is mandatory\n"
    }

    if (this.state.start_date > this.state.end_date) {
      error_message += "Start Date Cannot be after End Date\n"
    }

    if (error_message !== "") {
      var default_error_message = "Graph could not generate for the following reasons:\n";
      alert(default_error_message + error_message);
      return;
    }

    this.setState({loading: true});

    $.ajax({
      url: '/data_viewer/graph_details',
      method: 'GET',
      data: {
        graph_type: this.state.graph_type,
        x_axis_1: this.state.x_axis_1,
        x_axis_2: this.state.x_axis_2,
        measurement: this.state.measurement,
        x_axis_1_label: this.props.x_axis_legend[this.state.x_axis_1],
        x_axis_2_label: this.props.x_axis_legend[this.state.x_axis_2],
        measurement_label: this.props.measurement_legend[this.state.measurement],
        output_format: this.state.output_format,
        filters: this.state.filters,
        start_date: this.state.start_date,
        end_date: this.state.end_date,
        graph_colour_theme: this.state.graph_colour_theme
      }
    }).done(function (data) {
      if (data["graph_data"].length > 0) {
        var table_col_label = this.props.measurement_legend[this.state.measurement];
        var title = this.props.measurement_legend[this.state.measurement] + ", " + this.props.x_axis_legend[this.state.x_axis_1];
        if (this.state.x_axis_2 !== "") {
          title += " by " + this.props.x_axis_legend[this.state.x_axis_2];
          table_col_label = this.props.x_axis_legend[this.state.x_axis_2]
        }
        var sub_title = moment(this.state.start_date).format("MMMM DD YYYY").toString() + " - " + moment(this.state.end_date).format("MMMM DD YYYY").toString();
        var y_axis_type = this.state.output_format;
        y_axis_type = y_axis_type.charAt(0).toUpperCase() + y_axis_type.slice(1);


        this.setState({
          graph_data: data["graph_data"],
          graph_title: title,
          graph_sub_title: sub_title,
          graph_output_format: this.state.output_format,
          graph_measurement: this.state.measurement,
          data_keys: data["data_keys"],
          index_by: this.state.x_axis_1,
          index_by_label: this.props.x_axis_legend[this.state.x_axis_1],
          y_axis_label: this.props.measurement_legend[this.state.measurement] + " " + y_axis_type,
          graph_type_selected: this.state.graph_type,
          missing_count: data["missing_count"],
          graph_table_data: data["table_data"],
          graph_table_col_keys: data["table_col_keys"],
          table_col_label: table_col_label
        });

        if (this.state.table_status) {
          this.setState({
            table_data: data["table_data"],
            table_col_keys: data["table_col_keys"]
          })
        }
      } else {
        alert("Search complete. Could not find any data")
      }
      this.setState({loading: false})
    }.bind(this))
      .fail(function (request, status, error) {
        this.setState({loading: false});
        alert("Something went wrong!")
      }.bind(this))
  }


  getXVar1Options() {
    var x_opt = JSON.parse(JSON.stringify(this.props.x_axis_options));
    x_opt = this.filterXVar(x_opt, this.props.x_var_1_exclusion);

    return x_opt
  }

  getXVar2Options() {
    var x_opt = JSON.parse(
      JSON.stringify(
        [{value: "", label: "None"}].concat(
          this.props.x_axis_options.filter(val => {
            return val.value !== this.state.x_axis_1
          }))
      ));

    x_opt = this.filterXVar(x_opt, this.props.x_var_2_exclusion);
    return x_opt
  }

  filterXVar(x_options, exclusion_list) {
    var x_opt = x_options.filter(val => {
      var exclusion_keys = Object.keys(exclusion_list);
      for (var i in exclusion_keys) {
        var key = exclusion_keys[i];
        if (this.state[key]) {
          var exclusion_options = exclusion_list[key][this.state[key]];
          if (exclusion_options && exclusion_options.length > 0) {
            return exclusion_options.indexOf(val["value"]) < 0
          }
        }
      }
      return true
    });
    return x_opt
  }

  handleGraphTypeChange(value) {
    //Exclusion list filtering goes here
    this.setState({
      selected_preset: undefined,
      graph_type: value
    });

    this.clearXSelected("graph_type", value, this.props.x_var_1_exclusion, "x_axis_1");
    this.clearXSelected("graph_type", value, this.props.x_var_2_exclusion, "x_axis_2")
  }

  handleOutputFormatChange(value) {
    this.setState({
      selected_preset: undefined,
      output_format: value
    });

    this.clearXSelected("output_format", value, this.props.x_var_1_exclusion, "x_axis_1");
    this.clearXSelected("output_format", value, this.props.x_var_2_exclusion, "x_axis_2")
  }

  handleSelectChange(e, id) {
    this.setState({
      selected_preset: undefined,
      [id]: e.value
    });

    if (id === "x_axis_1" && e.value === this.state.x_axis_2) {
      this.setState({x_axis_2: ""})
    }

    this.clearXSelected(id, e.value, this.props.x_var_1_exclusion, "x_axis_1");
    this.clearXSelected(id, e.value, this.props.x_var_2_exclusion, "x_axis_2")
  }

  handleDateChange(date, date_type) {
    this.setState({
      [date_type]: date
    });
  }

  clearXSelected(key, val, exclusion_list, x_var) {
    if (exclusion_list[key] &&
      exclusion_list[key][val] &&
      exclusion_list[key][val].length > 0 &&
      exclusion_list[key][val].indexOf(this.state[x_var]) > -1) {
      this.setState({[x_var]: ""})
    }
  }

  render() {
    return (
      <div id="data-viewer-main-container" className="main-content">
        <div id="data-viewer-container" className="container-fluid row-fluid">
          <div id="graph-controls-container" className="col-sm-12 col-md-2 col-lg-2">
            <hr className="data-viewer-hr"/>
            <div className="graph-controls-header-text">
              <p>1. Select Graph Type</p>
            </div>
            <div id="graph-type-container" className="row">
              <DataViewerGraphType
                graph_type={this.state.graph_type}
                graph_var={"bar"}
                graph_text={"Bar"}
                graph_icon={BarChartIcon}
                handleGraphTypeChange={this.handleGraphTypeChange}
              />

              <DataViewerGraphType
                graph_type={this.state.graph_type}
                graph_var={"pie"}
                graph_text={"Pie"}
                graph_icon={PieChartIcon}
                handleGraphTypeChange={this.handleGraphTypeChange}
              />

              <DataViewerGraphType
                graph_type={this.state.graph_type}
                graph_var={"line"}
                graph_text={"Line"}
                graph_icon={TimelineIcon}
                handleGraphTypeChange={this.handleGraphTypeChange}
              />

              <DataViewerGraphType
                graph_type={this.state.graph_type}
                graph_var={"plot"}
                graph_text={"Plot"}
                graph_icon={ScatterPlotIcon}
                handleGraphTypeChange={this.handleGraphTypeChange}
              />
            </div>

            <div className="graph-controls-header-text">
              <p>2. Output Format</p>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div
                  className={this.state.output_format === "count" ? "graph-control-button-active" : "graph-control-button"}
                  onClick={() => {
                    this.handleOutputFormatChange("count")
                  }}
                >
                  Count
                </div>
              </div>
              <div className="col-md-6">
                <div
                  className={this.state.output_format === "percentage" ? "graph-control-button-active" : "graph-control-button"}
                  onClick={() => {
                    this.handleOutputFormatChange("percentage")
                  }}
                >
                  Percentage
                </div>
              </div>
            </div>

            <hr className="data-viewer-hr"/>

            <div className="graph-controls-header-text">
              <p>3. Select Variables</p>
            </div>

            <div className="graph-controls-sub-header-text">
              <p>Measurement</p>
            </div>

            <div className="graph-select-container">
              <Select
                classNamePrefix="dv-select"
                onChange={(value) => {
                  this.handleSelectChange(value, "measurement")
                }}
                value={this.state.measurement ? {
                  value: this.state.measurement,
                  label: this.props.measurement_legend[this.state.measurement]
                } : null}
                placeholder="Select..."
                options={this.props.measurement_options}
              />
            </div>

            <hr className="data-viewer-hr"/>

            <div className="graph-controls-sub-header-text">
              <p>Variable 1</p>
            </div>

            <div className="graph-select-container">
              <Select
                classNamePrefix="dv-select"
                onChange={(value) => {
                  this.handleSelectChange(value, "x_axis_1")
                }}
                value={this.state.x_axis_1 ? {
                  value: this.state.x_axis_1,
                  label: this.props.x_axis_legend[this.state.x_axis_1]
                } : null}
                placeholder="Select..."
                options={this.getXVar1Options()}
              />
            </div>

            <hr className="data-viewer-hr"/>

            <div className="graph-controls-sub-header-text">
              <p>Variable 2</p>
            </div>
            <div className="graph-select-container">
              <Select
                classNamePrefix="dv-select"
                onChange={(value) => {
                  this.handleSelectChange(value, "x_axis_2")
                }}
                value={this.state.x_axis_2 ? {
                  value: this.state.x_axis_2,
                  label: this.props.x_axis_legend[this.state.x_axis_2]
                } : null}
                placeholder="Select..."
                options={this.getXVar2Options()}
              />
            </div>

            <hr className="data-viewer-hr"/>

            <div className="graph-controls-header-text">
              <p>4. Apply Filters</p>
            </div>
            <div className={this.getFilterCount() > 0 ? "graph-control-button-active" : "graph-control-button"}
                 onClick={() => {
                   this.handleShowFilterModal()
                 }}
            >
              {this.filterText()}
            </div>

            <hr className="data-viewer-hr"/>

            <div className="graph-controls-header-text">
              <p>5. Select Time Frame</p>
            </div>
            <div className="graph-controls-sub-header-text">
              <p>Start Date</p>
            </div>
            <div className="graph-update-fields-box-date">
              <DatePicker
                onChange={(value) => {
                  this.handleDateChange(value, "start_date")
                }}
                selected={this.state.start_date}
                dateFormat="yyyy-MM-dd"
                className="graph-control-button-date"
                placeholderText="YYYY-MM-DD"/>
            </div>

            <hr className="data-viewer-hr"/>

            <div className="graph-controls-sub-header-text">
              <p>End Date</p>
            </div>
            <div className="graph-update-fields-box-date">
              <DatePicker
                onChange={(value) => {
                  this.handleDateChange(value, "end_date")
                }}
                selected={this.state.end_date}
                dateFormat="yyyy-MM-dd"
                className="graph-control-button-date"
                placeholderText="YYYY-MM-DD"/>
            </div>

            <hr className="data-viewer-hr"/>

            <div className="graph-controls-header-text">
              <p>6. Select Colour Theme</p>
            </div>
            <div className="graph-controls-sub-header-text">
              <p>Colour Themes</p>
            </div>

            <div className="graph-select-container">
              <Select
                classNamePrefix="dv-select"
                onChange={(value) => {
                  this.handleSelectChange(value, "graph_colour_theme")
                }}
                value={this.state.graph_colour_theme ? {
                  value: this.state.graph_colour_theme,
                  label: this.props.colour_theme_legend[this.state.graph_colour_theme]
                } : null}
                placeholder="Select..."
                options={this.props.colour_theme_options}
                components={{Option: ColorThemeOptions}}
              />
            </div>

            <br/>

            <div className="graph-control-button-active"
                 onClick={() => {
                   this.handleGenerateGraph()
                 }}
            >
              Generate Graph
            </div>
          </div>

          <div id="graph-container" className="col-sm-12 col-md-10 col-lg-10">
            <div id="graph-header">
              <div id="graph-header-button-container">
                <div id="graph-header-left-container">
                  <div className="graph-control-button-clear"
                       onClick={() => {
                         this.handleClearClick()
                       }}
                  >
                    Clear
                  </div>
                </div>

                <div id="graph-header-right-container">
                  <div className="graph-control-button-active-header"
                       onClick={() => {
                         this.handleSavePresetClick()
                       }}
                  >
                    Save as Preset
                  </div>

                  <div className="graph-control-button-active-header graph-header-right-container-preset"
                       onClick={() => {
                         this.handleSharePresetClick()
                       }}
                  >
                    Share Presets
                  </div>

                  <div className="graph-control-button-active-header"
                       onClick={() => {
                         this.handleDownloadPrintClick(false)
                       }}
                  >
                    Download
                  </div>

                  <div className="graph-control-button-active-header"
                       style={{display: this.isIE() ? "none" : "block"}}
                       onClick={() => {
                         this.handleDownloadPrintClick(true)
                       }}
                  >
                    Print
                  </div>
                </div>


                <div id="graph-header-right-container">
                  <div className="graph-preset-select-container">
                    <Select
                      classNamePrefix="dv-select"
                      onChange={(val) => {
                        this.handlePresetSelect(val)
                      }}
                      value={this.state.selected_preset ? this.state.selected_preset : null}
                      placeholder={
                        <div className="row">
                          <DataViewerGraphTypeHorizontal
                            label={"Presets"}
                            graph_type={"bar"}
                          />
                        </div>
                      }
                      options={this.props.preset_options.concat(this.state.user_presets)}
                      components={{Option: PresetOptions}}
                      isOptionDisabled={(option) => option.disabled}
                    />
                  </div>
                </div>
              </div>
            </div>
            <DataViewerDisplay
              graph_data={this.recalculateData()}
              graph_full_data={this.state.graph_data}
              graph_type_selected={this.state.graph_type_selected}
              graph_title={this.state.graph_title}
              graph_sub_title={this.state.graph_sub_title}
              formatValue={this.formatValue}
              handleLegendClick={this.handleLegendClick}
              handleLegendOnHover={this.handleLegendOnHover}
              handleLegendOnHoverExit={this.handleLegendOnHoverExit}
              hover={this.state.hover}
              data_keys={this.state.data_keys}
              index_by={this.state.index_by}
              y_axis_label={this.state.y_axis_label}
              graph_output_format={this.state.graph_output_format}
              getTooltipText={this.getTooltipText}
              missing_count={this.state.missing_count}
              max_val={this.max_val}

              label={"Show Data Table"}
              handleTableToggle={this.handleTableToggle}
              table_status={this.state.table_status}

              separated_row_keys={this.state.separated_row_keys}
              table_data={this.state.table_data}
              table_col_keys={this.state.table_col_keys}
              table_col_label={this.state.table_col_label}
              index_by_label={this.state.index_by_label}
              graph_colour={this.state.graph_colour}

              viewing={this.state.viewing}
              loading={this.state.loading}
              loading_ani={this.props.loading_ani}
              opacity_text={this.opacity_text}
            />
          </div>
        </div>

        <SavePresetModal
          show_preset_save_modal={this.state.show_preset_save_modal}
          handleHidePresetSaveModal={this.handleHidePresetSaveModal}
          handlePresetNameChange={this.handlePresetNameChange}
          handlePresetNameConfirm={this.handlePresetNameConfirm}
        />

        <SharePresetModal
          show_preset_share_modal={this.state.show_preset_share_modal}
          handleHidePresetShareModal={this.handleHidePresetShareModal}
          user_preset_options={this.state.user_preset_options}
          other_users={this.props.other_users}
        />

        <FilterModal
          show_filter_modal={this.state.show_filter_modal}
          active_index={this.state.active_index}
          handleHideFilterModal={this.handleHideFilterModal}
          filter_options={this.props.filter_options}
          handleOptionClick={this.handleOptionClick}
          handleMainOptionClick={this.handleMainOptionClick}
          optionStatus={this.optionStatus}
          handleAddFilters={this.handleAddFilters}
          handleClearFilterClick={this.handleClearFilterClick}
          handleTabChange={this.handleTabChange}
          mainOptionStatus={this.mainOptionStatus}
        />

        <div id="dt-download-print-spinner-container" style={{display: this.state.viewing ? "none" : "block"}}>
          <img id="dt-download-print-spinner" src={this.props.loading_ani}/>
        </div>
      </div>
    );
  }
}

class DataViewerDisplay extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div id="data-viewer-display">
        <DataViewerGraph
          graph_data={this.props.graph_data}
          graph_full_data={this.props.graph_full_data}
          graph_type_selected={this.props.graph_type_selected}
          graph_title={this.props.graph_title}
          graph_sub_title={this.props.graph_sub_title}
          formatValue={this.props.formatValue}
          handleLegendClick={this.props.handleLegendClick}
          handleLegendOnHover={this.props.handleLegendOnHover}
          handleLegendOnHoverExit={this.props.handleLegendOnHoverExit}
          hover={this.props.hover}
          data_keys={this.props.data_keys}
          index_by={this.props.index_by}
          index_by_label={this.props.index_by_label}
          y_axis_label={this.props.y_axis_label}
          graph_output_format={this.props.graph_output_format}
          getTooltipText={this.props.getTooltipText}
          graph_colour={this.props.graph_colour}
          missing_count={this.props.missing_count}
          viewing={this.props.viewing}
          loading={this.props.loading}
          max_val={this.props.max_val}
          loading_ani={this.props.loading_ani}
          opacity_text={this.props.opacity_text}
        />
        {this.props.viewing ?
          <div id="graph-check-container">
            <DataViewerCheckBox
              label={"Show Data Table"}
              fill_colour={"#bfbfbf"}
              back_colour={"transparent"}

              handleCheckClick={this.props.handleTableToggle}
              checked={this.props.table_status}
            />
          </div>
          : null
        }
        <br/>


        <DataViewerTableDisplay
          separated_row_keys={this.props.separated_row_keys}
          table_data={this.props.table_data}
          table_col_keys={this.props.table_col_keys}
          table_row_keys={Object.keys(this.props.table_data)}
          table_col_label={this.props.table_col_label}
          index_by_label={this.props.index_by_label}
          graph_colour={this.props.graph_colour}
          viewing={this.props.viewing}
          formatValue={this.props.formatValue}
        />
      </div>
    );
  }
}

function DataViewerTableDisplay(props) {
  if (props.separated_row_keys.length > 0) {
    return (
      <div>
        {props.separated_row_keys.map((row_keys, index) => (
          <DataViewerTable
            key={index}
            index={index}
            table_index={index}
            table_data={props.table_data}
            table_col_keys={props.table_col_keys}
            table_row_keys={row_keys.data}
            table_col_label={props.table_col_label}
            index_by_label={props.index_by_label}
            graph_colour={props.graph_colour}
            viewing={props.viewing}
            formatValue={props.formatValue}
            separated={row_keys.next_page}
          />
        ))}
      </div>
    )
  } else {
    return (
      <DataViewerTable
        table_index={0}
        table_data={props.table_data}
        table_col_keys={props.table_col_keys}
        table_row_keys={props.table_row_keys}
        table_col_label={props.table_col_label}
        index_by_label={props.index_by_label}
        graph_colour={props.graph_colour}
        viewing={props.viewing}
        formatValue={props.formatValue}
        separated={false}
      />
    )
  }

}


function DataViewerGraph(props) {
  if (props.loading) {
    return (
      <div id="graph-text-container">
        <img src={props.loading_ani}/>
      </div>
    )
  } else if (props.graph_data.length === 0 && !props.loading) {
    return (
      <div id="graph-text-container">
        <div className="graph-temp-text">
          <p>Use the controls on the left to create graphs.</p>
        </div>
        <div className="graph-temp-text">
          <p>Select “Generate Graph” after setting parameters to view the graph.</p>
        </div>
      </div>
    )
  } else {
    if (props.graph_type_selected === "bar") {
      return (<BarGraph
        graph_data={props.graph_data}
        graph_full_data={props.graph_full_data}
        graph_title={props.graph_title}
        graph_sub_title={props.graph_sub_title}
        formatValue={props.formatValue}
        handleLegendClick={props.handleLegendClick}
        handleLegendOnHover={props.handleLegendOnHover}
        handleLegendOnHoverExit={props.handleLegendOnHoverExit}
        hover={props.hover}
        data_keys={props.data_keys}
        index_by={props.index_by}
        graph_output_format={props.graph_output_format}
        index_by_label={props.index_by_label}
        y_axis_label={props.y_axis_label}
        getTooltipText={props.getTooltipText}
        graph_colour={props.graph_colour}
        viewing={props.viewing}
        max_val={props.max_val}
        opacity_text={props.opacity_text}
      />)
    } else if (props.graph_type_selected === "pie") {
      return (<PieGraph
        graph_data={props.graph_data}
        graph_full_data={props.graph_full_data}
        graph_title={props.graph_title}
        graph_sub_title={props.graph_sub_title}
        formatValue={props.formatValue}
        handleLegendClick={props.handleLegendClick}
        handleLegendOnHover={props.handleLegendOnHover}
        handleLegendOnHoverExit={props.handleLegendOnHoverExit}
        hover={props.hover}
        graph_colour={props.graph_colour}
        viewing={props.viewing}
        opacity_text={props.opacity_text}
      />)
    } else if (props.graph_type_selected === "line") {
      return (<LineGraph
        graph_data={props.graph_data}
        graph_full_data={props.graph_full_data}
        graph_title={props.graph_title}
        graph_sub_title={props.graph_sub_title}
        formatValue={props.formatValue}
        handleLegendClick={props.handleLegendClick}
        handleLegendOnHover={props.handleLegendOnHover}
        handleLegendOnHoverExit={props.handleLegendOnHoverExit}
        hover={props.hover}
        graph_output_format={props.graph_output_format}
        index_by_label={props.index_by_label}
        y_axis_label={props.y_axis_label}
        getTooltipText={props.getTooltipText}
        graph_colour={props.graph_colour}
        viewing={props.viewing}
        max_val={props.max_val}
        opacity_text={props.opacity_text}
      />)
    } else if (props.graph_type_selected === "plot") {
      return (<PlotGraph
        graph_data={props.graph_data}
        graph_full_data={props.graph_full_data}
        graph_title={props.graph_title}
        graph_sub_title={props.graph_sub_title}
        formatValue={props.formatValue}
        handleLegendClick={props.handleLegendClick}
        handleLegendOnHover={props.handleLegendOnHover}
        handleLegendOnHoverExit={props.handleLegendOnHoverExit}
        hover={props.hover}
        graph_output_format={props.graph_output_format}
        index_by_label={props.index_by_label}
        y_axis_label={props.y_axis_label}
        getTooltipText={props.getTooltipText}
        graph_colour={props.graph_colour}
        missing_count={props.missing_count}
        viewing={props.viewing}
        max_val={props.max_val}
        opacity_text={props.opacity_text}
      />)
    } else {
      return (
        <div id="graph-text-container">
          <div className="graph-temp-text">
            <p>Use the controls on the left to create graphs.</p>
          </div>
          <div className="graph-temp-text">
            <p>Select “Generate Graph” after setting parameters to view the graph.</p>
          </div>
        </div>
      )
    }
  }
}

export default Index
