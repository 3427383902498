import React from 'react'
import {createTheme, MuiThemeProvider} from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";

export default class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      orgs: this.props.orgs
    }
  }

  componentDidMount() {
    // DISABLING AUTO REFRESH BECAUSE NO ONE CHECKS IT ACTIVELY
    // setInterval(() => $.ajax({
    //   url: '/golden_eye/get_receiver_status.json',
    //   type: 'GET'
    // }).done(function (data) {
    //   this.setState({orgs: data.orgs})
    // }.bind(this)), 30000);
  }


  getMuiTheme = () => createTheme({
    overrides: {
      MUIDataTable: {
        paper: {
          backgroundColor: "#25282c",
          boxShadow: "none !important"
        }
      },
      MuiTypography: {
        root: {
          fontSize: '24px !important',
          color: '#f2f2f2 !important',
        }
      },
      MuiTable: {
        root: {
          border: 'none'
        }
      },
      MUIDataTablePagination: {
        root: {
          position: 'fixed',
          bottom: '0px',
          width: '100vw',
          backgroundColor: "#51565E",
        }
      },
      MuiSelect: {
        select: {
          paddingRight: '40px !important'
        }
      },
      MUIDataTableHeadCell: {
        sortActive: {
          color: '#f2f2f2'
        }
      },
      MuiSvgIcon: {
        root: {
          background: 'transparent !important',
          fontSize: '40px !important',
          fill: '#f2f2f2 !important',
        }
      },
      MuiInputBase: {
        root: {
          fontSize: '36px !important',
          color: '#f2f2f2 !important',
          "&:before": {
            borderBottom: '1px solid #f2f2f2',
          },
        }
      },
      MuiInput: {
        underline: {
          "&:after": {
            borderBottom: '1px solid #f2f2f2',
          },
          "&:before": {
            borderBottom: '1px solid #f2f2f2',
          },
        }
      },
      MuiPaper: {
        root: {
          backgroundColor: '#51565E'
        },
      },
      MuiMenuItem: {
        root: {
          fontSize: '24px',
          color: '#f2f2f2',
        },
      },
      MUIDataTableFilter: {
        root: {
          backgroundColor: '#51565E'
        },
        title: {
          fontSize: '16px',
          color: '#f2f2f2',
        },
        resetLink: {
          fontSize: '16px',
          color: '#6CA9FF',
        }
      },
      MuiFormLabel: {
        root: {
          fontSize: '24px',
          color: '#f2f2f2 !important',
        },
      },
      MuiGridListTile: {
        root: {
          width: "100% !important"
        }
      },
      MuiTablePagination: {
        root: {
          width: "calc(100vw - 210px) !important"
        }
      },
      MuiTableSortLabel: {
        root: {
          color: '#f2f2f2',
          fontSize: 30,
          background: 'transparent',
          "&:hover": {
            color: '#f2f2f2',
            fontSize: 30,
            background: 'transparent',
            '&& $icon': {
              opacity: 1,
              fontSize: 30,
              color: '#f2f2f2',
              background: 'transparent',
            },
          },
          "&$active": {
            color: '#f2f2f2',
            fontSize: 30,
            background: 'transparent',
            '&& $icon': {
              opacity: 1,
              fontSize: 30,
              background: 'transparent',
              color: '#f2f2f2'
            },
          },
        },
      },
      MUIDataTableBody: {
        emptyTitle: {
          fontSize: '24px',
          fontFamily: 'Roboto',
          fontWeight: 'bold'
        }
      },
    }
  });

  render() {
    const filter_col_opt = {
      filter: true,
      display: true,
      sort: true,
      searchable: true
    };
    const no_filter_col_opt = {
      filter: false,
      display: true,
      sort: true,
      searchable: true
    };
    const hidden_column = {
      filter: false,
      display: false,
      sort: false,
      searchable: false
    }
    const table_cols = [
      {
        name: "organization_id",
        label: "Organization ID",
        options: hidden_column
      },
      {
        name: "organization_name",
        label: "Organization Name",
        options: {
          filter: true,
          display: true,
          sort: true,
          searchable: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <a href={`/golden_eye/organization_information/${tableMeta["rowData"][0]}`}>{value}</a>
            );
          },
        }
      },
      {
        name: "software_version",
        label: "Software Version",
        options: filter_col_opt
      },
    ];

    const table_options = {
      filter: true,
      filterType: 'dropdown',
      search: true,
      print: false,
      download: false,
      viewColumns: false,
      selectableRows: "none",
      rowHover: false,
      rowsPerPage: 15,
      rowsPerPageOptions: [10, 15, 50],
      responsive: 'scrollFullHeight',
      setTableProps: () => {
        return {
          className: "ge-better-reports-table"
        };
      },
      setRowProps: (row) => {
        return {
          className: "ge-report-rows",
        };
      },
      textLabels: {
        body: {
          noMatch: "No reports matching search",
          toolTip: ""
        }
      }
    };

    return (
      <div className="main-content">
        <div id="record-editor-container" className="container-fluid">
          <div className="row-fluid">
            <div id="record-editor-header">
              <h1>TESTING ORGANIZATIONS</h1>
            </div>
            <div>
              <MuiThemeProvider theme={this.getMuiTheme()}>
                <MUIDataTable
                  data={this.state.orgs}
                  columns={table_cols}
                  options={table_options}/>
              </MuiThemeProvider>
            </div>
          </div>
        </div>
      </div>
    )
  }
}