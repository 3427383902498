import React from 'react'
import {Modal} from "react-bootstrap";
import PlumCouleeMentalHealthReferral from "./plum_coulee_mental_health_referral";
import Select from "react-select";


class Disposition extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show_definition: false,
      definition_select: 0,
      section_d_questions: this.props.is_plum_coulee ? [1, 6] : [1, 2, 6],
      special_questions: [
        {
          key: "mhstAttended",
          title: "MHST attended scene"
        },
        {
          key: "mhstAttendedHospital",
          title: "MHST officer(s) attended hospital"
        }
      ],
      new_referral_questions: this.props.is_plum_coulee ? (
        ('referralQuestions' in this.props.configuration) ?
          this.getReferralQues(this.props.configuration.referralQuestions) :
          [
            {
              title: "Caseworker notified",
              questionKey: "caseworkerNotified",
              expansionItem: "caseworkerNotifiedNotes",
              expansionItemPlaceholder: "name and phone number",
            },
            {
              title: "Probation notified",
              questionKey: "probationNotified",
              expansionItem: "probationNotifiedNotes",
              expansionItemPlaceholder: "name and phone number",
            },
            {
              title: "CAS notified",
              questionKey: "casNotified",
              expansionItem: "casNotifiedNotes",
              expansionItemPlaceholder: "name and phone number",
            },
          ]) : [],

    };
    this.handleShowDefinition = this.handleShowDefinition.bind(this);
    this.handleHideDefinition = this.handleHideDefinition.bind(this);
    this.referralQuestionPress = this.referralQuestionPress.bind(this);
    this.sectionDPressed = this.sectionDPressed.bind(this);
    this.sectionDYork = this.sectionDYork.bind(this);
    this.getReferralQues = this.getReferralQues.bind(this);
    this.handleCustomChange = this.handleCustomChange.bind(this);
  }

  getReferralQues(questions) {
    return questions.filter(ques => {
      return ques.isDisplayed
    })
      .map(ques => {
        return {
          title: ques.subHeader,
          questionKey: ques.questionKey,
          expansionItem: ques.expansionItem,
          expansionItemPlaceholder: ques.expansionItemPlaceholder,
        }
      })
  }

  sectionDPressed(questionIndex, value) {
    this.props.report_object['sectionDActions'][questionIndex] = value;
    this.forceUpdate()
  }

  sectionDYork(questionIndex, value) {
    this.props.report_object[questionIndex] = value;
    this.forceUpdate()
  }

  handleCustomChange(key, expansionItem, alterExpansion, value, expansionOptions, title) {
    if (alterExpansion) {
      if (value === 'Yes') {
        if (confirm(`Are you sure you want to remove ${title} options ?`)) {
          this.props.report_object[expansionItem] = ""
          this.props.report_object[key] = value;
        }
      } else {
        if (!this.props.report_object[expansionItem])
          this.props.report_object[expansionItem] = expansionOptions[0]
        this.props.report_object[key] = value;
      }
    } else {
      this.props.report_object[key] = value;
    }
    this.forceUpdate()
  }

  referralQuestionPress(questionIndex, value, expansionItem) {
    this.props.report_object[questionIndex] = value;
    if (value === 'Yes') {
      this.props.report_object['sectionDActions'][2] = 1
    } else if (this.props.report_object['caseworkerNotified'] === 'No' &&
      this.props.report_object['probationNotified'] === 'No' &&
      this.props.report_object['casNotified'] === 'No'
    ) {
      this.props.report_object['sectionDActions'][2] = 0
    }

    if (value === 'No') {
      this.props.protected_fields[expansionItem] = ""
    }

    this.forceUpdate()
  }

  handleShowDefinition(i) {
    this.setState({definition_select: i});
    this.setState({show_definition: true});
  }

  handleHideDefinition() {
    this.setState({definition_select: 0});
    this.setState({show_definition: false});
  }

  componentDidMount() {
    if (this.props.is_plum_coulee) {
      let special_questions = [];
      const {extraFields} = this.props.configuration
      if (extraFields.lodgedInCells) {
        special_questions.push({
          key: "lodgedInCells",
          title: "Lodged in cells"
        })
      }
      if (extraFields.otherServiceCallout) {
        special_questions.push({
          key: "otherServiceCallout",
          title: "Callout on behalf of other service"
        })
      }
      if (extraFields.additionalQuestions && extraFields.additionalQuestions.values) {
        extraFields.additionalQuestions.values.forEach(data => {
          let ques = {}
          if ('expansionItem' in data) {
            ques.expansionItem = data.expansionItem;
            ques.expansionItemOptions = data.expansionItemOptions
            if ('alterExpansionOption' in data)
              ques.alterExpansionOption = data.alterExpansionOption
          }
          ques.key = data.key
          ques.title = data.title
          ques.definition = data.definition
          special_questions.push(ques)
        })
        this.setState({special_questions})
      }
    }
  }

  render() {
    return (
      <div id="section-d-container" className="container-report">
        <div className="title-container">
          <h1 className="title-container-head">DISPOSITION</h1>
        </div>

        <div id="police-action-container" className="content-area-container">
          <PlumCouleeMentalHealthReferral
            original_report_object={this.props.original_report_object}
            report_object={this.props.report_object}
          />
          <div className="section-d-header">
            <div>
              <div className="bmhs-option-container-two">
                <div className="bmhs-option-cell-two two-buttons">
                  <p className="bmhs-option-two">No</p>
                </div>
                <div className="bmhs-option-cell-two">
                  <p className="bmhs-option-two">Yes</p>
                </div>
              </div>
            </div>
          </div>

          <div className="content-area-container">
            {
              this.state.section_d_questions.map((item, index) => {
                return (
                  <SectionDQuestion
                    key={index}
                    handleShowDefinition={this.handleShowDefinition}
                    report_object={this.props.report_object}
                    question_type={item}
                    sectionDPressed={this.sectionDPressed}
                  />
                )
              })
            }

            {
              this.state.special_questions.map((item, index) => {
                return (
                  <SpecialSectionDQuestion
                    key={index}
                    report_object={this.props.report_object}
                    special_type={item.key}
                    indicator_text={item.title}
                    expansion_item={item.expansionItem}
                    alterExpansion={item.alterExpansionOption}
                    expansion_options={item.expansionItemOptions}
                    is_plum_coulee={this.props.is_plum_coulee}
                    sectionDYork={this.sectionDYork}
                    handleCustomChange={this.handleCustomChange}
                  />
                )
              })
            }

            {
              this.state.new_referral_questions.map((item, index) => {
                return (
                  <NewReferralQuestion
                    key={index}
                    report_object={this.props.report_object}
                    questionKey={item.questionKey}
                    title={item.title}
                    expansionItem={item.expansionItem}
                    expansionItemPlaceholder={item.expansionItemPlaceholder}
                    handleShowDefinition={this.handleShowDefinition}
                    referralQuestionPress={this.referralQuestionPress}
                    protected_fields={this.props.protected_fields}
                    handleProtectedFieldChange={this.props.handleProtectedFieldChange}
                  />
                )
              })
            }

            <DefinitionModal
              show_definition={this.state.show_definition}
              index={this.state.definition_select}
              handleHideDefinition={this.handleHideDefinition}
            />
          </div>
        </div>
      </div>

    )
  }
}

function DefinitionModal(props) {
  var definitionBody = "";
  var definitionTitle = "";
  switch (props.index) {
    case 1:
      definitionTitle = "Transferred to other emergency service";
      definitionBody = "The responsibility for the person is transferred to another emergency service for example, Emergency Medical Services (EMS) or a mobile mental health crisis team.";
      break;
    case 2:
      definitionTitle = "Caseworker/probation notified";
      definitionBody = "Contact was made with the person's existing community caseworker or probation officer and information regarding this contact with the person was shared, especially in cases where the person is considered vulnerable and a possible danger to him or herself or others";
      break;
    case 6:
      definitionTitle = "Charges pending";
      definitionBody = "The police officer intends on laying charges for an offence(s).";
      break;
    default:
      definitionBody = "";
  }
  return (
    <div>
      <Modal id="report_view_edit_modal"
             backdropClassName="report_view_edit_modal_backdrop"
             show={props.show_definition} backdrop={true} keyboard={false} animation={false}
             onHide={props.handleHideDefinition} dialogClassName={"re-modal-content"}>
        <div id="definition-container">
          <div id="definition-title-container" className="title-container">
            <h1 id="definition-title-container-head" className="title-container-head">{definitionTitle}</h1>
          </div>
          <div id="definition-body">{definitionBody}
          </div>
          <div className="dismiss-btn" id="definition-dismiss"
               onClick={props.handleHideDefinition}>Dismiss
          </div>
        </div>
      </Modal>
    </div>
  )
}

function SpecialSectionDQuestion(props) {
  if (props.is_plum_coulee || props.report_object[props.special_type]) {
    var indicator_text = props.indicator_text;
    return (
      <div className="section-d-question">
        <div className="section-d-question-container">
          <div className="section-d-sub-header">
            <p className="indicators">{indicator_text}</p>
          </div>
          <div className="definition-container" tabIndex="0">
            <p className="define"></p>
          </div>
          <div className="bmhs-cell-container-two" id="section-d-mhst-hospital">
            <div className="bmhs-cell-report">
              <div
                className={`bmhs-button small two-buttons section-d-no ${props.report_object[props.special_type] === "No" ? "selected" : ""}`}
                tabIndex="0" onClick={() => {
                props.handleCustomChange(props.special_type, props.expansion_item, props.alterExpansion,
                  "No", props.expansion_options,props.indicator_text)
              }}>
              </div>
            </div>
            <div className="bmhs-cell-report last">
              <div
                className={`bmhs-button small two-buttons section-d-yes ${props.report_object[props.special_type] === "Yes" ? "selected" : ""}`}
                tabIndex="0" onClick={() => {
                props.handleCustomChange(props.special_type, props.expansion_item, props.alterExpansion, "Yes", props.expansion_options,props.indicator_text)
              }}>
              </div>
            </div>
          </div>
          <div>
            {
              (props.report_object[props.expansion_item]) && (props.report_object[props.expansion_item] !== '') && (props.expansion_options) && (
                <div className={`update-dropdown-container record-editor-select`}>
                  <div className="re-sub-select-label-container">
                    <div className="time-label">Options:</div>
                  </div>
                  <div className="re-sub-select-col">
                    <Select
                      classNamePrefix="re-sub-select"
                      value={{
                        value: props.report_object[props.expansion_item],
                        label: props.report_object[props.expansion_item]
                      }}
                      placeholder="Options"
                      options={props.expansion_options.map(item => {
                        return {value: item, label: item}
                      })}
                      onChange={(val) => {
                        props.sectionDYork(props.expansion_item, val.value)
                      }
                      }
                    />
                  </div>
                  <br/>
                </div>)
            }
          </div>
        </div>
        <br className="re-line-break"/>
      </div>
    );
  } else {
    return (<div></div>);
  }
}

function SectionDQuestion(props) {
  var indicator_text = "";
  switch (props.question_type) {
    case 1:
      indicator_text = "Transferred to other emergency service";
      break;
    case 2:
      indicator_text = "Caseworker / probation notified";
      break;
    case 6:
      indicator_text = "Charges pending";
      break;
    default:
      indicator_text = "";
  }
  return (
    <div className="section-d-question">
      <div className="section-d-question-container">
        <div className="section-d-sub-header">
          <p className="indicators">{indicator_text}</p>
        </div>
        <div className="definition-container" tabIndex="0" onClick={() => {
          props.handleShowDefinition(props.question_type)
        }}>
          <p className="define">Definition</p>
        </div>
        <div className="bmhs-cell-container-two" id="section-d-1">
          <div className="bmhs-cell-report">
            <div
              className={`bmhs-button small two-buttons section-d-no ${props.report_object['sectionDActions'][props.question_type] === 0 ? "selected" : ""}`}
              tabIndex="0" onClick={() => {
              props.sectionDPressed(props.question_type, 0)
            }}>
            </div>
          </div>
          <div className="bmhs-cell-report last">
            <div
              className={`bmhs-button small two-buttons section-d-yes ${props.report_object['sectionDActions'][props.question_type] === 1 ? "selected" : ""}`}
              tabIndex="0" onClick={() => {
              props.sectionDPressed(props.question_type, 1)
            }}>
            </div>
          </div>
        </div>
      </div>
      <br className="re-line-break"/>
    </div>
  );
}

function NewReferralQuestion(props) {
  return (
    <div className="section-d-question">
      <div className="section-d-question-container">
        <div className="section-d-sub-header">
          <p className="indicators">{props.title}</p>
        </div>
        <div className="definition-container" tabIndex="0" onClick={() => {
          props.handleShowDefinition(2)
        }}>
          <p className="define">Definition</p>
        </div>
        <div className="bmhs-cell-container-two" id="section-d-1">
          <div className="bmhs-cell-report">
            <div
              className={`bmhs-button small two-buttons section-d-no ${props.report_object[props.questionKey] === 'No' ? "selected" : ""}`}
              tabIndex="0" onClick={() => {
              props.referralQuestionPress(props.questionKey, 'No', props.expansionItem)
            }}>
            </div>
          </div>
          <div className="bmhs-cell-report last">
            <div
              className={`bmhs-button small two-buttons section-d-yes ${props.report_object[props.questionKey] === 'Yes' ? "selected" : ""}`}
              tabIndex="0" onClick={() => {
              props.referralQuestionPress(props.questionKey, 'Yes', props.expansionItem)
            }}>
            </div>
          </div>
        </div>
        {
          props.report_object[props.questionKey] === 'Yes' && (
            <input
              id={props.expansionItem}
              value={props.protected_fields[props.expansionItem] || ''}
              placeholder={props.expansionItemPlaceholder}
              className="screenerInput"
              onChange={props.handleProtectedFieldChange}
            />)
        }
      </div>
      <br className="re-line-break"/>
    </div>
  );
}


export default Disposition