import React from 'react'
import {RecordEditorDateTime} from './record_editor_custom_fields'

class OfficerTracking extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (this.props.responder_data_object.length === 0) {
      this.props.addResponder();
    }
  }

  render() {
    return (
      <div className="container-report" id="officer-tracking-container">
        <div className="title-container">
          <h1 className="title-container-head">OFFICER TIME TRACKING</h1>
        </div>

        <div id="officers-container" className="content-area-container">
          <div id="officers-times-container">
            {
              this.props.responder_data_object.map((item, index) => {
                return (
                  <OfficerTemplate
                    key={index}
                    index={index}
                    responder_object={item}
                    responder_datetime={this.props.responder_datetime}
                    report_object={this.props.report_object}
                    removeResponder={this.props.removeResponder}
                    is_plum_coulee={this.props.is_plum_coulee}
                  />
                )
              })
            }
          </div>

          <div className="update-times-container" id="add-officer-button-container">
            <div className="time-label-container">
            </div>
            <div className="button-box" id="add-officer" onClick={this.props.addResponder}>
              Add Officer to Call
            </div>
          </div>
        </div>
      </div>
    )
  }
}

class OfficerTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      starting_officer: this.props.responder_object["badgeNumber"] === this.props.report_object["badgeNumber"]
    };

    this.props.responder_object["datetime"] = {};
    for (var dt in this.props.responder_datetime) {
      var field_name = this.props.responder_datetime[dt];
      var r_t = {};
      r_t["date"] = this.props.responder_object[field_name] && this.props.responder_object[field_name].length > 0 ? formatDate(this.props.responder_object[field_name]) : '';
      r_t["time"] = this.props.responder_object[field_name] && this.props.responder_object[field_name].length > 0 ? formatTime(this.props.responder_object[field_name]) : '';
      this.props.responder_object["datetime"][field_name] = r_t;
    }

    this.handleBadgeNumberChange = this.handleBadgeNumberChange.bind(this);
    this.clearTimestamp = this.clearTimestamp.bind(this);
  }

  handleBadgeNumberChange(e) {
    if (this.props.responder_object["missing"] && this.props.responder_object["missing"]["badgeNumber"]) {
      this.props.responder_object["missing"]["badgeNumber"] = false;
    }
    this.props.responder_object["badgeNumber"] = e.target.value;
    this.forceUpdate();
  }

  clearTimestamp() {
    for (var dt in this.props.responder_datetime) {
      var field_name = this.props.responder_datetime[dt];
      this.props.responder_object["datetime"][field_name]["date"] = '';
      this.props.responder_object["datetime"][field_name]["time"] = '';
    }
    this.forceUpdate();
  }

  render() {
    return (
      <div key={this.props.index} id="officer-tracking-template">
        <div className="update-times-container">
          <div className="time-label-container">
            <div className="time-label">Badge Number:</div>
          </div>
          <div className="update-fields-box" id="badge-number-input-container">
            <input value={this.props.responder_object["badgeNumber"]}
                   className={this.props.responder_object["missing"] && this.props.responder_object["missing"]["badgeNumber"] ? "missing" : ""}
                   id="badge-number-input" disabled={this.state.starting_officer}
                   onChange={this.handleBadgeNumberChange} placeholder="badge number"/>
          </div>

          <RecordEditorDateTime
            title="Joined the Call:"
            tooltip="Indicates when the Officer started participating in the call."
            field_id="responderStartTime"
            datetime_value={this.props.responder_object}
          />

          {!this.props.is_plum_coulee && (<RecordEditorDateTime
            title="Overtime 1.5x Started:"
            tooltip="Indicates when the Officer began to accrue 1.5x overtime hours."
            field_id="responderOvertimeStart"
            datetime_value={this.props.responder_object}
          />)}

          {!this.props.is_plum_coulee && (<RecordEditorDateTime
            title="Overtime 2x Started:"
            tooltip="Indicates when the Officer began to accrue 2x overtime hours."
            field_id="responderDoubleTimeStart"
            datetime_value={this.props.responder_object}
          />)}

          <RecordEditorDateTime
            title="Left the Call:"
            tooltip="Indicates when the Officer stopped participating in the call, regardless of if the call was ended or not."
            field_id="responderEndTime"
            datetime_value={this.props.responder_object}
          />

          <div className="update-times-container" id="officer-button-container">
            <div className="time-label-container">
            </div>
            <div className="button-box clear-officer" onClick={this.clearTimestamp}>
              Clear Timestamps
            </div>
            <div className={`button-box delete-officer ${this.state.starting_officer ? 'hide-delete' : ""}`}
                 onClick={() => {
                   this.props.removeResponder(this.props.index)
                 }}
            >
              Delete from Call
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default OfficerTracking