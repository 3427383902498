import React from "react";
import {Modal} from "react-bootstrap";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import {withStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import {DataViewerCheckBox} from './data_viewer_custom_fields'


function FilterModal(props) {
  return (
    <div>
      <Modal id="archive_report_modal"
             backdropClassName="report_view_edit_modal_backdrop"
             show={props.show_filter_modal} backdrop={true} keyboard={false} animation={false}
             onHide={props.handleHideFilterModal} dialogClassName={"dv-modal-content"}>
        <div id="filter_title_modal_container">

          <div id="graph-header-left-container">
            <h2 id="filter_modal_title">Filters</h2>
          </div>


          <div id="graph-header-right-container" className="filter-clear-container">
            <div className="graph-control-button-clear"
                 onClick={() => {
                   props.handleClearFilterClick(
                     props.filter_options.map(elem => {
                       return elem.main_group_options
                     }).reduce(function (a, b) {
                       return a.concat(b);
                     })
                   )
                 }}
            >
              Clear
            </div>
          </div>
        </div>
        <hr className="data-viewer-hr"/>
        <div id="filter-modal-text-container">
          <VerticalTabs value={props.active_index}>
            {
              props.filter_options.map((item, index) => {
                return (
                  <FilterTab
                    className={props.active_index === index ? 'selected' : ''}
                    key={index}
                    value={index}
                    label={item["main_group_label"]}
                    onClick={() => (props.handleTabChange(index))}
                  />
                )
              })
            }
          </VerticalTabs>

          {
            props.filter_options.map((item, index) => {
              return (
                <div className="filter-sub-option-container" key={index}>
                  {
                    props.active_index === index &&
                    <TabContainer
                      index={props.active_index}
                      main_group_options={item["main_group_options"]}
                      handleOptionClick={props.handleOptionClick}
                      optionStatus={props.optionStatus}
                      handleClearFilterClick={props.handleClearFilterClick}
                      mainOptionStatus={props.mainOptionStatus}
                      handleMainOptionClick={props.handleMainOptionClick}
                    />
                  }
                </div>
              )
            })
          }
        </div>
        <div id="filter_modal_button_container">
          <button id="archive_modal_cancel_button" className="automated-button"
                  onClick={props.handleHideFilterModal}>Cancel
          </button>
          <button id="archive_modal_confirm_button" className="automated-button"
                  onClick={props.handleAddFilters}>Apply Filters
          </button>
        </div>
      </Modal>
    </div>
  );
}

function TabContainer(props) {
  return (
    <Typography
      component="div"
      style={
        {
          padding: "5px 5px 5px 10px",
          width: 550
        }
      }
    >
      <div className="clear-sub-filter-container">
        <p
          className="clear-sub-filter"
          onClick={() => {
            props.handleClearFilterClick(props.main_group_options)
          }}
        >
          Clear All
        </p>
      </div>
      <Grid container spacing={2}>
        {
          props.main_group_options.map((item, index) => {
            return (
              <Grid key={index} item xs={4} sm={4}>
                <div className="filter-legend-item">
                  <div className="filter-main-option-check" key={index}>
                    <DataViewerCheckBox
                      label={item["sub_group_label"]}
                      handleCheckClick={() => props.handleMainOptionClick(item)}
                      checked={props.mainOptionStatus(item)}
                      label_class={"graph-check-label-title"}
                    />
                  </div>
                  {
                    item["sub_group_options"].map((opt, opt_index) => {
                      return (
                        <div className="filter-option-check" key={opt_index}>
                          <DataViewerCheckBox
                            label={opt["option_label"]}
                            handleCheckClick={() => props.handleOptionClick(item, opt["option_value"])}
                            checked={props.optionStatus(item["sub_group_id"], opt["option_value"])}
                          />
                        </div>
                      )
                    })
                  }

                </div>
              </Grid>

            )
          })
        }

      </Grid>
    </Typography>
  );
}


const VerticalTabs = withStyles(theme => ({
  flexContainer: {
    flexDirection: "column"
  },
  indicator: {
    display: "none"
  }
}))(Tabs);

const FilterTab = withStyles(theme => ({
  root: {
    backgroundColor: "#474c53",
    boxShadow: "0px 1px 1px 0 rgba(0, 0, 0, 0.35), inset 0px 1px 0 0 rgba(255, 255, 255, 0.05)",
    fontSize: 18
  },
  wrapper: {
    alignItems: "end"
  },
  selected: {
    backgroundColor: "#1a6acb"
  }
}))(Tab);

export default FilterModal