import React from "react";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

class DataViewerTable extends React.Component {
  constructor(props) {
    super(props);

    this.getRow = this.getRow.bind(this)
  }

  getRow(id) {
    return this.props.table_data[id]
  }

  render() {
    if (this.props.table_row_keys.length > 0) {
      return (
        <div id="data_viewer_table_container" className={this.props.separated ? "avoidThisRow" : ""}>
          <TableContainer classes={{root: 'data_viewer_table'}}>
            <Table>
              <TableHead id="data-table-header">
                <TableRow>
                  <TableCell
                    style={{
                      borderBottom: "1px solid " + this.props.graph_colour,
                      borderRight: "1px solid " + this.props.graph_colour,
                      borderLeft: "none",
                      borderTop: "none",
                      backgroundColor: "transparent"
                    }}
                    colSpan={2} rowSpan={2}
                  />
                  <TableCell
                    align="center" colSpan={this.props.table_col_keys.length}
                    classes={{root: 'data_viewer_table_cell_header'}}
                    style={{
                      color: this.props.graph_colour,
                      borderBottom: "none",
                      borderRight: "1px solid " + this.props.graph_colour,
                      borderLeft: "1px solid " + this.props.graph_colour,
                      borderTop: "1px solid " + this.props.graph_colour,
                      backgroundColor: this.props.viewing ? "#1a1c1f" : "transparent"

                    }}
                  >
                    {`${this.props.table_col_label}${this.props.table_index > 0 ? " (CNTD.)" : ""}`}
                  </TableCell>
                </TableRow>
                <TableRow>
                  {this.props.table_col_keys.map(col => (
                    <TableCell
                      key={col} align="center"
                      classes={{root: 'data_viewer_table_cell_header'}}
                      style={{
                        color: this.props.graph_colour,
                        borderBottom: "1px solid " + this.props.graph_colour,
                        borderRight: "1px solid " + this.props.graph_colour,
                        borderLeft: "1px solid " + this.props.graph_colour,
                        borderTop: "none",
                        backgroundColor: this.props.viewing ? "#1a1c1f" : "transparent"
                      }}
                    >
                      {col}
                    </TableCell>
                  ))}
                </TableRow>

              </TableHead>
              <TableBody id="data-table-body">
                <TableRow>
                  <TableCell
                    align="center"
                    rowSpan={this.props.table_row_keys.length + 1}
                    classes={{root: 'data_viewer_table_cell_header'}}
                    style={{
                      color: this.props.graph_colour,
                      borderBottom: "1px solid " + this.props.graph_colour,
                      borderRight: "none",
                      borderLeft: "1px solid " + this.props.graph_colour,
                      borderTop: "1px solid " + this.props.graph_colour,
                      width: "10%",
                      backgroundColor: this.props.viewing ? "#1a1c1f" : "transparent"
                    }}
                  >
                    {this.props.index_by_label}
                  </TableCell>
                </TableRow>
                {this.props.table_row_keys.map((row, row_index) => (
                  <TableRow key={row}>
                    <TableCell
                      align="center"
                      classes={{root: 'data_viewer_table_cell_header'}}
                      style={{
                        pageBreakInside: "avoid",
                        color: this.props.graph_colour,
                        borderBottom: "1px solid " + this.props.graph_colour,
                        borderRight: "1px solid " + this.props.graph_colour,
                        borderLeft: "none",
                        borderTop: "1px solid " + this.props.graph_colour,
                        width: "20%",
                        backgroundColor: this.props.viewing ? "#1a1c1f" : "transparent"
                      }}
                    >
                      {row}
                    </TableCell>
                    {this.getRow(row).map((row_data, col_index) => (
                      <TableCell
                        key={row_index.toString() + "-" + row_data.toString() + "-" + col_index.toString()}
                        align="center"
                        classes={{root: 'data_viewer_table_cell'}}
                        style={{
                          pageBreakInside: "avoid",
                          color: this.props.graph_colour,
                          border: "1px solid " + this.props.graph_colour,
                          backgroundColor: this.props.viewing ? (row_index % 2 === 0 ? "#1a1c1f" : "#404040") : "transparent"
                        }}
                      >
                        {this.props.formatValue(row_data, true, false)}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <div className="data-table-page-break"></div>
        </div>
      )
    } else {
      return (
        <div>
        </div>
      )
    }

  }
}

export default DataViewerTable