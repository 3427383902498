import React from "react";
import {Modal} from "react-bootstrap"

class KeyAuthentication extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      key_data: sessionStorage.getItem('organizationKey'),
      incorrect_key: sessionStorage.getItem('incorrectKey'),
      key_file: null,
      key_info: false
    };

    this.handleFileSelect = this.handleFileSelect.bind(this);
    this.handleFileSubmit = this.handleFileSubmit.bind(this);
    this.handleKeyResubmit = this.handleKeyResubmit.bind(this);
    this.handleShowKeyInfo = this.handleShowKeyInfo.bind(this);
    this.handleHideKeyInfo = this.handleHideKeyInfo.bind(this);
  }

  handleFileSelect(event) {
    this.setState({key_file: event.target.files[0]});
  }

  handleShowKeyInfo() {
    this.setState({key_info: true})
  }


  handleHideKeyInfo() {
    this.setState({key_info: false})
  }

  handleFileSubmit() {
    forge.options.usePureJavaScript = true;
    var cont = true;
    var orgEncryptionKey = this.props.encryption_key;
    var f = this.state.key_file;
    var reader = new FileReader();

    reader.onload = function () {
      sessionStorage.setItem('organizationKey', reader.result);
      var orgKeyData = JSON.parse(sessionStorage.getItem('organizationKey'));
      var fileEncryptionKey = orgKeyData.organizationPublicKey;
      if (orgEncryptionKey === fileEncryptionKey) {
        this.setState({incorrect_key: false});
        sessionStorage.setItem('incorrectKey', 'false');
        if (this.props.refresh) location.reload();
      } else {
        $.post("/record_editor/incorrect_key.json", {"public_key": orgKeyData.organizationPublicKey});
        sessionStorage.setItem('incorrectKey', true);
        this.setState({incorrect_key: 'true'});
        cont = confirm("WARNING: incorrect key file. Would you like to continue?");
        if (cont == false) {
          sessionStorage.removeItem('organizationKey');
          sessionStorage.removeItem('incorrectKey');
        }
      }
      if (cont) {
        this.setState({key_data: sessionStorage.getItem('organizationKey')});
        if ((sessionStorage.getItem('incorrectKey') === 'true' && this.props.refresh)) location.reload();
      }
    }.bind(this);

    if (f) {
      reader.readAsText(f);
    } else {
      alert("Please select a key file before downloading.");
    }
  }

  handleKeyResubmit() {
    this.setState({
      key_data: null,
      incorrect_key: null,
      key_file: null
    });
    sessionStorage.removeItem('organizationKey');
    sessionStorage.removeItem('incorrectKey');
  }

  render() {
    return (
      <div id="key-submit-top-container">
        <div id="key-submit-main-container">
          <button style={this.state.incorrect_key === 'true' ? {} : {display: 'none'}}
                  id="key_resubmit" onClick={this.handleKeyResubmit}>
            Warning: Incorrect Key File. Click to resubmit
          </button>
        </div>
        <div>
          <Modal id="myModalKeySubmit"
                 show={!this.state.key_data} backdrop={"static"} keyboard={false} animation={false}
                 dialogClassName={"re-modal-content"}>
            <div id="key-submit-container">
              <h2 id="key-submit-header">Authentication Required</h2>
              <p className="key-submit-text">Record editing requires access to personally identifiable information.
                Authorized users must select
                your
                organization's decryption key to proceed.</p>
            </div>
            <div id="key-submit-input-container">
              <input className="key_file" id="file" type="file" name="file" onChange={this.handleFileSelect}/>
              <label
                htmlFor="file"
                style={{backgroundColor: this.state.key_file ? "#2c5b9e" : "#51565e"}}
              >
                <img id="key_image" src={this.props.key_image_path}/>
                <p id="key_image_text">Select Key File</p>
              </label>
              <div id="file-selected-container">
                <p>File Selected:</p>
                <p
                  style={{color: this.state.key_file ? "white" : "#808080"}}
                >{this.state.key_file ? this.state.key_file.name : "No file chosen."}
                </p>
              </div>
            </div>
            <div id="key-submit-button-container">
              <button
                style={{opacity: this.state.key_file ? 1 : 0.4}}
                className="key-submit-button"
                onClick={this.handleFileSubmit}
              >
                Proceed
              </button>
            </div>
            <div className="key-info-box" onClick={() => {
              this.handleShowKeyInfo()
            }}>Where is my key file?
            </div>
          </Modal>
        </div>

        <KeyInfo
          key_info={this.state.key_info}
          handleHideKeyInfo={this.handleHideKeyInfo}
        />
      </div>
    );
  }
}

function KeyInfo(props) {
  return (
    <div>
      <Modal id="archive_report_modal"
             backdropClassName="report_view_edit_modal_backdrop"
             show={props.key_info} backdrop={true} keyboard={false} animation={false}
             onHide={props.handleHideKeyInfo} dialogClassName={"re-modal-content"}>
        <div>
          <h2 id="archive_modal_title">About Key Files</h2>
        </div>

        <div id="archive_modal_text_container">
          <div className="archive_modal_text_body_container">
            <p className="archive_modal_text_body">
              <b>The key file allows HealthIM software to decrypt and share HealthIM records between different
                users.</b>
            </p>
          </div>

          <div className="archive_modal_text_body_container">
            <p className="archive_modal_text_body">Access to your organization's key file may be granted by your IT staff
              or IT service provider</p>
          </div>
        </div>
        <div id="archive_modal_button_container">
          <button id="archive_modal_cancel_button" className="automated-button key_info_button"
                  onClick={props.handleHideKeyInfo}>Close
          </button>
        </div>
      </Modal>
    </div>
  );
}

export default KeyAuthentication
