import React from 'react'
import {RecordEditorDateTime, RecordEditorSelect} from './record_editor_custom_fields'

class CallLog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      call_outcome: this.props.disposition
    };

    this.call_outcome_select_options =
      [
        {
          value: "Not Transported",
          label: "Not Transported"
        },
        {
          value: "Voluntary Transport",
          label: "Voluntary Transport"
        },
        {
          value: "Involuntary Apprehension",
          label: "Involuntary Apprehension"
        },
        {
          value: "Apprehended Under Existing Order",
          label: "Apprehended Under Existing Order"
        }
      ];

    if (this.props.is_plum_coulee || this.props.analytics_organization_name === "Winnipeg Police Service") {
      this.hospital_outcome_select_options =
        [
          {
            value: "Admitted into hospital care",
            label: "Admitted into hospital care"
          },
          {
            value: "Released from hospital",
            label: "Released from hospital"
          },
          {
            value: "Patient still awaiting disposition (Police released)",
            label: "Patient still awaiting disposition (Police released)"
          }
        ];
    } else {
      this.hospital_outcome_select_options =
        [
          {
            value: "Admitted",
            label: "Admitted"
          },
          {
            value: "Not Admitted",
            label: "Not Admitted"
          },
          {
            value: "Unknown",
            label: "Unknown"
          }
        ];
    }

    if (this.props.transfer_available) {
      this.hospital_outcome_select_options.push({value: "Transferred", label: "Transferred"})
    }

    this.handleCallOutcomeChange = this.handleCallOutcomeChange.bind(this);
    this.resetDispositionDValues = this.resetDispositionDValues.bind(this);
    this.checkTransfer = this.checkTransfer.bind(this);
  }

  checkTransfer(index) {
    if ((index + 1) !== this.props.hospital_data_object.length) {
      var next_hospital = this.props.hospital_data_object[index + 1];
      var cur_hospital = this.props.hospital_data_object[index];
      if ((next_hospital.id.toString() === cur_hospital.id.toString())
        && (next_hospital.name.toString() === cur_hospital.name.toString())) {
        this.props.hospital_data_object[index + 1].id = "";
        this.props.hospital_data_object[index + 1].name = "";
        this.forceUpdate();
      }
    }
    this.forceUpdate();
  }

  resetDispositionDValues() {
    this.props.report_object['sectionDActions'][0] = 0;
    this.props.report_object['sectionDActions'][4] = 0;
    this.props.report_object['sectionDActions'][5] = 0;
    this.forceUpdate();
  }

  handleCallOutcomeChange(selected) {
    var value = selected.value;
    var prev = this.state.call_outcome;
    var allowClick = true;
    if (prev !== "Not Transported" && value === "Not Transported") {
      allowClick = confirm("Are you sure you want to remove all hospital wait times from this call?");
      if (allowClick) {
        this.props.clearHospitalsAfter(-1);
        this.props.report_object["patientAdmitted"] = "";
        this.resetDispositionDValues();
      } else {
        return;
      }
    } else if (prev === "Not Transported" && value !== "Not Transported") {
      this.props.addHospital();
      this.resetDispositionDValues();
    } else {
      this.resetDispositionDValues();
    }

    this.setState({call_outcome: value});

    if (value === "Voluntary Transport") {
      this.props.report_object['sectionDActions'][0] = 1;
    } else if (value === "Apprehended Under Existing Order") {
      this.props.report_object['sectionDActions'][4] = 1;
    } else if (value === "Involuntary Apprehension") {
      this.props.report_object['sectionDActions'][5] = 1;
    }

    if (value !== "Apprehended Under Existing Order") {
      this.props.report_object["apprehensionType"] = "";
    }
    this.forceUpdate();
  }

  componentDidMount() {
    if (this.props.report_object['sectionDActions'][4] != 1) {
      this.props.report_object["apprehensionType"] = "";
    }

    if (this.props.hospital_data_object.length === 0 && this.state.call_outcome !== "Not Transported") {
      this.props.addHospital();
    }
    this.forceUpdate();
  }

  render() {
    return (
      <div id="call-log-container" className="container-report">
        <div className="title-container">
          <h1 className="title-container-head">CALL LOG</h1>
        </div>

        <div id="form-times-container" className="content-area-container">
          <RecordEditorDateTime
            title="HealthIM initiated:"
            tooltip="Indicates when Officers selected “Start New Call” in HealthIM."
            field_id="date"
            datetime_value={this.props.report_time_fields}
          />

          <RecordEditorDateTime
            title="Arrival on scene:"
            tooltip="Indicates when Officers arrived at the incident location."
            field_id="arrivalTimestamp"
            datetime_value={this.props.report_time_fields}
          />

          <RecordEditorSelect
            title="Call Outcome:"
            tooltip={
              <div className="dropdown-tooltip">
                Indicates what action was taken by the officer: <br/>
                • <b>Not Transported</b>: The individual was not brought to a hospital.<br/>
                • <b>Voluntarily Transported</b>: The individual was brought to hospital voluntarily.<br/>
                • <b>Involuntarily Apprehended</b>: The individual was apprehended under emergency police powers and
                brought to hospital for involuntary psychiatric assessment.<br/>
                • <b>Apprehension under existing order</b>: The individual was apprehended under an existing order
                (e.g.,
                physician’s order to apprehend) and transported to hospital.<br/>
              </div>
            }
            select_id="disposition"
            selected={this.state.call_outcome}
            selected_label={this.state.call_outcome}
            select_options={this.call_outcome_select_options}
            select_prompt="Select call outcome:"
            handleSelectChange={this.handleCallOutcomeChange}
            show_dropdown={true}
          />

          <RecordEditorSelect
            title="Order:"
            tooltip={undefined}
            select_id="apprehensionType"
            selected={this.props.report_object["apprehensionType"]}
            selected_label={this.props.report_object["apprehensionType"]}
            select_options={this.props.apprehension_types}
            select_prompt="Select Order:"
            handleSelectChange={this.props.handleSelectChange}
            show_dropdown={this.state.call_outcome === "Apprehended Under Existing Order"}
          />

          {
            this.props.hospital_data_object.map((item, index) => {
              return (
                <HospitalTemplate
                  key={index}
                  index={index}
                  possible_destinations={this.props.possible_destinations}
                  report_object={this.props.report_object}
                  hospital_object={item}
                  hospital_data_object={this.props.hospital_data_object}
                  addHospital={this.props.addHospital}
                  clearHospitalsAfter={this.props.clearHospitalsAfter}
                  hospital_outcome_select_options={this.hospital_outcome_select_options}
                  call_outcome={this.state.call_outcome}
                  hospital_outcome_tooltip={
                    <HospitalOutcomeTooltip transfer_available={this.props.transfer_available}/>
                  }
                  number_of_hospitals={this.props.hospital_data_object.length}
                  checkTransfer={this.checkTransfer}
                  hospital_datetime={this.props.hospital_datetime}
                  report_time_fields={this.props.report_time_fields}
                />
              )
            })
          }

          <RecordEditorDateTime
            title="End Call Time:"
            tooltip="Indicates when Officers ended the call."
            field_id="endCallTime"
            datetime_value={this.props.report_time_fields}
          />
        </div>
      </div>
    );
  }
}

class HospitalTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hospital_outcome: (this.props.index + 1) === this.props.number_of_hospitals ? this.props.report_object["patientAdmitted"] : "Transferred"
    };

    this.props.hospital_object["datetime"] = {};

    for (var dt in this.props.hospital_datetime) {
      var field_name = this.props.hospital_datetime[dt];
      var r_t = {};
      r_t["date"] = this.props.hospital_object[field_name] && this.props.hospital_object[field_name].length > 0 ? formatDate(this.props.hospital_object[field_name]) : '';
      r_t["time"] = this.props.hospital_object[field_name] && this.props.hospital_object[field_name].length > 0 ? formatTime(this.props.hospital_object[field_name]) : '';
      this.props.hospital_object["datetime"][field_name] = r_t;
    }

    this.handleHospitalDestinationChange = this.handleHospitalDestinationChange.bind(this);
    this.hospitalOutcomeChange = this.hospitalOutcomeChange.bind(this);
  }

  handleHospitalDestinationChange(selected) {
    if (!this.props.report_object["destinations"]) {
      this.props.report_object["destinations"] = []
    }

    var last_index = this.props.report_object["destinations"].indexOf(this.props.hospital_object.id);

    if (last_index >= 0) {
      this.props.report_object["destinations"].splice(last_index, 1);
    }

    if (this.props.report_object["destinations"].indexOf(selected.value) < 0) {
      this.props.report_object["destinations"].push(selected.value);
    }

    this.props.hospital_object.id = selected.value;
    this.props.hospital_object.name = selected.label;
    this.forceUpdate();

    this.props.checkTransfer(this.props.index)
  }

  hospitalOutcomeChange(selected) {
    var value = selected.value;
    var prev = this.state.hospital_outcome;

    if (prev !== "Transferred" && value === "Transferred") {
      this.props.addHospital();
      this.props.report_object["patientAdmitted"] = "";
    } else if (prev === "Transferred" && value !== "Transferred") {
      var allowClick = confirm("Are you sure you want to remove all hospital wait times after this hospital?");
      if (allowClick) {
        this.props.clearHospitalsAfter(this.props.index);
        this.props.report_object["patientAdmitted"] = value;
      } else {
        return;
      }
    } else {
      this.props.report_object["patientAdmitted"] = value;
    }

    this.setState({hospital_outcome: selected.value});
    this.forceUpdate();
  }

  render() {
    return (
      <div key={this.props.index} id="hospital-template">
        <RecordEditorSelect
          title="Destination:"
          tooltip="Indicates which health care facility the subject was transported to."
          select_id="hospitalSelect"
          selected={this.props.hospital_object.id.toString()}
          selected_label={this.props.hospital_object.name.toString()}
          select_options={this.props.possible_destinations}
          select_prompt="Select Hospital:"
          select_prompt_value=""
          select_filter={this.props.index > 0 ? this.props.hospital_data_object[this.props.index - 1].id.toString() : undefined}
          handleSelectChange={this.handleHospitalDestinationChange}
          show_dropdown={true}
        />

        <SubmittedOrBeforeTravel
          index={this.props.index}
          hospital_object={this.props.hospital_object}
          call_outcome={this.props.call_outcome}
          report_object={this.props.report_object}
          report_time_fields={this.props.report_time_fields}
        />

        <RecordEditorDateTime
          title={`Arrival at ${this.props.hospital_object.name.length > 0 ? this.props.hospital_object.name : "hospital"}:`}
          tooltip="Indicates when Officers arrived at the health care facility."
          field_id="arriveAtED"
          datetime_value={this.props.hospital_object}
        />

        <RecordEditorDateTime
          title={`Exited at ${this.props.hospital_object.name.length > 0 ? this.props.hospital_object.name : "hospital"}:`}
          tooltip="Indicates when Officers were released from the health care facility."
          field_id="departFromED"
          datetime_value={this.props.hospital_object}
        />

        <RecordEditorSelect
          title="Outcome at Hospital:"
          tooltip={this.props.hospital_outcome_tooltip}
          select_id="hospitalPatientAdmitted"
          selected={this.state.hospital_outcome}
          selected_label={this.state.hospital_outcome}
          select_options={this.props.hospital_outcome_select_options}
          select_prompt="Select hospital outcome:"
          select_prompt_value=""
          handleSelectChange={this.hospitalOutcomeChange}
          show_dropdown={true}
        />
      </div>
    )
  }
}

function SubmittedOrBeforeTravel(props) {
  if (props.index === 0) {
    return (
      <RecordEditorDateTime
        title={`${props.call_outcome}:`}
        tooltip={`Indicates when Officers made the decision to ${props.call_outcome}.`}
        field_id="reportEnd"
        datetime_value={props.report_time_fields}
      />
    )
  } else {
    return (
      <RecordEditorDateTime
        title={`Departed for ${props.hospital_object.name.length > 0 ? props.hospital_object.name : "hospital"}:`}
        tooltip="Indicates when Officers departed from one health care facility to transfer the subject to the next health care facility."
        field_id="beforeTravel"
        datetime_value={props.hospital_object}
      />
    )
  }
}

function HospitalOutcomeTooltip(props) {
  if (props.transfer_available) {
    return (
      <div className="dropdown-tooltip">
        Indicates which outcome occurred at the hospital:<br/>
        • <b>Not Admitted</b><br/>
        • <b>Admitted</b><br/>
        • <b>Unknown</b><br/>
        • <b>Transferred:</b> Officers were tasked with transporting the individual to a second health facility.<br/>
      </div>
    )
  } else {
    return (
      <div className="dropdown-tooltip">
        Indicates which outcome occurred at the hospital:<br/>
        • <b>Not Admitted</b><br/>
        • <b>Admitted</b><br/>
        • <b>Unknown</b><br/>
      </div>
    )
  }
}

export default CallLog